import React from "react";
import ReactTooltip from "react-tooltip";

const Toooltip = (props) => {
  return (
    <ReactTooltip id={props.id} aria-haspopup="true">
      <p className="m-0">{props.title}</p>
    </ReactTooltip>
  );
};

export default Toooltip;
