import React, {Component, Suspense} from "react";
import {connect} from "react-redux";
import _ from "lodash";
import produce from "immer";
import {Link} from "react-router-dom";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import LoaderCircle from "./loader-circle";
import {
	generateQuestionFromVideo,
	wikiTopicSuggestions,
	editQuestionPaper,
	updateInstructionRead,
	fetchUser,
	generateQuestionFromPdfDoc,
	QuestionPaperRating,
	getQPRating,
} from "./../services";
import MSG, {notifySuccess, notifyError, notifyInfo} from "./../utils/Helper";
import {startLoader, stopLoader} from "./../store/reducers/generalActions";
import {authLogout} from "./../store/reducers/auth";
import EasyMCQ from "./questionTypes/easymcq";
import MediumMCQ from "./questionTypes/mediummcq";
import HardMCQ from "./questionTypes/hardmcq";
import TrueFalse from "./questionTypes/true_false";
import Descriptive from "./questionTypes/descriptive";
import FillUpsMCQ from "./questionTypes/fillups";
import Tooltip from "./common/tooltip";
import Addeasymcq from "./addquestion/addeasymcq";
import {getDroppedOrSelectedFiles} from "html5-file-selector";
import "react-dropzone-uploader/dist/styles.css";
import Addmediummcq from "./addquestion/addmediummcq";
import Addhardmcq from "./addquestion/addhardmcq";
import Truefalse from "./addquestion/true_false";
import AddDescriptive from "./addquestion/adddescriptive";
import Fillups from "./addquestion/fillups";
import {isMobile} from "react-device-detect";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import Salebar from "./sales";
import ToggleForGQ from "./common/ToggleForGQDemo";
import DonwloadQuestionPaper from "./common/DonwloadQuestionPaper";
import QuestionPaperPreview from "./question-paper-preview";
import DemoHeader from "./common/DemoHeader";
import DemoGenerateQuestionsStdHots from "./common/DemoGenerateQuestionStdHots";

import styles from "../styles/demoResponsive.module.css";
import classNames from "classnames";

class DemoPage extends Component {
	state = {
		questionPaperGenerated: false,
		titleofQuestion: "World is Adopting Technology in Education",
		titleofQuestion_emsg: "",
		contentForQuestions:
			"Today, technology is becoming more and more important in all aspects of life. Education is no exception. In fact, technology has had a big impact on education. More and more schools are using computers, laptops, tablets and smartphones in the classroom. This has led to a number of advantages as well as disadvantages. One of the main advantages of using technology in education is that it can improve teaching and learning. This is because technology can be used to create targeted and engaging learning experiences. For example, using educational software, students can complete interactive exercises that are specifically designed to help them learn. Additionally, technology can be used to provide real-time feedback to both students and teachers. This can help to improve the quality of education overall. However, there are also some disadvantages to using technology in education. One of the main disadvantages is that it can be distracting for students. This is because students may be tempted to use their devices for things other than learning. Additionally, some students may not have the necessary skills to use technology effectively (proper guidance in starting should be given). This can lead to them feeling frustrated and not being able to get the most out of their education. Overall, the advantages of using technology in education outweigh the disadvantages. This is because technology can be used to create targeted and engaging learning experiences. Additionally, technology can be used to provide real-time feedback to both students and teachers.",
		wikiSearchInput: "",
		refreshing: false,
		typing: false,
		typingTimeout: 0,
		selectedWikiTopic: null,
		tmpselectedWikiTopic: null,
		showAlert: false,
		wikiContent: "",
		searchTopic: "",
		iseditpaper: true,
		questions: [],
		ques_count: {
			easyCount: 0,
			mediumCount: 0,
			hardCount: 0,
			descriptiveCount: 0,
			trueFalseCount: 0,
			fillUpsCount: 0,
		},
		addquestionmodal: false,
		questiontypetab: "easymcq",
		defaultActiveTab: "custom-tab",
		addeasymcq: false,
		addmediummcq: false,
		addhardmcq: false,
		addtruefalse: false,
		adddescriptive: false,
		addfillups: false,
		showPreview: false,
		sortableQuestionOption: [
			{
				id: 1,
				name: "An object at rest stays at rest and an object in motion stays in motion.",
			},
			{
				id: 2,
				name: "Individuals define physics by what it was rather than what it is and will be.",
			},
			{
				id: 3,
				name: "What we mean by this is that things keep changing in the world of physics with every discovery.",
			},
			{
				id: 4,
				name: "As theories progress and discoveries are made, not only the answer but the whole question.",
			},
		],
		isUploaded: false,
		videoFile: null,
		pdfDocFile: null,
		feedbackAlert: false,
		showRatingAlert: false,
		callRatingAPI: true,
		questionPaperId: null,
		questionPaperRating: null,
		timeLeft: {},
		highlightBadEmoji: null,
		highlightNeutralEmoji: null,
		highlightGoodEmoji: null,
		showPremiumPrompt: false,
		premiumPromptText: "",
		isShowPremiumPrompt: false,
		showDemoPopUp: false,
		showQuestionsTemplate: true,
		categoryAccordion: false,
	};

	handleSubmit = e => {
		e.preventDefault();
	};

	isFutureDate = idate => {
		var today = new Date().getTime(),
			idate = idate.split("/");

		idate = new Date(idate[2], idate[1] - 1, idate[0]).getTime();
		return today - idate < 0 ? true : false;
	};

	showHeaderTextPlan = () => {
		if (this.props.user.plan_details) {
			var planButtonText = "Upgrade Plan";

			if (this.props.user.plan_details.plan_name === "Yearly") {
				planButtonText = "View Plan";
			}
			return planButtonText;
		}
		return null;
	};

	handleCallback = childData => {
		var easyCount = 0;
		var mediumCount = 0;
		var hardCount = 0;
		var trueFalseCount = 0;
		var descriptiveCount = 0;
		var fillUpsCount = 0;
		childData.map(question => {
			switch (question.category_type) {
				case 1:
					easyCount += 1;
					break;
				case 2:
					mediumCount += 1;
					break;
				case 3:
					hardCount += 1;
					break;
				case 4:
					trueFalseCount += 1;
					break;
				case 5:
					descriptiveCount += 1;
					break;
				case 6:
					fillUpsCount += 1;
					break;
				default:
					break;
			}
		});
		let ques_count_obj = Object.assign({}, this.state.ques_count);
		ques_count_obj.easyCount = easyCount;
		ques_count_obj.mediumCount = mediumCount;
		ques_count_obj.hardCount = hardCount;
		ques_count_obj.trueFalseCount = trueFalseCount;
		ques_count_obj.descriptiveCount = descriptiveCount;
		ques_count_obj.fillUpsCount = fillUpsCount;
		this.setState({questions: childData, ques_count: ques_count_obj});
	};

	onInputChange = option => {
		if (this.state.typingTimeout) {
			clearTimeout(this.state.typingTimeout);
		}
		this.setState({
			wikiSearchInput: option,
			refreshing: true,
			typing: false,
			typingTimeout: setTimeout(() => {
				this.getOptionsAsync(option);
			}, 2000),
		});
	};

	getOptionsAsync = async val => {
		if (val !== "") {
			var wikiFormData = new FormData();
			wikiFormData.append("wiki-topic", val);
			wikiFormData.append("generateQA", "");

			setInterval(function () {
				<LoaderCircle isLoading={true} />;
			}, 5000);

			this.setState({
				showDemoPopUp: true,
			});
		}
	};

	handleWikiTopicSelect = (name, option) => {
		this.setState({
			selectedWikiTopic: option,
			tmpselectedWikiTopic: option,
			[name]: option.value,
		});
		try {
			var formData = new FormData();
			formData.append("wiki-topic", option.value);
			formData.append("generateQA", "");
			this.handlePopUpOpen();
			return;
		} catch (err) {
			console.log(err);
		}
	};

	handleWikiTopicFocus = () => {
		this.setState({
			tmpselectedWikiTopic: null,
			options: [],
		});
	};

	handleWikiTopicBlur = () => {
		if (this.state.tmpselectedWikiTopic === null) {
			this.setState({
				tmpselectedWikiTopic: this.state.selectedWikiTopic,
				options: [],
			});
		}
	};

	resetQuestionState = () => {
		this.setState({
			questions: [],
			questionPaperGenerated: false,
			ques_count: {
				easyCount: 0,
				mediumCount: 0,
				hardCount: 0,
				descriptiveCount: 0,
				trueFalseCount: 0,
				fillUpsCount: 0,
			},
		});
	};

	scrollToQuestions = () => {
		const position = document.getElementById("generated_questions").offsetTop;
		window.scrollTo(0, position);
	};

	handleAddQuestionOnClick = () => {
		this.addquestion();
		this.scrollToBottom();
	};

	handlePopUpOpen = () => {
		this.setState({
			showDemoPopUp: true,
		});
	};

	handlePopUpClose = () => {
		this.setState({
			showDemoPopUp: false,
		});
	};

	getFilesFromEvent = e => {
		return new Promise(resolve => {
			getDroppedOrSelectedFiles(e).then(chosenFiles => {
				resolve(chosenFiles.map(f => f.fileObject));
				var fileObj = chosenFiles.map(f => f.fileObject);
				this.setState({videoFile: fileObj[0], isUploaded: true});
			});
		});
	};

	handleFileStatusChange = ({meta, file}, status) => {
		if (status === "removed") {
			this.setState({videoFile: null, isUploaded: false});
		}
	};

	getDocPdfFromEvent = e => {
		return new Promise(resolve => {
			getDroppedOrSelectedFiles(e).then(chosenFiles => {
				resolve(chosenFiles.map(f => f.fileObject));
				var fileObj = chosenFiles.map(f => f.fileObject);
				this.setState({pdfDocFile: fileObj[0]});
			});
		});
	};

	handlePdfDocFileStatusChange = ({meta, file}, status) => {
		if (status === "removed") {
			this.setState({pdfDocFile: null});
		}
	};

	validateTopicInput = () => {
		if (_.trim(this.state.titleofQuestion) === "") {
			this.setState(
				{
					titleofQuestion_emsg:
						"Please enter Chapter name, Any topic, etc.",
				},
				() => {
					const position =
						document.getElementById("titleofQuestion").offsetTop;
					window.scrollTo(0, position);
					document.getElementById("titleofQuestion").focus();
				}
			);
			return false;
		}
		this.setState({
			titleofQuestion_emsg: "",
		});
		return true;
	};

	scrollToBottom = () => {
		window.scrollTo(0, document.body.scrollHeight);
	};

	videoExtractButtonContent = () => {
		if (this.props.user.plan_details) {
			return (
				<React.Fragment>
					<React.Fragment>
						<div className='btn-flex100'>
							{this.props.user.plan_details.plan_name === "Free" ? (
								<Link type='button' className='btn--submit' to='/plans'>
									Upgrade Plan
								</Link>
							) : (
								<button
									type='button'
									className='btn--submit'
									onClick={this.handleGenerateQuestionFromVideo}
									disabled={
										this.state.videourl || this.state.isUploaded
											? false
											: true
									}
								>
									Extract Content
								</button>
							)}
						</div>
					</React.Fragment>
				</React.Fragment>
			);
		}
	};

	handleGenerateQuestionFromVideo = async () => {
		if (!this.validateTopicInput()) {
			return false;
		}

		const current_date = moment(moment().toDate()).format("YYYY-MM-DD");
		const plan_expiry_date = moment(this.props.user.plan_expired_at).format(
			"YYYY-MM-DD"
		);

		if (
			moment(plan_expiry_date).isBefore(current_date) &&
			this.props.user.plan_details.plan_name !== "Free"
		) {
			// notifyError("Your Premium plan is expired. Please renew your plan.");
			this.setState({
				premiumPromptText:
					"Your Premium plan is expired. Renew your plan to continue generating more question papers.",
				isShowPremiumPrompt: true,
			});
			if (this.state.showRatingAlert === false) {
				this.setState({
					showPremiumPrompt: true,
				});
			}
			return;
		}

		this.props.dispatch(startLoader());
		try {
			const {data: res} = await getQPRating();
			if (res.success === true && res.rating === null) {
				this.setState({
					feedbackAlert: true,
					questionPaperId: res.response.content_id,
					questionPaperRating: res.response.rating,
					callRatingAPI: false,
				});
				if (
					this.state.feedbackAlert === true &&
					this.state.questionPaperRating === null
				) {
					this.setState({
						showRatingAlert: true,
					});
				}
			}
		} catch (err) {
			if (err.response && err.response.status === 422) {
				const {data} = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			} else if (err.response && err.response.status === 401) {
				const {data} = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			} else {
				notifyError(MSG.commonApiError);
			}
		}

		var device = "Desktop";

		if (isMobile === true) {
			device = "Mobile";
		}

		var formData = new FormData();

		if (this.state.isUploaded === true) {
			formData.append("topic", this.state.titleofQuestion);
			formData.append("file", this.state.videoFile);
			formData.append("type", "video");
			formData.append("device", device);
		} else {
			formData.append("topic", this.state.titleofQuestion);
			formData.append("url", this.state.videourl);
			formData.append("type", "url");
			formData.append("device", device);
		}
		try {
			const {data: res} = await generateQuestionFromVideo(formData);
			if (res.success === true) {
				notifySuccess(res.message);
				if (res.code === 1111) {
					/* Check if new notification addded to db and update it */
					this.props.dispatch(fetchUser());
				}
				if (!_.isEmpty(res.response)) {
					let ques_count = {
						easyCount: 0,
						mediumCount: 0,
						hardCount: 0,
						descriptiveCount: 0,
						trueFalseCount: 0,
						fillUpsCount: 0,
					};
					res.response.map(question => {
						switch (question.category_type) {
							case 1:
								ques_count.easyCount += 1;
								break;
							case 2:
								ques_count.mediumCount += 1;
								break;
							case 3:
								ques_count.hardCount += 1;
								break;
							case 4:
								ques_count.trueFalseCount += 1;
								break;
							case 5:
								ques_count.descriptiveCount += 1;
								break;
							case 6:
								ques_count.fillUpsCount += 1;
								break;
							default:
								break;
						}
					});
					this.setState({
						questions: [...res.response],
						questionPaperGenerated: true,
						ques_count: {...ques_count},
					});
					this.scrollToQuestions();
				}
			} else {
				this.resetQuestionState();
				if (
					res.code === 111 &&
					this.props.user.plan_details.plan_name === "Free"
				) {
					this.setState({
						premiumPromptText: res.message,
						isShowPremiumPrompt: true,
					});
					if (this.state.showRatingAlert === false) {
						this.setState({
							showPremiumPrompt: true,
						});
					}
				} else {
					notifyError(res.message);
				}
			}
		} catch (err) {
			console.log(err);
			this.resetQuestionState();
			if (err.response && err.response.status === 422) {
				const {data} = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			} else if (err.response && err.response.status === 401) {
				const {data} = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			} else {
				notifyError(MSG.commonApiError);
			}
		}
		this.props.dispatch(stopLoader());
	};

	handleGenerateQuestionFromPdfDoc = async () => {
		if (!this.validateTopicInput()) {
			return false;
		}

		const current_date = moment(moment().toDate()).format("YYYY-MM-DD");
		const plan_expiry_date = moment(this.props.user.plan_expired_at).format(
			"YYYY-MM-DD"
		);

		if (
			moment(plan_expiry_date).isBefore(current_date) &&
			this.props.user.plan_details.plan_name !== "Free"
		) {
			// notifyError("Your Premium plan is expired. Please renew your plan.");
			this.setState({
				premiumPromptText:
					"Your Premium plan is expired. Renew your plan to continue generating more question papers.",
				isShowPremiumPrompt: true,
			});
			if (this.state.showRatingAlert === false) {
				this.setState({
					showPremiumPrompt: true,
				});
			}
			return;
		}

		this.props.dispatch(startLoader());

		try {
			const {data: res} = await getQPRating();
			if (res.success === true && res.rating === null) {
				this.setState({
					feedbackAlert: true,
					questionPaperId: res.response.content_id,
					questionPaperRating: res.response.rating,
					callRatingAPI: false,
				});
				if (
					this.state.feedbackAlert === true &&
					this.state.questionPaperRating === null
				) {
					this.setState({
						showRatingAlert: true,
					});
				}
			}
		} catch (err) {
			if (err.response && err.response.status === 422) {
				const {data} = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			} else if (err.response && err.response.status === 401) {
				const {data} = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			} else {
				notifyError(MSG.commonApiError);
			}
		}

		var device = "Desktop";

		if (isMobile === true) {
			device = "Mobile";
		}

		var formData = new FormData();

		formData.append("topic", this.state.titleofQuestion);
		formData.append("file", this.state.pdfDocFile);
		formData.append("device", device);

		//console.log("videodata",data)
		try {
			const {data: res} = await generateQuestionFromPdfDoc(formData);
			//console.log("res",res)
			if (res.success === true) {
				notifySuccess(res.message);
				if (res.code === 1111) {
					/* Check if new notification addded to db and update it */
					this.props.dispatch(fetchUser());
				}
				if (!_.isEmpty(res.response)) {
					let ques_count = {
						easyCount: 0,
						mediumCount: 0,
						hardCount: 0,
						descriptiveCount: 0,
						trueFalseCount: 0,
						fillUpsCount: 0,
					};
					res.response.map(question => {
						switch (question.category_type) {
							case 1:
								ques_count.easyCount += 1;
								break;
							case 2:
								ques_count.mediumCount += 1;
								break;
							case 3:
								ques_count.hardCount += 1;
								break;
							case 4:
								ques_count.trueFalseCount += 1;
								break;
							case 5:
								ques_count.descriptiveCount += 1;
								break;
							case 6:
								ques_count.fillUpsCount += 1;
								break;
							default:
								break;
						}
					});
					this.setState({
						questions: [...res.response],
						questionPaperGenerated: true,
						ques_count: {...ques_count},
					});
					this.scrollToQuestions();
				}
			} else {
				this.resetQuestionState();
				if (
					res.code === 111 &&
					this.props.user.plan_details.plan_name === "Free"
				) {
					this.setState({
						premiumPromptText: res.message,
						isShowPremiumPrompt: true,
					});
					if (this.state.showRatingAlert === false) {
						this.setState({
							showPremiumPrompt: true,
						});
					}
				} else {
					notifyError(res.message);
				}
			}
		} catch (err) {
			console.log(err);
			this.resetQuestionState();
			if (err.response && err.response.status === 422) {
				const {data} = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			} else if (err.response && err.response.status === 401) {
				const {data} = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			} else {
				notifyError(MSG.commonApiError);
			}
		}
		this.props.dispatch(stopLoader());
	};

	handleChange = ({currentTarget: input}) => {
		this.setState({
			[input.name]: input.value,
		});
	};

	handleTitleChange = ({currentTarget: input}) => {
		if (_.trim(input.value) !== "") {
			this.setState({
				titleofQuestion_emsg: "",
			});
		}
		this.setState({
			[input.name]: input.value,
		});
	};

	handleQuestionUpdate = question => {
		// console.log(question);
		this.setState(
			produce(this.state, draftState => {
				const index = draftState.questions.findIndex(
					m => m.question_id === question.question_id
				);
				draftState.questions[index] = question;
			})
		);
	};

	handlestateforaddquestion = newquestion => {
		switch (newquestion.category_type) {
			case 1:
				this.state.ques_count.easyCount += 1;
				break;
			case 2:
				this.state.ques_count.mediumCount += 1;
				break;
			case 3:
				this.state.ques_count.hardCount += 1;
				break;
			case 4:
				this.state.ques_count.trueFalseCount += 1;
				break;
			case 5:
				this.state.ques_count.descriptiveCount += 1;
				break;
			case 6:
				this.state.ques_count.fillUpsCount += 1;
				break;
			default:
				break;
		}
		this.setState({
			questions: [...this.state.questions, newquestion],
		});
	};

	addquestion = () => {
		switch (this.state.questiontypetab) {
			case "easymcq":
				this.setState({addeasymcq: !this.state.addeasymcq});
				break;
			case "mediummcq":
				this.setState({addmediummcq: !this.state.addmediummcq});
				break;
			case "hardmcq":
				this.setState({addhardmcq: !this.state.addhardmcq});
				break;
			case "truefalse":
				this.setState({addtruefalse: !this.state.addtruefalse});
				break;
			case "descriptive":
				this.setState({adddescriptive: !this.state.adddescriptive});
				break;
			case "fillups":
				this.setState({addfillups: !this.state.addfillups});
				break;
		}
	};

	handlediscard = questiontype => {
		switch (questiontype) {
			case "easymcq":
				this.setState({addeasymcq: !this.state.addeasymcq});
				break;
			case "mediummcq":
				this.setState({addmediummcq: !this.state.addmediummcq});
				break;
			case "hardmcq":
				this.setState({addhardmcq: !this.state.addhardmcq});
				break;
			case "truefalse":
				this.setState({addtruefalse: !this.state.addtruefalse});
				break;
			case "descriptive":
				this.setState({adddescriptive: !this.state.adddescriptive});
				break;
			case "fillups":
				this.setState({addfillups: !this.state.addfillups});
				break;
		}
	};

	handleQPRating = async () => {
		if (this.state.questionPaperRating === null) {
			notifyError("Please Rate Question Paper First!");
			return false;
		}
		this.setState({showRatingAlert: false, feedbackAlert: false});
		const QP_Rating_Parm = {
			content_id: this.state.questionPaperId,
			rating: this.state.questionPaperRating,
		};
		//this.props.dispatch(startLoader());
		try {
			const {data: res} = await QuestionPaperRating(QP_Rating_Parm);
			if (res.success === true) {
				notifySuccess(res.message);
				this.setState({
					questionPaperRating: null,
					highlightBadEmoji: null,
					highlightNeutralEmoji: null,
					highlightGoodEmoji: null,
				});
				this.handleRatingPromptCloseEvent();
				//this.setState({ showRatingAlert: false, feedbackAlert: false });
			} else {
				notifyError(res.message);
			}
		} catch (err) {
			console.log(err);
			if (err.response && err.response.status === 422) {
				const {data} = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			} else if (err.response && err.response.status === 401) {
				const {data} = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			} else {
				notifyError(MSG.commonApiError);
			}
		}
		//this.props.dispatch(stopLoader());
	};

	handleQPRatingChange = value => {
		this.setState({
			questionPaperRating: value,
		});
	};

	getRateText = rate => {
		if (rate === 1) {
			document.getElementById("labelFeedbackLabel").innerHTML = "Bad";
			document.getElementById("labelFeedbackLabel").style.width = "120px";
		} else if (rate === 2) {
			document.getElementById("labelFeedbackLabel").innerHTML = "Neutral";
			document.getElementById("labelFeedbackLabel").style.width = "120px";
		} else if (rate === 3) {
			document.getElementById("labelFeedbackLabel").innerHTML = "Good";
			document.getElementById("labelFeedbackLabel").style.width = "120px";
		} else {
			document.getElementById("labelFeedbackLabel").innerHTML = "";
			document.getElementById("labelFeedbackLabel").style.width = "0";
		}
	};

	handleRatingPromptCloseEvent = () => {
		this.setState({showRatingAlert: false, feedbackAlert: false});
		if (this.state.isShowPremiumPrompt) {
			this.setState({
				showPremiumPrompt: true,
			});
		}
	};

	setModal = () => {
		this.setState({
			showDemoPopUp: true,
			showDownloadPopUp: false,
		});
	};

	sleep = async duration => {
		return new Promise(resolve => {
			setTimeout(resolve, duration);
		});
	};

	handleGenerateQuestions = async e => {
		this.props.dispatch(startLoader());
		await this.sleep(3000);
		if (localStorage.getItem("hit") === 1) {
			this.setState({showDemoPopUp: true});
			this.props.dispatch(stopLoader());
			return;
		}
		localStorage.setItem("hit", 1);

		if (this.state.showQuestionsTemplate) {
			var questions = [
				{
					question_id: 1,
					content_id: 1,
					topic: "World is Adopting Technology in Education",
					category_type: 1,
					question: [
						"Ques  : What is one of the main advantages of using technology in education? ",
					],
					is_required: 1,
					question_rating: 0,
					option_rating: 0,
					options: [
						"Technology can be used to create non-targeted and engaging learning experiences.",
						"Technology can help improve teaching and learning.*",
						"Some students may not have the necessary skills to use technology effectively.",
						"Technology can be distracting for students.",
					],
					pro_tips:
						"One of the main advantages of using technology in education is that it can improve teaching and learning. This is because technology can be used to create targeted and engaging learning experiences.",
					more_options: "''",
					comment: null,
				},
				{
					question_id: 2,
					content_id: 1,
					topic: "World is Adopting Technology in Education",
					category_type: 2,
					question: [
						"Ques  : Which of the following is true about using technology in education?",
						"I. Technology can help to improve the overall quality of education.",
						"II. Real-time feedback can be given to both students and teachers.",
						"III. It can be very challenging to adopt technology in education.",
						"IV. Technology may be distracting for some students.",
						"Which of the options given above is/are correct:",
					],
					is_required: 1,
					question_rating: 0,
					option_rating: 0,
					options: [
						"III and I only.",
						"II only.",
						"II, IV and I only.*",
						"All the above.",
					],
					pro_tips:
						"Additionally, technology can be used to provide real-time feedback to both students and teachers. This can help to improve the quality of education overall. However, there are also some disadvantages to using technology in education. One of the main disadvantages is that it can be distracting for students.",
					more_options: "''",
					comment: null,
				},
				{
					question_id: 3,
					content_id: 1,
					topic: "World is Adopting Technology in Education",
					category_type: 5,
					question: [
						"Ques  : What are some advantages & disadvantages of using technology in Education?",
					],
					is_required: 1,
					question_rating: 0,
					option_rating: 0,
					options: [
						<>
							<br />
							<b>Advantages include</b>
							<br /> 1) Massive improvement in teaching and learning
							process <br />
							2) You can provide real-time feedback to both students and
							teachers <br />
							3) Create targeted and engaging learning experiences.{" "}
							<br />
							<br />
							<b>Disadvantages include</b> <br />
							1) Distraction for students in rare cases. <br />
							2) Some students may not have the necessary skills to use
							technology effectively &nbsp;so proper guidance in starting
							might help here. Overall, the advantages of using <br />
							technology in education outweigh the disadvantages.
						</>,
					],
					pro_tips:
						"One of the main advantages of using technology in education is that it can improve teaching and learning. This is because technology can be used to create targeted and engaging learning experiences.Additionally, technology can be used to provide real-time feedback to both students and teachers. This can help to improve the quality of education overall. However, there are also some disadvantages to using technology in education. One of the main disadvantages is that it can be distracting for students. This is because students may be tempted to use their devices for things other than learning. Additionally, some students may not have the necessary skills to use technology effectively (proper guidance in starting should be given).",
					more_options: "''",
					comment: null,
				},
			];

			let ques_count = {
				easyCount: 1,
				mediumCount: 1,
				hardCount: 0,
				descriptiveCount: 1,
				trueFalseCount: 0,
				fillUpsCount: 0,
			};

			this.setState({
				questions: [...questions],
				questionPaperGenerated: true,
				ques_count: {...ques_count},
				pageNo: 1,
				questiontypetab: "easymcq",
				totalPages: 1,
				showQuestionsTemplate: false,
			});
			notifySuccess("Questions Generated Successfully.");
			this.scrollToQuestions();
		} else {
			this.handlePopUpOpen();
		}
		this.props.dispatch(stopLoader());
	};

	toggleDefaultActiveTab = state => {
		this.setState(state);
	};

	handleQPRating = async () => {
		if (this.state.questionPaperRating === null) {
			notifyError("Please Rate Question Paper First!");
			return false;
		}
		this.setState({showRatingAlert: false, feedbackAlert: false});
		const QP_Rating_Parm = {
			content_id: this.state.questionPaperId,
			rating: this.state.questionPaperRating,
		};
		//this.props.dispatch(startLoader());
		try {
			const {data: res} = await QuestionPaperRating(QP_Rating_Parm);
			if (res.success === true) {
				notifySuccess(res.message);
				this.setState({
					questionPaperRating: null,
					highlightBadEmoji: null,
					highlightNeutralEmoji: null,
					highlightGoodEmoji: null,
				});
				this.handleRatingPromptCloseEvent();
				//this.setState({ showRatingAlert: false, feedbackAlert: false });
			} else {
				notifyError(res.message);
			}
		} catch (err) {
			console.log(err);
			if (err.response && err.response.status === 422) {
				const {data} = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			} else if (err.response && err.response.status === 401) {
				const {data} = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			} else {
				notifyError(MSG.commonApiError);
			}
		}
		//this.props.dispatch(stopLoader());
	};

	handleQPRatingChange = value => {
		this.setState({
			questionPaperRating: value,
		});
	};

	getRateText = rate => {
		if (rate === 1) {
			document.getElementById("labelFeedbackLabel").innerHTML = "Bad";
			document.getElementById("labelFeedbackLabel").style.width = "120px";
		} else if (rate === 2) {
			document.getElementById("labelFeedbackLabel").innerHTML = "Neutral";
			document.getElementById("labelFeedbackLabel").style.width = "120px";
		} else if (rate === 3) {
			document.getElementById("labelFeedbackLabel").innerHTML = "Good";
			document.getElementById("labelFeedbackLabel").style.width = "120px";
		} else {
			document.getElementById("labelFeedbackLabel").innerHTML = "";
			document.getElementById("labelFeedbackLabel").style.width = "0";
		}
	};

	handleRatingPromptCloseEvent = () => {
		this.setState({showRatingAlert: false, feedbackAlert: false});
		if (this.state.isShowPremiumPrompt) {
			this.setState({
				showPremiumPrompt: true,
			});
		}
	};

	setModal = () => {
		this.setState({
			showDemoPopUp: true,
			showDownloadPopUp: false,
		});
	};

	sleep = async duration => {
		return new Promise(resolve => {
			setTimeout(resolve, duration);
		});
	};

	handleGenerateQuestions = async e => {
		this.props.dispatch(startLoader());
		await this.sleep(3000);
		if (localStorage.getItem("hit") === 1) {
			this.setState({showDemoPopUp: true});
			this.props.dispatch(stopLoader());
			return;
		}
		localStorage.setItem("hit", 1);

		if (this.state.showQuestionsTemplate) {
			var questions = [
				{
					question_id: 1,
					content_id: 1,
					topic: "World is Adopting Technology in Education",
					category_type: 1,
					question: [
						"Ques  : What is one of the main advantages of using technology in education? ",
					],
					is_required: 1,
					question_rating: 0,
					option_rating: 0,
					options: [
						"Technology can be used to create non-targeted and engaging learning experiences.",
						"Technology can help improve teaching and learning.*",
						"Some students may not have the necessary skills to use technology effectively.",
						"Technology can be distracting for students.",
					],
					pro_tips:
						"One of the main advantages of using technology in education is that it can improve teaching and learning. This is because technology can be used to create targeted and engaging learning experiences.",
					more_options: "''",
					comment: null,
				},
				{
					question_id: 2,
					content_id: 1,
					topic: "World is Adopting Technology in Education",
					category_type: 2,
					question: [
						"Ques  : Which of the following is true about using technology in education?",
						"I. Technology can help to improve the overall quality of education.",
						"II. Real-time feedback can be given to both students and teachers.",
						"III. It can be very challenging to adopt technology in education.",
						"IV. Technology may be distracting for some students.",
						"Which of the options given above is/are correct:",
					],
					is_required: 1,
					question_rating: 0,
					option_rating: 0,
					options: [
						"III and I only.",
						"II only.",
						"II, IV and I only.*",
						"All the above.",
					],
					pro_tips:
						"Additionally, technology can be used to provide real-time feedback to both students and teachers. This can help to improve the quality of education overall. However, there are also some disadvantages to using technology in education. One of the main disadvantages is that it can be distracting for students.",
					more_options: "''",
					comment: null,
				},
				{
					question_id: 3,
					content_id: 1,
					topic: "World is Adopting Technology in Education",
					category_type: 5,
					question: [
						"Ques  : What are some advantages & disadvantages of using technology in Education?",
					],
					is_required: 1,
					question_rating: 0,
					option_rating: 0,
					options: [
						<>
							<br />
							<b>Advantages include</b>
							<br /> 1) Massive improvement in teaching and learning
							process <br />
							2) You can provide real-time feedback to both students and
							teachers <br />
							3) Create targeted and engaging learning experiences.{" "}
							<br />
							<br />
							<b>Disadvantages include</b> <br />
							1) Distraction for students in rare cases. <br />
							2) Some students may not have the necessary skills to use
							technology effectively &nbsp;so proper guidance in starting
							might help here. Overall, the advantages of using <br />
							technology in education outweigh the disadvantages.
						</>,
					],
					pro_tips:
						"One of the main advantages of using technology in education is that it can improve teaching and learning. This is because technology can be used to create targeted and engaging learning experiences.Additionally, technology can be used to provide real-time feedback to both students and teachers. This can help to improve the quality of education overall. However, there are also some disadvantages to using technology in education. One of the main disadvantages is that it can be distracting for students. This is because students may be tempted to use their devices for things other than learning. Additionally, some students may not have the necessary skills to use technology effectively (proper guidance in starting should be given).",
					more_options: "''",
					comment: null,
				},
			];

			let ques_count = {
				easyCount: 1,
				mediumCount: 1,
				hardCount: 0,
				descriptiveCount: 1,
				trueFalseCount: 0,
				fillUpsCount: 0,
			};

			this.setState({
				questions: [...questions],
				questionPaperGenerated: true,
				ques_count: {...ques_count},
				pageNo: 1,
				questiontypetab: "easymcq",
				totalPages: 1,
				showQuestionsTemplate: false,
			});
			notifySuccess("Questions Generated Successfully.");
			this.scrollToQuestions();
		} else {
			this.handlePopUpOpen(e);
		}
		this.props.dispatch(stopLoader());
	};

	toggleDefaultActiveTab = state => {
		this.setState(state);
	};

	componentDidUpdate() {
		if (this.props.isAuthenticated) {
			const refData = localStorage.getItem("refData");
			if (refData) {
				this.props.history.replace("/plans");
			}
		}
	}

	async componentDidMount() {
		if (this.props.isAuthenticated) {
		  this.props.dispatch(fetchUser());
		  const refData = localStorage.getItem("refData");
		  if (refData) {
			this.props.history.replace("/plans");
		  } else {
			this.props.history.replace("/generate-questions");
		  }
		}
		
		if (this.props.location.state !== undefined) {
		  const { state } = this.props.location;
		  if (state.editpaper) {
			this.props.dispatch(startLoader());
			const req = {
			  content_id: state.editpaperid,
			};
			this.setState({
			  iseditpaper: false,
			});
			try {
			  const { data: res } = await editQuestionPaper(req);
			  if (res.success === true) {
				notifySuccess(res.message);
				if (!_.isEmpty(res.response)) {
				  let ques_count = {
					easyCount: 0,
					mediumCount: 0,
					hardCount: 0,
					descriptiveCount: 0,
					trueFalseCount: 0,
					fillUpsCount: 0,
				  };
				  res.response.question_table_data.map((question) => {
					switch (question.category_type) {
					  case 1:
						ques_count.easyCount += 1;
						break;
					  case 2:
						ques_count.mediumCount += 1;
						break;
					  case 3:
						ques_count.hardCount += 1;
						break;
					  case 4:
						ques_count.trueFalseCount += 1;
						break;
					  case 5:
						ques_count.descriptiveCount += 1;
						break;
					  case 6:
						ques_count.fillUpsCount += 1;
						break;
					  default:
						break;
					}
				  });
				  const contentData = res.response.content_table_data;
				  if (_.isEmpty(contentData.wiki_topic)) {
					this.setState({
					  titleofQuestion: res.response.content_table_data.topic,
					  contentForQuestions:
						res.response.content_table_data.content_text,
					  questions: [...res.response.question_table_data],
					  questionPaperGenerated: true,
					  defaultActiveTab: "custom-tab",
					  ques_count: { ...ques_count },
					});
				  } else {
					this.setState({
					  titleofQuestion: res.response.content_table_data.topic,
					  selectedWikiTopic: {
						label: res.response.content_table_data.wiki_topic,
						value: res.response.content_table_data.wiki_topic,
					  },
					  tmpselectedWikiTopic: {
						label: res.response.content_table_data.wiki_topic,
						value: res.response.content_table_data.wiki_topic,
					  },
					  wikiContent: res.response.content_table_data.content_text,
					  questions: [...res.response.question_table_data],
					  questionPaperGenerated: true,
					  defaultActiveTab: "wiki",
					  ques_count: { ...ques_count },
					});
				  }
				  this.scrollToQuestions();
				}
			  } else {
				notifyError(res.message);
				this.resetQuestionState();
			  }
			} catch (err) {
			  console.log(err);
			  this.resetQuestionState();
			  if (err.response && err.response.status === 422) {
				const { data } = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			  } else if (err.response && err.response.status === 401) {
				const { data } = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			  } else {
				notifyError(MSG.commonApiError);
			  }
			}
			this.props.dispatch(stopLoader());
		  }
		}
		if (this.props.user.is_instruction_readed === 0) {
		  this.setState(
			{
			  showAlert: true,
			},
			async () => {
			  await updateInstructionRead();
			  this.props.dispatch(fetchUser());
			}
		  );
		}
	  }

	render() {
		let easymcq = 0;
		let mediummcq = 0;
		let hardmcq = 0;
		let true_false = 0;
		let fillups = 0;
		let descriptive = 0;
		let is_new_pricing = 1;
		return (
			<React.Fragment>
				<DemoHeader
					handlePopUpOpen={this.handlePopUpOpen}
					showHeaderTextPlan={this.showHeaderTextPlan}
					props={this.props}
				/>
				<DemoHeader
					handlePopUpOpen={this.handlePopUpOpen}
					showHeaderTextPlan={this.showHeaderTextPlan}
					props={this.props}
				/>
				<div style={{top: "80px", position: "relative"}}>
					<Salebar></Salebar>
				</div>
				<Tooltip title='Coming Soon' id='global' />
				<div
					className={classNames(
						styles["mobile-height-cover"],
						"height-cover bg-e5"
					)}
				>
					<div className='bg--overlay--top'></div>
					<div
						className={classNames(
							"container",
							styles["demo-custom-margin"],
							"mb-4 position-relative"
						)}
					>
						<div
							className={classNames(
								styles["generate--bg--title"],
								"generate--bg--title"
							)}
						>
							<h3>Generate Interactive Quiz Instantly</h3>
							<span>Most Advanced Test Generator</span>
						</div>
						<div>
							<ToggleForGQ tab='Standard' />
						</div>
						<DemoGenerateQuestionsStdHots
							handleSubmit={this.handleSubmit}
							state={this.state}
							handleTitleChange={this.handleTitleChange}
							handleChange={this.handleChange}
							props={this.props}
							handlePdfDocFileStatusChange={
								this.handlePdfDocFileStatusChange
							}
							handlePopUpOpen={this.handlePopUpOpen}
							handleWikiTopicBlur={this.handleWikiTopicBlur}
							handleWikiTopicFocus={this.handleWikiTopicFocus}
							onInputChange={this.onInputChange}
							getFilesFromEvent={this.getFilesFromEvent}
							handleFileStatusChange={this.handleFileStatusChange}
							handleGenerateQuestions={this.handleGenerateQuestions}
							handlePopUpClose={this.handlePopUpClose}
							handleWikiTopicSelect={this.handleWikiTopicSelect}
							videoExtractButtonContent={this.videoExtractButtonContent}
							getDocPdfFromEvent={this.getDocPdfFromEvent}
							setState={this.toggleDefaultActiveTab}
						/>
					</div>
				</div>
				{this.state.questionPaperGenerated && (
					<div className='section--padding bg-e5' id='generated_questions'>
						<div
							className={classNames(
								"container",
								styles["demo-custom-container"]
							)}
						>
							<div className='row'>
								<div
									className='offset-md-1 mb-4'
									style={{width: "100%", marginLeft: "1.333333%"}}
								>
									<div
										className={classNames(
											styles["generated-questions-top-section"],
											"row"
										)}
									>
										<div className='col-md-4'>
											<p
												className={classNames(
													styles[
														"section-generated-title-responsive"
													],
													"section-generated-title"
												)}
												// style={{ marginLeft: "29.5%" }}
											>
												Generated Questions
											</p>
										</div>
										<div
											className={classNames(
												styles["front-action-buttons-responsive"],
												"col-md-8 front-action-buttons"
											)}
										>
											<div
												className={classNames(
													"nav-item dropdown fl-center"
												)}
											>
												<a
													className={classNames(
														styles[
															"front-action-buttons-text-responsive"
														],
														"nav-link dropdown-toggle dropdown-title-front"
													)}
													href='/#'
													id='navbarDropdown'
													role='button'
													data-toggle='dropdown'
													aria-haspopup='true'
													aria-expanded='false'
												>
													Conduct Test
												</a>
												<div
													className={classNames(
														styles["conduct-test-dropdown"],
														"dropdown-menu navdrop--menu dropdown-main"
													)}
													aria-labelledby='navbarDropdown'
												>
													<button
														// to="#"
														onClick={() =>
															this.setState({
																showDemoPopUp: true,
															})
														}
														className={classNames(
															styles[
																"front-action-buttons-text-responsive"
															],
															"dropdown-item"
														)}
													>
														For Self
													</button>
													<button
														// to="#"
														onClick={() =>
															this.setState({
																showDemoPopUp: true,
															})
														}
														className={classNames(
															styles[
																"front-action-buttons-text-responsive"
															],
															"dropdown-item"
														)}
													>
														For Others
														
													</button>
													
												</div>
											</div>
											<div className=''>
												<button
													type='button'
													role='button'
													style={{textDecoration: "none"}}
													className={classNames(
														styles[
															"front-action-buttons-text-responsive"
														],
														"preview--paper--btn"
													)}
													// to="#"
													onClick={() =>
														this.setState({showPreview: true})
													}
												>
													Preview Quiz
												</button>
											</div>
											<div>
												<button
													type='button'
													role='button'
													style={{textDecoration: "none"}}
													className={classNames(
														styles[
															"front-action-buttons-text-responsive"
														],
														"preview--paper--btn"
													)}
													// to={"#"}
													onClick={e => {
														this.setState({showDemoPopUp: true});
													}}
												>
													Generate Sets
												</button>
											</div>
											<div>
												<button
													type='button'
													role='button'
													style={{textDecoration: "none"}}
													className={classNames(
														styles[
															"front-action-buttons-text-responsive"
														],
														"preview--paper--btn"
													)}
													// to={"#"}
													onClick={e => {
														this.setState({
															showDownloadPopUp: true,
														});
													}}
												>
													Download
												</button>
												<DonwloadQuestionPaper
													isDemo={true}
													setDemoModal={this.setModal}
													showDownloadPopUp={
														this.state.showDownloadPopUp
													}
													closeDownloadPopUp={() =>
														this.setState({
															showDownloadPopUp: false,
														})
													}
													downloadAllowed={this.state.currentItem}
													downloadQuestionPaper={
														this.handleDownloadFile
													}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className='col-md-3'>
									<div className='left-widget-area'>
										<div
											className={classNames(
												styles["category-widget-responsive"],
												"category-widget"
											)}
										>
											<p
												className={classNames(
													styles["categories-title"]
												)}
												style={{
													marginBottom:
														window.innerWidth > 767
															? "1rem"
															: this.state.categoryAccordion
															? "1rem"
															: "0rem",
												}}
											>
												Categories
												{window.innerWidth <= 767 && (
													<>
														{this.state.categoryAccordion ? (
															<ExpandLess
																onClick={() =>
																	this.setState({
																		categoryAccordion:
																			!this.state
																				.categoryAccordion,
																	})
																}
															/>
														) : (
															<ExpandMore
																onClick={() =>
																	this.setState({
																		categoryAccordion:
																			!this.state
																				.categoryAccordion,
																	})
																}
															/>
														)}
													</>
												)}
											</p>
											{window.innerWidth <= 767 &&
												this.state.categoryAccordion && (
													<div className='custom-nav-pills-column'>
														<div
															className='nav flex-column nav-pills'
															id='v-pills-tab'
															role='tablist'
															aria-orientation='vertical'
														>
															<a
																className={classNames(
																	styles["category-nav-link"],
																	"nav-link active"
																)}
																id='v-pills-easy-tab'
																data-toggle='pill'
																href='#v-pills-easy'
																role='tab'
																aria-controls='v-pills-easy'
																aria-selected='true'
																onClick={e => {
																	e.preventDefault();
																	this.setState({
																		questiontypetab:
																			"easymcq",
																	});
																}}
															>
																Easy MCQ
																<span>
																	{
																		this.state.ques_count
																			.easyCount
																	}
																</span>
															</a>
															<a
																className={classNames(
																	styles["category-nav-link"],
																	"nav-link"
																)}
																id='v-pills-medium-tab'
																data-toggle='pill'
																href='#v-pills-medium'
																role='tab'
																aria-controls='v-pills-medium'
																aria-selected='false'
																onClick={e => {
																	e.preventDefault();
																	this.setState({
																		questiontypetab:
																			"mediummcq",
																	});
																}}
															>
																Medium MCQ
																{/* {is_new_pricing === 1 && (
                                  <img
                                    src="img/crown.jpg"
                                    alt="icon"
                                    width="30"
                                    height="30"
                                    style={{
                                      position: "absolute",
                                      marginTop: "-12px",
                                      marginLeft: "-7px",
                                    }}
                                  />
                                )} */}
																<span>
																	{
																		this.state.ques_count
																			.mediumCount
																	}
																</span>
															</a>
															<a
																className={classNames(
																	styles["category-nav-link"],
																	"nav-link"
																)}
																id='v-pills-hard-tab'
																// data-toggle="pill"
																href='#v-pills-hard'
																role='tab'
																aria-controls='v-pills-hard'
																aria-selected='false'
																// onClick={(e) => {
																//   e.preventDefault();
																//   this.setState({ questiontypetab: "hardmcq" });
																// }}
																onClick={e =>
																	this.handlePopUpOpen(e)
																}
															>
																Hard MCQ{" "}
																{/* {is_new_pricing === 1 && (
                                  <img
                                    src="img/crown.jpg"
                                    alt="icon"
                                    width="30"
                                    height="30"
                                    style={{
                                      position: "absolute",
                                      marginTop: "-12px",
                                      marginLeft: "-7px",
                                    }}
                                  />
                                )} */}
																<span>
																	{
																		this.state.ques_count
																			.hardCount
																	}
																</span>
															</a>
															<a
																className={classNames(
																	styles["category-nav-link"],
																	"nav-link"
																)}
																id='v-pills-truefalse-tab'
																// data-toggle="pill"
																href='#v-pills-truefalse'
																role='tab'
																aria-controls='v-pills-truefalse'
																aria-selected='false'
																// onClick={(e) => {
																//   e.preventDefault();
																//   this.setState({ questiontypetab: "truefalse" });
																// }}
																onClick={e =>
																	this.handlePopUpOpen(e)
																}
															>
																True / False{" "}
																{/* {is_new_pricing === 1 && (
                                  <img
                                    src="img/crown.jpg"
                                    alt="icon"
                                    width="30"
                                    height="30"
                                    style={{
                                      position: "absolute",
                                      marginTop: "-12px",
                                      marginLeft: "-7px",
                                    }}
                                  />
                                )} */}
																<span>
																	{
																		this.state.ques_count
																			.trueFalseCount
																	}
																</span>
															</a>
															<a
																className={classNames(
																	styles["category-nav-link"],
																	"nav-link"
																)}
																id='v-pills-Descriptive-tab'
																data-toggle='pill'
																href='#v-pills-Descriptive'
																role='tab'
																aria-controls='v-pills-Descriptive'
																aria-selected='false'
																onClick={e => {
																	e.preventDefault();
																	this.setState({
																		questiontypetab:
																			"descriptive",
																	});
																}}
															>
																Descriptive
																<span>
																	{
																		this.state.ques_count
																			.descriptiveCount
																	}
																</span>
															</a>
															<a
																className={classNames(
																	styles["category-nav-link"],
																	"nav-link"
																)}
																id='v-pills-Fillups-tab'
																// data-toggle="pill"
																href='#v-pills-Fillups'
																role='tab'
																aria-controls='v-pills-Fillups'
																aria-selected='false'
																// onClick={(e) => {
																//   e.preventDefault();
																//   this.setState({ questiontypetab: "fillups" });
																// }}
																onClick={e =>
																	this.handlePopUpOpen(e)
																}
															>
																Fillups{" "}
																{/* {is_new_pricing === 1 && (
                                  <img
                                    src="img/crown.jpg"
                                    alt="icon"
                                    width="30"
                                    height="30"
                                    style={{
                                      position: "absolute",
                                      marginTop: "-12px",
                                      marginLeft: "-7px",
                                    }}
                                  />
                                )} */}
																<span>
																	{
																		this.state.ques_count
																			.fillUpsCount
																	}
																</span>
															</a>
														</div>
													</div>
												)}
											{window.innerWidth > 767 && (
												<div className='custom-nav-pills-column'>
													<div
														className='nav flex-column nav-pills'
														id='v-pills-tab'
														role='tablist'
														aria-orientation='vertical'
													>
														<a
															className={classNames(
																styles["category-nav-link"],
																"nav-link active"
															)}
															id='v-pills-easy-tab'
															data-toggle='pill'
															href='#v-pills-easy'
															role='tab'
															aria-controls='v-pills-easy'
															aria-selected='true'
															onClick={e => {
																e.preventDefault();
																this.setState({
																	questiontypetab: "easymcq",
																});
															}}
														>
															Easy MCQ
															<span>
																{
																	this.state.ques_count
																		.easyCount
																}
															</span>
														</a>
														<a
															className={classNames(
																styles["category-nav-link"],
																"nav-link"
															)}
															id='v-pills-medium-tab'
															data-toggle='pill'
															href='#v-pills-medium'
															role='tab'
															aria-controls='v-pills-medium'
															aria-selected='false'
															onClick={e => {
																e.preventDefault();
																this.setState({
																	questiontypetab: "mediummcq",
																});
															}}
														>
															Medium MCQ{" "}
															<span>
																{
																	this.state.ques_count
																		.mediumCount
																}
															</span>
														</a>
														<a
															className={classNames(
																styles["category-nav-link"],
																"nav-link"
															)}
															id='v-pills-hard-tab'
															// data-toggle="pill"
															href='#v-pills-hard'
															role='tab'
															aria-controls='v-pills-hard'
															aria-selected='false'
															// onClick={(e) => {
															//   e.preventDefault();
															//   this.setState({ questiontypetab: "hardmcq" });
															// }}
															onClick={e =>
																this.handlePopUpOpen(e)
															}
														>
															Hard MCQ{" "}
															{/* {is_new_pricing === 1 && (
                                <img
                                  src="img/crown.jpg"
                                  alt="icon"
                                  width="30"
                                  height="30"
                                  style={{
                                    position: "absolute",
                                    marginTop: "-12px",
                                    marginLeft: "-7px",
                                  }}
                                />
                              )} */}
															<span>
																{
																	this.state.ques_count
																		.hardCount
																}
															</span>
														</a>
														<a
															className={classNames(
																styles["category-nav-link"],
																"nav-link"
															)}
															id='v-pills-truefalse-tab'
															// data-toggle="pill"
															href='#v-pills-truefalse'
															role='tab'
															aria-controls='v-pills-truefalse'
															aria-selected='false'
															// onClick={(e) => {
															//   e.preventDefault();
															//   this.setState({ questiontypetab: "truefalse" });
															// }}
															onClick={e =>
																this.handlePopUpOpen(e)
															}
														>
															True / False{" "}
															{/* {is_new_pricing === 1 && (
                                <img
                                  src="img/crown.jpg"
                                  alt="icon"
                                  width="30"
                                  height="30"
                                  style={{
                                    position: "absolute",
                                    marginTop: "-12px",
                                    marginLeft: "-7px",
                                  }}
                                />
                              )} */}
															<span>
																{
																	this.state.ques_count
																		.trueFalseCount
																}
															</span>
														</a>
														<a
															className={classNames(
																styles["category-nav-link"],
																"nav-link"
															)}
															id='v-pills-Descriptive-tab'
															data-toggle='pill'
															href='#v-pills-Descriptive'
															role='tab'
															aria-controls='v-pills-Descriptive'
															aria-selected='false'
															onClick={e => {
																e.preventDefault();
																this.setState({
																	questiontypetab:
																		"descriptive",
																});
															}}
														>
															Descriptive
															<span>
																{
																	this.state.ques_count
																		.descriptiveCount
																}
															</span>
														</a>
														<a
															className={classNames(
																styles["category-nav-link"],
																"nav-link"
															)}
															id='v-pills-Fillups-tab'
															// data-toggle="pill"
															href='#v-pills-Fillups'
															role='tab'
															aria-controls='v-pills-Fillups'
															aria-selected='false'
															// onClick={(e) => {
															//   e.preventDefault();
															//   this.setState({ questiontypetab: "fillups" });
															// }}
															onClick={e =>
																this.handlePopUpOpen(e)
															}
														>
															Fillups{" "}
															{/* {is_new_pricing === 1 && (
                                <img
                                  src="img/crown.jpg"
                                  alt="icon"
                                  width="30"
                                  height="30"
                                  style={{
                                    position: "absolute",
                                    marginTop: "-12px",
                                    marginLeft: "-7px",
                                  }}
                                />
                              )} */}
															<span>
																{
																	this.state.ques_count
																		.fillUpsCount
																}
															</span>
														</a>
													</div>
												</div>
											)}
										</div>
										<button
											type='button'
											className={classNames(
												styles["add-quetsion--btn"],
												"add-quetsion--btn w-100"
											)}
											// onClick={this.addquestion}
											// onClick={this.handleAddQuestionOnClick}
											onClick={e => this.handlePopUpOpen(e)}
										>
											<svg
												className='mr-2'
												width='16'
												height='15'
												viewBox='0 0 16 15'
												fill='none'
											>
												<path
													d='M8.38021 1V14M1.8125 7.5H14.9479'
													stroke='white'
													strokeWidth='1.5'
												/>
											</svg>
											Add Question{" "}
											{/* {is_new_pricing === 1 && (
                        <img
                          src="img/crown.jpg"
                          alt="icon"
                          width="30"
                          height="30"
                          style={{
                            position: "absolute",
                            marginTop: "-12px",
                            marginLeft: "-7px",
                          }}
                        />
                      )} */}
										</button>
									</div>
								</div>
								<div className='col-md-9'>
									<div className='tab-content' id='v-pills-tabContent'>
										<div
											className='tab-pane fade show active'
											id='v-pills-easy'
											role='tabpanel'
											aria-labelledby='v-pills-easy-tab'
										>
											<div
												className={classNames(
													styles["qna-wrapper"],
													"question-wrapper--outer"
												)}
											>
												{this.state.ques_count.easyCount === 0 && (
													<p>No Questions Formed!</p>
												)}
												{this.state.questions.map(question => {
													if (question.category_type === 1) {
														easymcq = easymcq + 1;
														return (
															<EasyMCQ
																key={`question_${question.question_id}`}
																question={question}
																count={easymcq}
																handleQuestionUpdate={
																	this.handleQuestionUpdate
																}
																disable={true}
															/>
														);
													}
												})}
												{this.state.addeasymcq === true ? (
													<div>
														<div id='letx' />
														<br />
														<Addeasymcq
															questions={this.state.questions}
															handlestate={
																this.handlestateforaddquestion
															}
															parentCallback={
																this.handleCallback
															}
															discardquestion={
																this.handlediscard
															}
														></Addeasymcq>{" "}
													</div>
												) : (
													" "
												)}
											</div>
										</div>
										<div
											className='tab-pane fade'
											id='v-pills-medium'
											role='tabpanel'
											aria-labelledby='v-pills-medium-tab'
										>
											<div
												className={classNames(
													styles["qna-wrapper"],
													"question-wrapper--outer"
												)}
											>
												{this.state.ques_count.mediumCount === 0 && (
													<p>No Questions Formed!</p>
												)}
												{this.state.questions.map(question => {
													if (question.category_type === 2) {
														mediummcq = mediummcq + 1;
														return (
															<MediumMCQ
																key={`question_${question.question_id}`}
																question={question}
																count={mediummcq}
																handleQuestionUpdate={
																	this.handleQuestionUpdate
																}
																disable={true}
															/>
														);
													}
												})}
												{this.state.addmediummcq === true ? (
													<div>
														<br />
														<Addmediummcq
															questions={this.state.questions}
															handlestate={
																this.handlestateforaddquestion
															}
															parentCallback={
																this.handleCallback
															}
															discardquestion={
																this.handlediscard
															}
														></Addmediummcq>{" "}
													</div>
												) : (
													" "
												)}
											</div>
										</div>
										<div
											className='tab-pane fade'
											id='v-pills-hard'
											role='tabpanel'
											aria-labelledby='v-pills-hard-tab'
										>
											<div
												className={classNames(
													styles["qna-wrapper"],
													"question-wrapper--outer"
												)}
											>
												{this.state.ques_count.hardCount === 0 && (
													<p>No Questions Formed!</p>
												)}
												{this.state.questions.map(question => {
													if (question.category_type === 3) {
														hardmcq = hardmcq + 1;
														return (
															<HardMCQ
																key={`question_${question.question_id}`}
																question={question}
																count={hardmcq}
																handleQuestionUpdate={
																	this.handleQuestionUpdate
																}
															/>
														);
													}
												})}
												{this.state.addhardmcq === true ? (
													<div>
														<br />
														<Addhardmcq
															questions={this.state.questions}
															handlestate={
																this.handlestateforaddquestion
															}
															parentCallback={
																this.handleCallback
															}
															discardquestion={
																this.handlediscard
															}
														></Addhardmcq>{" "}
													</div>
												) : (
													" "
												)}
											</div>
										</div>
										<div
											className='tab-pane fade'
											id='v-pills-truefalse'
											role='tabpanel'
											aria-labelledby='v-pills-truefalse-tab'
										>
											<div
												className={classNames(
													styles["qna-wrapper"],
													"question-wrapper--outer"
												)}
											>
												{this.state.ques_count.trueFalseCount ==
													0 && <p>No Questions Formed!</p>}
												{this.state.questions.map(question => {
													if (question.category_type === 4) {
														true_false = true_false + 1;
														return (
															<TrueFalse
																key={`question_${question.question_id}`}
																question={question}
																count={true_false}
																handleQuestionUpdate={
																	this.handleQuestionUpdate
																}
															/>
														);
													}
												})}
												{this.state.addtruefalse === true ? (
													<div>
														<br />
														<Truefalse
															questions={this.state.questions}
															handlestate={
																this.handlestateforaddquestion
															}
															parentCallback={
																this.handleCallback
															}
															discardquestion={
																this.handlediscard
															}
														></Truefalse>{" "}
													</div>
												) : (
													" "
												)}
											</div>
										</div>
										<div
											className='tab-pane fade'
											id='v-pills-Descriptive'
											role='tabpanel'
											aria-labelledby='v-pills-Descriptive-tab'
										>
											<div
												className={classNames(
													styles["qna-wrapper"],
													"question-wrapper--outer"
												)}
											>
												{this.state.ques_count.descriptiveCount ==
													0 && <p>No Questions Formed!</p>}
												{this.state.questions.map(question => {
													if (question.category_type === 5) {
														descriptive = descriptive + 1;
														return (
															<Descriptive
																key={`question_${question.question_id}`}
																question={question}
																count={descriptive}
																handleQuestionUpdate={
																	this.handleQuestionUpdate
																}
																disable={true}
																setModal={this.setModal}
																isDemo={true}
															/>
														);
													}
												})}
												{this.state.adddescriptive === true ? (
													<div>
														<br />
														<AddDescriptive
															questions={this.state.questions}
															handlestate={
																this.handlestateforaddquestion
															}
															parentCallback={
																this.handleCallback
															}
															discardquestion={
																this.handlediscard
															}
														></AddDescriptive>{" "}
													</div>
												) : (
													" "
												)}
											</div>
										</div>
										<div
											className='tab-pane fade'
											id='v-pills-Fillups'
											role='tabpanel'
											aria-labelledby='v-pills-Fillups-tab'
										>
											<div
												className={classNames(
													styles["qna-wrapper"],
													"question-wrapper--outer"
												)}
											>
												{this.state.ques_count.fillUpsCount ==
													0 && <p>No Questions Formed!</p>}
												{this.state.questions.map(question => {
													if (question.category_type === 6) {
														fillups = fillups + 1;
														return (
															<FillUpsMCQ
																key={`question_${question.question_id}`}
																question={question}
																count={fillups}
																handleQuestionUpdate={
																	this.handleQuestionUpdate
																}
															/>
														);
													}
												})}
												{this.state.addfillups === true ? (
													<div>
														<br />
														<Fillups
															questions={this.state.questions}
															handlestate={
																this.handlestateforaddquestion
															}
															parentCallback={
																this.handleCallback
															}
															discardquestion={
																this.handlediscard
															}
														></Fillups>{" "}
													</div>
												) : (
													" "
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				<SweetAlert
					customClass={classNames(
						styles["swal-preview"],
						"sweet-alert-preview"
					)}
					show={this.state.showPreview}
					showConfirm
					confirmBtnText='Okay'
					confirmBtnStyle={{fontSize: "16px"}}
					confirmBtnBsStyle='success'
					onEscapeKey={() => this.setState({showPreview: false})}
					onOutsideClick={() => this.setState({showPreview: false})}
					onConfirm={() => this.setState({showPreview: false})}
					onCancel={() => this.setState({showPreview: false})}
				>
					<button
						type='button'
						className={classNames(
							styles["swal-cross-btn"],
							"close sets-standard-close"
						)}
						aria-label='Close'
						style={{outline: "none"}}
						onClick={() => this.setState({showPreview: false})}
					>
						<span aria-hidden='true'>&times;</span>
					</button>
					<QuestionPaperPreview
						location={{
							state: {
								topic: this.state.titleofQuestion,
								content_id: this.state.questions[0]?.content_id,
								ques_count: this.state.ques_count,
							},
						}}
						isModalPreview={true}
						isDemo={true}
					/>
				</SweetAlert>
				<SweetAlert
					show={this.state.showRatingAlert}
					showConfirm
					showCloseButton
					style={{zIndex: 99999999}}
					title={
						<>
							<div className='swal-titile'>
								<h4>
									<span>Your Question Paper is being Generated.</span>
									<br />
									<span>
										Meanwhile, what do you think of your recent
										Question Paper?{" "}
									</span>
								</h4>
							</div>
							<div className='rate--question large-star1 mt-4'>
								<span className='feedbackLabel'>
									<span id='labelFeedbackLabel'></span>
								</span>
								<div className='line-height-0 d-flex justify-content-center pt-4'>
									<div id='bad' className='emoji-scale'>
										<span className='describeEmoji'>Bad</span>
										<i
											className={`emoji ${
												this.state.questionPaperRating === 1
													? "fas"
													: "far"
											} fa-frown highlight ${
												this.state.highlightBadEmoji
											}`}
											onClick={() =>
												this.setState({
													questionPaperRating: 1,
													highlightNeutralEmoji: null,
													highlightGoodEmoji: null,
													highlightBadEmoji: "highlight-emoji",
												})
											}
										></i>
									</div>
									<div id='neutral' className='emoji-scale'>
										<span className='describeEmoji'>Neutral</span>
										<i
											className={`emoji ${
												this.state.questionPaperRating === 2
													? "fas"
													: "far"
											} fa-meh highlight ${
												this.state.highlightNeutralEmoji
											}`}
											onClick={() =>
												this.setState({
													questionPaperRating: 2,
													highlightBadEmoji: null,
													highlightGoodEmoji: null,
													highlightNeutralEmoji: "highlight-emoji",
												})
											}
										></i>
									</div>
									<div id='Good' className='emoji-scale'>
										<span className='describeEmoji'>Good</span>
										<i
											className={`emoji ${
												this.state.questionPaperRating === 3
													? "fas"
													: "far"
											} fa-grin-alt highlight ${
												this.state.highlightGoodEmoji
											}`}
											onClick={() =>
												this.setState({
													questionPaperRating: 3,
													highlightBadEmoji: null,
													highlightNeutralEmoji: null,
													highlightGoodEmoji: "highlight-emoji",
												})
											}
										></i>
									</div>
								</div>
							</div>
						</>
					}
					confirmBtnText='Submit'
					confirmBtnStyle={{fontSize: "16px"}}
					confirmBtnBsStyle='success'
					onEscapeKey={this.handleRatingPromptCloseEvent}
					onOutsideClick={this.handleRatingPromptCloseEvent}
					onConfirm={this.handleQPRating}
					onCancel={this.handleRatingPromptCloseEvent}
				></SweetAlert>
				<SweetAlert
					customClass='sweet-alert'
					show={this.state.showAlert}
					showConfirm
					title={
						<div className='swal-title'>
							<h2>Please Read Carefully</h2>
							<hr />
							<ol>
								<li className='instruction-points'>
									PrepAI only supports content in the English language.
								</li>
								<li className='instruction-points'>
									PrepAI is best suited for subjective kind of content
									for now.
								</li>
								<li className='instruction-points'>
									PrepAI doesn't consider mathematical equations,
									chemical reactions, numerical, tabular data, diagrams
									yet for question generation.
								</li>
							</ol>
						</div>
					}
					confirmBtnText='OK'
					confirmBtnStyle={{fontSize: "16px"}}
					confirmBtnBsStyle='success'
					onEscapeKey={() => this.setState({showAlert: false})}
					onOutsideClick={() => this.setState({showAlert: false})}
					onConfirm={() => this.setState({showAlert: false})}
					onCancel={() => this.setState({showAlert: false})}
				/>
				<SweetAlert
					customClass='sweet-alert'
					show={this.state.showPremiumPrompt}
					showConfirm={false}
					title={
						<div className='swal-title'>
							<p className='style-text'>
								{this.state.premiumPromptText}
							</p>
							<Link className='btn prompt-plan-btn' to='/plans'>
								Upgrade Plan
							</Link>
							<span style={{margin: "20px", fontSize: "20px"}}>OR</span>
							<Link
								className='btn prompt-referral-btn'
								to='/refer-prepai'
							>
								Refer PrepAI
							</Link>
						</div>
					}
					onEscapeKey={() => this.setState({showPremiumPrompt: false})}
					onOutsideClick={() => this.setState({showPremiumPrompt: false})}
					onConfirm={() => this.setState({showPremiumPrompt: false})}
					onCancel={() => this.setState({showPremiumPrompt: false})}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	};
};

export default connect(mapStateToProps)(DemoPage);
