import React, { Component } from "react";
import { fetchUser, updateUserDetails } from "../../services";
import MSG, { findUsList, notifyError, notifySuccess, profession } from "../../utils/Helper";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { updateProfileDetails } from "../../store/reducers/auth";

class ProfileUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            source_from: "",
            source_from_other: "",
            profession: props.user.profession,
            profession_other: props.user.profession_other,
            data: {
              contact_number: props.user.contact_number,
              country_code: "in",
              dial_code: props.user.dial_code,
              formatted_phone_number: "",
            },
            showLoader: false,
            showUpdateProfilePopUp: true,
            isShowProfilePopUp: false,
        }
    }

  UpdateProfileSubmit = async () => {
    if (this.state.profession == "") {
      notifyError("Please select an option under profession section");
      return;
    } else if (
      this.state.profession == "Others" &&
      this.state.profession_other == ""
    ) {
      notifyError("Please enter your profession");
      return;
    } else if (this.state.source_from == "") {
      notifyError("Please select an option under source section");
      return;
    } else if (
      this.state.source_from == "Others" &&
      this.state.source_from_other == ""
    ) {
      notifyError("Please enter your profession");
      return;
    } else if (this.state.data.contact_number == "") {
      notifyError("Please enter your phone details");
      return;
    }

    let reqObj = this.state.data;
    let req = {
      ...reqObj,
      profession: this.state.profession,
      profession_other: this.state.profession_other,
      source_from: this.state.source_from,
      source_from_other: this.state.source_from_other,
    };
    req.contact_number = req.contact_number.substring(req.dial_code.length - 1);

    this.setState({
      showLoader: !this.state.showLoader,
    });

    try {
      this.props.dispatch(updateProfileDetails({contact_number: req.contact_number}))
      const { data: res } = await updateUserDetails(req);
      if (res.success == true) {
        await this.props.dispatch(fetchUser());
        if (this.props.user.update_profile_index == 0) {
          notifySuccess(res.message);
          this.setState({
            showUpdateProfilePopUp: false,
            isShowProfilePopUp: false,
          });
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.setState({
      showLoader: !this.state.showLoader,
    });
  };

  CancelUpdateProfile = async () => {
    this.setState({
      showLoader: !this.state.showLoader,
    });

    try {
      const { data: res } = await updateUserDetails({
        ask_later: 1,
      });
      if (res.success == true) {
        await this.props.dispatch(fetchUser());
        if (this.props.user.update_profile_index == 0) {
          notifySuccess(res.message);
          this.setState({
            showUpdateProfilePopUp: false,
            isShowProfilePopUp: false,
          });
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.setState({
      showLoader: !this.state.showLoader,
    });
  };
  handleUpdateProfileChange = ({ currentTarget: input }) => {
    this.setState({
      [input.name]: input.value,
    });
  };
  handleChange = ({ currentTarget: input }) => {
    this.setState({
      [input.name]: input.value,
    });
  };
  handleOnChange = (value, data, event, formattedValue) => {
    if (value !== undefined) {
      this.setState({
        data: {
          ...this.state.data,
          contact_number: value,
          formatted_phone_number: formattedValue,
          country_code: data.countryCode,
          dial_code: "+" + data.dialCode,
        },
      });
    }
  };
  render() {
    return (
      <SweetAlert
        customClass="sweet-alert"
        show={window.innerWidth > 767 && this.state.showUpdateProfilePopUp}
        showConfirm
        showCancel
        title="Please complete your profile to continue"
        confirmBtnText="Finish"
        cancelBtnText="Ask Me Later"
        cancelBtnStyle={{ fontSize: "16px" }}
        confirmBtnStyle={{ fontSize: "16px" }}
        confirmBtnBsStyle="success"
        onEscapeKey={() => this.setState({ showUpdateProfilePopUp: false })}
        onOutsideClick={() => this.setState({ showUpdateProfilePopUp: false })}
        onConfirm={this.UpdateProfileSubmit}
        onCancel={this.CancelUpdateProfile}
        style={{ overflow: "initial", marginTop: "150px" }}
      >
        <form className="complete-profile mt-4">
          {/* {this.props.user.update_profile_index == 1 && ( */}
          <div className="row my-2">
            <label className="form--label col-6">
              What best describes your role?*
            </label>

            <div className="select-list col-6" style={{ fontSize: "larger" }}>
              <select
                name="profession"
                className="source_from custom-select"
                style={{ width: "75%", height: "35px" }}
                required
                value={this.state.profession}
                onChange={this.handleUpdateProfileChange}
              >
                <option value="">Select</option>
                {profession().map((op) => {
                  return (
                    <option key={op} value={op}>
                      {op}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {
            this.state.profession === "Others" && (
              // this.props.user.update_profile_index == 1 && (
              <>
                {/* <hr className="mb-2" style={{width: "15%"}}/> */}
                <div className="row my-2">
                  <label
                    htmlFor="profession_other"
                    className="form--label col-6"
                  >
                    Please Specify*
                  </label>
                  <div className="col-6">
                    <input
                      type="text"
                      name="profession_other"
                      id="profession_other"
                      className="form-control"
                      value={this.state.profession_other}
                      onChange={this.handleChange}
                      required
                      style={{ width: "75%", height: "35px" }}
                    />
                  </div>
                </div>
              </>
            )
            // )
          }
          {/* )}{" "} */}
          {/* {this.props.user.update_profile_index == 2 && ( */}
          {/* <hr className="my-2" style={{ width: "15%" }} /> */}
          <div className="row my-2">
            <label className="form--label col-6">
              How did you discover PrepAI?*
            </label>
            <div className="select-list col-6" style={{ fontSize: "larger" }}>
              <select
                name="source_from"
                className="source_from custom-select"
                style={{ width: "75%", height: "35px" }}
                required
                onChange={this.handleUpdateProfileChange}
                value={this.state.source_from}
                disabled={this.state.referralActive}
              >
                <option value="">Select</option>

                {findUsList().map((op) => {
                  return (
                    <option key={op} value={op}>
                      {op}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {
            this.state.source_from === "Others" && (
              // this.props.user.update_profile_index == 2 && (
              <>
                {/* <hr className="mb-2" style={{width: "15%"}}/> */}
                <div className="row my-2">
                  <label
                    htmlFor="source_from_other"
                    className="form--label col-6"
                  >
                    Please Specify*
                  </label>
                  <div className="col-6">
                    <input
                      type="text"
                      name="source_from_other"
                      id="source_from_other"
                      className="form-control"
                      value={this.state.source_from_other}
                      onChange={this.handleChange}
                      required
                      style={{ width: "75%", height: "35px" }}
                    />
                  </div>
                </div>
              </>
            )
            // )
          }
          {/* )} */}
          {/* {this.props.user.update_profile_index == 3 && ( */}
          {/* <hr className="my-2" style={{ width: "15%" }} /> */}
          <div className="row my-2">
            <label className="form--label col-6">
              Update your contact number*
            </label>
            <div
              className="position-relative col-6"
              style={{ fontSize: "larger" }}
            >
              <PhoneInput
                country={this.state.data.country_code}
                countryCodeEditable={false}
                placeholder="Enter phone number"
                name="formatted_phone_number"
                value={this.state.data.formatted_phone_number}
                onChange={this.handleOnChange}
                buttonStyle={{ backgroundColor: "transparent" }}
                style={{ width: "75%" }}
                className="complete-profile-popup"
              />
            </div>
          </div>
          {/* )} */}
          <div
            style={{
              position: "relative",
              marginLeft: "98px",
              marginTop: "18px",
            }}
          ></div>
        </form>
        {this.state.showLoader ? (
          <div className="spinner-border mt-3" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          ""
        )}
      </SweetAlert>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      isAuthenticated: state.auth.isAuthenticated,
      user: state.auth.user,
    };
  };
  
export default connect(mapStateToProps)(ProfileUpdate);