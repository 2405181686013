import {toastr} from "react-redux-toastr";
import _ from "lodash";
import moment from "moment";

export function convertUTCToLocal(mydate) {
	return moment.utc(mydate).local();
}

export function notifySuccess(msg) {
	toastr.success(msg, "");
}

export function notifyError(msg) {
	toastr.error(msg, "");
}

export function notifyWarning(msg) {
	toastr.warning(msg, "");
}

export function notifyInfo(msg) {
	toastr.info(msg, "");
}

export function randomKey() {
	return Math.ceil(Math.random() * 10000);
}

export function isLiveDone() {
	var GivenDate = "2021-10-31 23:59:59";
	var CurrentDate = new Date();
	GivenDate = new Date(GivenDate);

	if (GivenDate >= CurrentDate) {
		return false;
	}
	return true;
}

export function sanitizeInput(inputString) {
	// Define a regular expression to match characters to keep
	var keepPattern = /[a-zA-Z0-9 .,?]/g;

	// Remove characters that are not in the keep pattern
	var sanitizedString = inputString.replace(/[^a-zA-Z0-9 .,?]/g, "");

	// Remove single quotes and double quotes
	sanitizedString = sanitizedString.replace(/['"]/g, "");

	return sanitizedString;
}

export function checkEnv() {
	return !process.env.NODE_ENV || process.env.NODE_ENV === "staging";
}

export function findUsList() {
	return [
		"Capterra",
		"Google",
		"Google Ads",
		"Instagram",
		"LinkedIn",
		"Twitter",
		"Software Advice",
		"GetApp",
		"Facebook",
		"WhatsApp",
		"Referral from a friend",
		"Reddit",
		"Others",
	];
}
export function profession() {
	return [
		"Teacher",
		"Content Head",
		"Principal",
		"School Head Teacher",
		"Student",
		"Parent",
		"EdTech Employee",
		"Academic Advisor",
		"Curriculum Developer",
		"C-level Executive",
		"Others",
	];
}

export function validateYouTubeUrl(url) {
	var regex =
		/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
	return regex.test(url);
}

const MSG = {
	commonAdd: "Record added successfully",
	commonApiError: "Something went wrong. Please try again.",
};

export default MSG;

export function getNextOfferDate() {
	let date = new Date();

	if (date.getDate() < 15) {
		date.setMonth(date.getMonth());
	} else {
		date.setMonth(date.getMonth() + 1);

		if (date.getMonth() === 11) {
			date.setMonth(0);
		}
	}

	date.setDate(15);

	const day = getOrdinalSuffix(15);

	const month = date.toLocaleDateString("en-US", {month: "long"});
	console.log("mon ", month);

	return `${day} ${month}`;
}

// Helper function to get ordinal suffix
function getOrdinalSuffix(day) {
	if (day > 3 && day < 21) return day + "th"; // Handle special case
	switch (day % 10) {
		case 1:
			return day + "st";
		case 2:
			return day + "nd";
		case 3:
			return day + "rd";
		default:
			return day + "th";
	}
}
