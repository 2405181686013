import React, {Component} from "react";
import {Link} from "react-router-dom";
import _ from "lodash";
import {connect} from "react-redux";
import styles from "../../styles/demoResponsive.module.css";
import classNames from "classnames";

class ToggleForGQ extends Component {
	constructor(props) {
		super(props);

		this.state = {
			buttonType: props.tab,
		};

		this.handleButtonClick = this.handleButtonClick.bind(this);
	}

	handleButtonClick(buttonType) {
		this.setState({buttonType});
		console.log(buttonType);
	}

	render() {
		const {buttonType} = this.state;
		const isHotsDisabled =
			!_.isEmpty(this.props.user) && !this.props.user.plan_details.hots_mode;
		const isSpecialistDisabled =
			!_.isEmpty(this.props.user) &&
			!this.props.user.plan_details.specialist;
		const isStandardTestDisabled = true;
		const is_new_pricing =
			!_.isEmpty(this.props.user) &&
			this.props.user.plan_details.is_new_pricing;
		const active_plan_name =
			!_.isEmpty(this.props.user) && this.props.user.plan_details.plan_name;

		return (
			<div className={classNames(styles["toggle-gq-demo"])}>
				<div className='easy-med-hard shadow-none'>
					<Link
						className={classNames(
							styles[`toggle-btn-main`],
							`py-2 ${
								buttonType !== "Special" && buttonType !== "Competitive"
									? "active-diff-btn"
									: "inactive-diff-btn"
							}`
						)}
						onClick={() => this.handleButtonClick("Standard")}
						to='/generate-questions'
					>
						Content
					</Link>

					{isSpecialistDisabled ? (
						<span
							className={classNames(styles[`toggle-btn-main`], `py-2`)}
							disabled={isSpecialistDisabled}
						>
							Subject
							{/* {is_new_pricing === 1 && (
                <img
                  src="img/crown.jpg"
                  className={styles["specialist-crown"]}
                  alt="icon"
                  width="30"
                  height="30"
                  style={{ position: "absolute", top: "-2px", right: "35px" }}
                />
              )} */}
							{/* <span className="badge badge-info beta-tag-toggle-gq">BETA</span> */}
						</span>
					) : (
						<>
							<Link
								className={classNames(
									styles[`toggle-btn-main`],
									`py-2 ${
										buttonType === "Special"
											? "active-diff-btn"
											: "inactive-diff-btn"
									}`
								)}
								onClick={() => this.handleButtonClick("Special")}
								to='/subject-expertise-questions'
							>
								Subject
								{/* {is_new_pricing === 1 && (
                  <img
                    className={styles["specialist-crown"]}
                    src="img/crown.jpg"
                    alt="icon"
                    width="30"
                    height="30"
                    style={{ position: "absolute", top: "-4px", right: "38px" }}
                  />
                )} */}
								{buttonType === "Special" && !is_new_pricing && (
									<div
										className={classNames(
											styles["notify-tp-special"],
											// `border-radius-right-diff`,
											"notify-tool-tip"
										)}
										style={{
											position: "absolute",
											right: "16rem",
											top: "9px",
										}}
									>
										<i
											className='fas fa-info-circle'
											style={{
												fontSize: "16px",
												color: "#08164e",
												cursor: "pointer",
											}}
										></i>
										{active_plan_name == "Free" ? (
											<span
												className='hoverText'
												style={{
													color: "#b6b1b1",
													backgroundColor: "#11267b",
												}}
											>
												You've{" "}
												{this.props.user.credits <= 0
													? "0 free credits"
													: this.props.user.credits}{" "}
												free credits worth{" "}
												{this.props.user.credits > 0 &&
													`$${_.round(
														this.props.user.credits / 2,
														1
													)}`}{" "}
												available in your account.
												<br />
												<span style={{color: "white"}}>
													Upgrade to PrepAI Premium
												</span>{" "}
												to get 54 free credits worth $27
											</span>
										) : (
											<span
												className='hoverText'
												style={{
													color: "#b6b1b1",
													backgroundColor: "#11267b",
												}}
											>
												Credits Available:{" "}
												{this.props.user.credits <= 0
													? 0
													: this.props.user.credits}{" "}
											</span>
										)}
									</div>
								)}
								{/* <span className="badge badge-info beta-tag-toggle-gq">BETA</span> */}
							</Link>
						</>
					)}

					{isStandardTestDisabled ? (
						<span
							className={classNames(
								styles[`toggle-btn-main`],
								`py-2 inactive-diff-btn`
							)}
							disabled={isStandardTestDisabled}
							style={{
								position: "relative",
								cursor: isStandardTestDisabled
									? "not-allowed"
									: "pointer",
							}}
						>
							<span
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<span style={{marginRight: "4px"}}>Standard</span>
								{"  "}
								<span>Test</span>
							</span>
							<span
								className='badge badge-warning text-white'
								style={{marginLeft: "4px", 
								fontSize: "9px",
								position: "absolute",
								top: "-13.5px",
								right: "5px",

							}}
							>
								Coming Soon!
							</span>
						</span>
					) : (
						<Link
							className={classNames(
								styles[`toggle-btn-main`],
								`py-2 ${
									buttonType === "Competitive"
										? "active-diff-btn"
										: "inactive-diff-btn"
								}`
							)}
							onClick={() => this.handleButtonClick("Competitive")}
							to='/competition-level-questions'
						>
							Standard Test
						</Link>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	};
};

export default connect(mapStateToProps)(ToggleForGQ);
