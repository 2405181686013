import React, { Component } from "react";
import styles from "../../cssmodule/profileprogress.module.css";
import { Tooltip } from "@mui/material";

export default class ProfileProgress extends Component {
  state = {
    progress: {
      40: styles.p40,
      60: styles.p60,
      80: styles.p80,
      100: styles.p100
    }
  }
  render() {
    const progress = this.props.progress;
    return (
      <Tooltip title="Complete Profile">
        <div className={styles.wrapper}>
          <div
            className={`${styles.c100} ${this.state.progress[progress]} ${styles.green}`}
          >
            <span className="dinline-block mr-2">
              <svg width="14" height="18" viewBox="0 0 14 18" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.4118 5.07588C11.4118 7.52341 9.44941 9.48593 7.00016 9.48593C4.55174 9.48593 2.5885 7.52341 2.5885 5.07588C2.5885 2.62836 4.55174 0.666672 7.00016 0.666672C9.44941 0.666672 11.4118 2.62836 11.4118 5.07588ZM7.00016 17.3333C3.38547 17.3333 0.333496 16.7458 0.333496 14.4791C0.333496 12.2116 3.40465 11.6449 7.00016 11.6449C10.6157 11.6449 13.6668 12.2325 13.6668 14.4991C13.6668 16.7667 10.5957 17.3333 7.00016 17.3333Z"
                  fill="#526892"
                />
              </svg>
            </span>
            <div className={styles.slice}>
              <div className={styles.bar}></div>
              <div className={styles.fill}></div>
            </div>
          </div>
        </div>
      </Tooltip>
    );
  }
}
