import React, { Component } from "react";
import ReactSelect, { components } from "react-select";
import { connect } from "react-redux";
import {
  setQuesValueType,
  setQuesValueCount,
  setInitialQues,
  setGenerateDiagram,
} from "../../store/reducers/generalActions";
import _ from "lodash";

import styles from "../../styles/demoResponsive.module.css";
import classNames from "classnames";
import Toggle from "./Toggle";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

class CustomQuestions extends Component {
  state = {
    checked: false,
    ques_type: [],
    ques_count: [],
    finalques_type: [],
    ques_type_option: [
      { value: "1", label: "Easy MCQ" },
      { value: "2", label: "Medium MCQ" },
      { value: "3", label: "Hard MCQ" },
      { value: "4", label: "True/False" },
      { value: "5", label: "Descriptive" },
      { value: "6", label: "Fillups" },
    ],
    ques_type_option_blooms: [
      { value: "1", label: "Remember" },
      { value: "2", label: "Understand" },
      { value: "3", label: "Apply" },
      { value: "4", label: "Analyze" },
      { value: "5", label: "Evaluate" },
      { value: "6", label: "Create" },
    ],

    ques_count_option: [
      { value: 10, label: "Upto 10" },
      { value: 25, label: "Upto 25" },
      { value: 50, label: "Upto 50" },
      { value: 0, label: "All Possible" },
    ],

    diagram_options: [
      { value: 1, label: "Diagrams" },
      { value: 2, label: "Graphs" },
      { value: 3, label: "Tables" },
      { value: 4, label: "All Possible" },
    ],

    diagram: [],
    customStyles: {
      option: (provided, state) => ({
        ...provided,
      }),

      container: (base) => ({
        ...base,

        border: this.state.occupation_message ? "1px solid red" : "",
        borderRadius: 5,
      }),

      // control: () => ({
      //   // none of react-select's styles are passed to <Control />
      //   border:"1px solid red"
      // }),
      menu: (provided, state) => ({
        ...provided,
      }),
      placeholder: (base) => ({
        ...base,
        fontSize: 13,
        color: "#8898aa",
      }),
    },
    // disableEnterQuestions: "",
  };

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.updateQuesTypeOptions();
    }
  }

  updateQuesTypeOptions() {
    const { ques_type_option, ques_type_option_blooms } = this.state;

    // if (!_.isEmpty(this.props.user)) {
    //   if (!this.props.user.plan_details.select_question_type) {
    //     this.setState({ disableEnterQuestions: "sm-disabled" });
    //   }
    // }

    const updatedOptions = ques_type_option.map((option, index) => {
      const isDisabled =
        !_.isEmpty(this.props.user) &&
        !this.props.user.plan_details.select_question_type;

      // if (isDisabled) this.setState({ disableEnterQuestions: "sm-disabled" });
      return { ...option, isDisabled };
    });

    const updatedBloomOptions = ques_type_option_blooms.map(
      (hotOptions, index) => {
        const isDisabled =
          !_.isEmpty(this.props.user) &&
          !this.props.user.plan_details.select_question_type;

        return { ...hotOptions, isDisabled };
      }
    );
    this.setState({ ques_type_option: updatedOptions });
    this.setState({ ques_type_option_blooms: updatedBloomOptions });
  }

  componentDidMount = () => {
    this.updateQuesTypeOptions();
    this.props.dispatch(setGenerateDiagram(0));
  };

  setQuesType = (ques_type) => {
    // this.props.setQuesType(ques_type)
    this.setState({ ques_type: ques_type });
    let finalques_type = ques_type.map((el) => el.value);
    finalques_type = finalques_type.join(",");
    this.props.dispatch(setQuesValueType(finalques_type));
  };

  handleQuesCount = (ques_count) => {
    // e.preventDefault();
    // const regex = /^[1-9][0-9]*$/;
    // const value = e.target.value;
    // if (value.length < 1) {
    //   this.setState({ ques_count: "" });
    //   this.props.dispatch(setQuesValueCount(""));
    // } else if (value == 0) {
    //   // console.log("ran")
    //   // this.setState({ ques_count: null})
    //   //   const reg = /^[1-9]/;
    //   //   if (reg.test(value)) {
    //   //     this.setState({ ques_count: value });
    //   //   } else {
    //   //     this.setState({ ques_count: null})
    //   //   }
    // } else if (value.length > 3) {
    //   //do nothing
    // } else {
    //   if (regex.test(value)) {
    //     this.setState({ ques_count: value });
    //     this.props.dispatch(setQuesValueCount(value));
    //   }
    // }

    this.setState({ ques_count: ques_count });
    const value = ques_count ? ques_count.value : null;
    if (value) this.props.dispatch(setQuesValueCount(value));
  };

  handleDiagram = (value) => {
    this.setState({ diagram: value }, () => {
      const valueSelected = !_.isEmpty(this.state.diagram)
        ? this.state.diagram.value
        : 0;
      this.props.dispatch(setGenerateDiagram(valueSelected));
    });

    // this.setState({checked: !this.state.checked}, () => {
    //   console.log("called: ", this.state.checked)
    //   if (this.state.checked) {
    //     this.props.dispatch(setGenerateDiagram(4))
    //   } else {
    //     this.props.dispatch(setGenerateDiagram(0))
    //   }
    // })
  };

  render() {
    let is_new_pricing =
      !_.isEmpty(this.props.user) &&
      this.props.user.plan_details.is_new_pricing;
    return (
      <>
        <div
          style={{ gap: "2%", flexWrap: "wrap" }}
          className="d-flex justify-content-center mb-3"
        >
          <div className="dropdown-1">
            <label
              for="selectques_type"
              className={classNames(
                styles["question-generate-tab-label"],
                "form-label d-flex align-items-center"
              )}
              style={{
                fontWeight: 500,
                color: "#344054",
                gap: "1rem",
                fontSize: "0.8rem",
              }}
            >
              {" "}
              Question Types{" "}
              {/* {is_new_pricing === 1 && (
                <img
                  src="img/crown.jpg"
                  className={classNames(styles["qtypes-crown"])}
                  alt="icon"
                  width="30"
                  height="30"
                  style={{
                    position: "absolute",
                    marginLeft: "115px",
                    marginTop: "-15px",
                  }}
                />
              )} */}
              {/* <span style={{ height: "1.2rem" }} className="badge badge-info">
              BETA
            </span> */}
            </label>
            <div className=" text-left">
              <ReactSelect
                className={classNames(styles["question-generate-tab-label"])}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                maxMenuHeight={200}
                components={{
                  Option,
                }}
                isDisabled={
                  !_.isEmpty(this.props.user) &&
                  !this.props.user.plan_details.enter_number_of_question
                }
                isClearable
                options={
                  this.props.isblooms
                    ? this.state.ques_type_option_blooms
                    : this.state.ques_type_option
                }
                type="text"
                placeholder={
                  this.state.ques_type.length > 0
                    ? this.state.ques_type
                    : "All Possible"
                }
                id="selectques_type"
                // onInputChange={(e) => this.handleCompanyinput(e)}
                onChange={this.setQuesType}
                value={this.state.ques_type}
                styles={this.state.customStyles}
              />
            </div>
          </div>
          <div className="position-relative dropdown-2">
            <label
              for="selectques_type"
              className={classNames(
                styles["question-generate-tab-label"],
                "form-label d-flex align-items-center"
              )}
              style={{
                fontWeight: 500,
                color: "#344054",
                gap: "1rem",
                fontSize: "0.8rem",
              }}
            >
              {" "}
              Question Count{" "}
              {/* {is_new_pricing === 1 && (
                <img
                  src="img/crown.jpg"
                  className={classNames(styles["qcounts-crown"])}
                  alt="icon"
                  width="30"
                  height="30"
                  style={{
                    position: "absolute",
                    marginLeft: "126px",
                    marginTop: "-17px",
                  }}
                />
              )} */}
            </label>
            {/* <input
              className={classNames(
                styles["question-generate-tab-label"],
                `custom-login-field--inputs form-control px-2 ${
                  !_.isEmpty(this.props.user) &&
                  !this.props.user.plan_details.enter_number_of_question
                    ? "sm-disabled"
                    : ""
                }`
              )}
              type="text"
              name="ques_count"
              value={this.state.ques_count}
              onChange={this.handleQuesCount}
              id="ques_count"
              aria-describedby="textHelp"
              placeholder="e.g. 30"
              style={{ height: "2.4rem", background: "none" }}
              disabled={
                !_.isEmpty(this.props.user) &&
                !this.props.user.plan_details.enter_number_of_question
              }
            /> */}
            <ReactSelect
              className={classNames(styles["question-generate-tab-label"])}
              // closeMenuOnSelect={false}
              hideSelectedOptions={false}
              maxMenuHeight={200}
              // components={{
              //   Option,
              // }}
              isClearable
              options={this.state.ques_count_option}
              type="text"
              placeholder={
                this.state.ques_count?.length > 0
                  ? this.state.ques_count
                  : "All Possible"
              }
              id="selectques_count"
              // onInputChange={(e) => this.handleCompanyinput(e)}
              onChange={this.handleQuesCount}
              name="ques_count"
              value={this.state.ques_count}
              styles={this.state.customStyles}
              isDisabled={
                !_.isEmpty(this.props.user) &&
                !this.props.user.plan_details.enter_number_of_question
              }
            />{" "}
          </div>
          {/* With Diagrams toggle section */}
          <div className="position-relative dropdown-3">
            <label
              for="selectques_type"
              className={classNames(
                styles["question-generate-tab-label"],
                "form-label d-flex align-items-center"
              )}
              style={{
                fontWeight: 500,
                color: "#344054",
                gap: "8px",
                fontSize: "0.8rem",
              }}
            >
              {" "}
              Include Visuals{" "}
              {/* {is_new_pricing === 1 && (
                <img
                  src="img/crown.jpg"
                  className={classNames(styles["qcounts-crown"])}
                  alt="icon"
                  width="30"
                  height="30"
                  style={{
                    position: "absolute",
                    marginLeft: "126px",
                    marginTop: "-17px",
                  }}
                />
              )} */}
              {/* {this.state.diagram?.value > 0 ? (
                <span className="badge badge-danger badge-standard-hots">
                  High Processing Time
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "8px",
                    right: "1px",
                    position: "absolute",
                    background: "#49c0a0",
                  }}
                  className="badge text-white badge-warning"
                >
                  Beta
                </span>
              )} */}
              <span
                style={{ fontSize: "8px" }}
                className="badge badge-warning text-white"
              >
                COMING SOON
              </span>
            </label>
            <ReactSelect
              className={classNames(styles["question-generate-tab-label"])}
              // closeMenuOnSelect={false}
              hideSelectedOptions={false}
              maxMenuHeight={200}
              // components={{
              //   Option,
              // }}
              isClearable
              options={this.state.diagram_options}
              type="text"
              placeholder={
                this.state.diagram?.length > 0 ? this.state.diagram : "None."
              }
              id="selectdiagram"
              // onInputChange={(e) => this.handleCompanyinput(e)}
              onChange={this.handleDiagram}
              name="diagram"
              value={this.state.diagram}
              styles={this.state.customStyles}
              isDisabled={true}
              // isDisabled={
              //   !_.isEmpty(this.props.user) &&
              //   !this.props.user.plan_details.enter_number_of_question
              // }
            />
          </div>
        </div>
        {/* <div className="generate-diagrams d-flex mb-3">
          <label className="mordern-radiobox-label my-0">
            <input
              name="radio"
              type="radio"
              onClick={(e) => this.handleCheckBox(e.target.checked)}
              checked={this.state.checked}
            />
            <span className="design"></span>
          </label>
          <p className="generate-diagrams-text m-0">
            <span>Generate Diagrams</span>{" "}
            <span style={{ fontSize: "8px" }}>
              (Enabling diagram generation will correspondingly extend the
              duration of question generation by a factor of 2.5 times the
              standard time.)
            </span>
          </p>
        </div> */}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch, // ← Add this
});

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    customQuesType: state.generalActions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomQuestions);
