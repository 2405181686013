import React from "react";
import styles from "../../styles/demoResponsive.module.css";
import classNames from "classnames";

const CategoryBoxHots = ({ state, is_new_pricing, handleQueTypeClick }) => {
  return (
    <div className="custom-nav-pills-column">
      <div
        className="nav flex-column nav-pills"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <a
          className={classNames(styles["category-nav-link"], "nav-link active")}
          id="v-pills-easy-tab"
          data-toggle="pill"
          href="#v-pills-easy"
          role="tab"
          aria-controls="v-pills-easy"
          aria-selected="true"
          onClick={(e) => handleQueTypeClick(e, "Remember", 7)}
        >
          Remember
          <span>{state.ques_count.knowledgeCount}</span>
        </a>
        <a
          className={classNames(styles["category-nav-link"], "nav-link")}
          id="v-pills-medium-tab"
          data-toggle="pill"
          href="#v-pills-medium"
          role="tab"
          aria-controls="v-pills-medium"
          aria-selected="false"
          onClick={(e) => handleQueTypeClick(e, "Understand", 8)}
        >
          Understand
          <span>{state.ques_count.comprehensionCount}</span>
        </a>
        <a
          className={classNames(styles["category-nav-link"], "nav-link")}
          id="v-pills-hard-tab"
          data-toggle="pill"
          href="#v-pills-hard"
          role="tab"
          aria-controls="v-pills-hard"
          aria-selected="false"
          onClick={(e) => handleQueTypeClick(e, "Apply", 9)}
        >
          Apply
          <span>{state.ques_count.applicationCount}</span>
        </a>
        <a
          className={classNames(styles["category-nav-link"], "nav-link")}
          id="v-pills-truefalse-tab"
          data-toggle="pill"
          href="#v-pills-truefalse"
          role="tab"
          aria-controls="v-pills-truefalse"
          aria-selected="false"
          onClick={(e) => handleQueTypeClick(e, "Analyze", 10)}
        >
          Analyze
          <span>{state.ques_count.analysisCount}</span>
        </a>
        <a
          className={classNames(styles["category-nav-link"], "nav-link")}
          id="v-pills-Descriptive-tab"
          data-toggle="pill"
          href="#v-pills-Descriptive"
          role="tab"
          aria-controls="v-pills-Descriptive"
          aria-selected="false"
          onClick={(e) => handleQueTypeClick(e, "Evaluate", 11)}
        >
          Evaluate
          <span>{state.ques_count.evaluationCount}</span>
        </a>
        <a
          className={classNames(styles["category-nav-link"], "nav-link")}
          id="v-pills-Fillups-tab"
          data-toggle="pill"
          href="#v-pills-Fillups"
          role="tab"
          aria-controls="v-pills-Fillups"
          aria-selected="false"
          onClick={(e) => handleQueTypeClick(e, "Create", 12)}
        >
          Create
          <span>{state.ques_count.synthesisCount}</span>
        </a>
      </div>
    </div>
  );
};

export default CategoryBoxHots;
