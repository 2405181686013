import React, {Component} from "react";
import {connect} from "react-redux";
import _ from "lodash";
import MSG, {
	notifySuccess,
	notifyError,
	profession,
	findUsList,
} from "./../utils/Helper";
import {startLoader, stopLoader} from "./../store/reducers/generalActions";
import {updateProfile, fetchUser, deleteProfile} from "./../services";
import {authLogout, updateProfileDetails} from "./../store/reducers/auth";
import PhoneInput from "react-phone-input-2";
import SweetAlert from "react-bootstrap-sweetalert";

import styles from "../styles/demoResponsive.module.css";
import classNames from "classnames";

class EditProfile extends Component {
	constructor(props) {
		super(props);
		const user = this.props.user;
		let dialCode = user.dial_code;
		let contactNumber = user.contact_number;
		let countryCode = user.country_code;
		let formattedNumber = "";
		if (user.dial_code == null) {
			dialCode = "+1";
			contactNumber = "1";
			// contactNumber = dialCode.substring(1) + contactNumber;
			formattedNumber = dialCode;
			countryCode = "us";
		} else {
			contactNumber = dialCode.substring(1) + contactNumber;
			formattedNumber = dialCode + user.contact_number;
		}
		this.state = {
			firstName: user.first_name,
			lastName: user.last_name,
			name: user.name,
			email: user.email,
			contact_number: contactNumber,
			country_code: countryCode,
			dial_code: dialCode,
			formatted_phone_number: formattedNumber,
			company_name: user.company_name,
			profession: user.profession,
			profession_other: user.profession_other,
			source_from: user.source_from,
			source_from_other: user.source_from_other,
			showDeletePrompt: false,
			reason1: "",
		};
	}

	async componentDidMount() {
		if (this.props.user.name == undefined) {
			this.props.history.replace("/question-paper");
		}
		if (this.props.user.country_code) {
			const country_code = _.lowerCase(this.props.user.country_code);
			this.setState({country_code});
		}
	}

	handleChange = ({currentTarget: input}) => {
		this.setState({
			[input.name]: input.value,
		});
	};

	handleSubmit = e => {
		e.preventDefault();
		const data = this.state;
		this.doSubmit(data);
	};

	handleOnChange = (value, data, event, formattedValue) => {
		if (value !== undefined) {
			this.setState({
				contact_number: value,
				formatted_phone_number: formattedValue,
				country_code: data.countryCode,
				dial_code: "+" + data.dialCode,
			});
		}
	};

	validateName = () => {
		// Check if the name is empty
		const {name} = this.state;
		if (_.isEmpty(name)) {
			notifyError("Name can't be empty");
			return false; // Value is empty
		}

		// const regex = /^[a-zA-Z\s]+$/;
		// if (!regex.test(name)) {
		//   notifyError("Name can't have special characters or numbers");
		//   return false; // Value contains special characters or digits
		// }

		return true; // Value is valid
	};

	doSubmit = async reqObj => {
		if (!this.validateName()) {
			return;
		}
		const req = {...reqObj};
		if (req.contact_number.length > 5) {
			req.contact_number = req.contact_number.substring(
				req.dial_code.length - 1
			);
		}
		this.props.dispatch(startLoader());
		try {
			const {data: res} = await updateProfile(req);
			if (res.success == true) {
				notifySuccess(res.message);
				this.props.dispatch(fetchUser());
				console.log(req);
				this.props.dispatch(updateProfileDetails(req));
			} else {
				notifyError(res.message);
			}
			this.props.dispatch(stopLoader());
		} catch (err) {
			console.log(err);
			if (err.response && err.response.status === 422) {
				const {data} = err.response;
				notifyError(data.message);
			} else if (err.response && err.response.status === 401) {
				// console.log("status", err.response.status);
				this.props.dispatch(authLogout());
			} else {
				notifyError(MSG.commonApiError);
			}
			this.props.dispatch(stopLoader());
		}
	};

	deleteAccount = async () => {
		if (this.state.reason1 !== "") {
			const req = {email: this.state.email};
			try {
				const {data: res} = await deleteProfile(req);
				if (res.success === true) {
					notifySuccess("Your account deleted successfully.");
					this.props.dispatch(authLogout());
				}
			} catch (err) {
				console.log(err);
				notifyError("Something went wrong. Pls try again.");
				this.props.dispatch(stopLoader());
			}
		} else {
			notifyError("Enter the reason for deleting you account");
		}
	};

	render() {
		return (
			<div className='login-bg height-cover bg-e5 item-centered'>
				<div className='login--box maxw-800'>
					<div id='edit-profile-validation'>
						<div className='w-100'>
							<div className='row align-items-center no-gutters'>
								<div className='col-6'>
									<h4 className='login--title'>My Profile</h4>
								</div>
								<div className='col-6 text-right'>
									<span className='response-save-text'></span>
								</div>
							</div>
						</div>
						<p className='login--support--text'>
							Add your personal details.
						</p>
						<div className='devider--shape'></div>
						<div className='login--form form-row'>
							<div className='form-group col-sm-6 custom-login-field'>
								<label
									htmlFor='fullName'
									className='custom-login-field--label'
								>
									Full Name
								</label>
								<div className='position-relative'>
									<span className='svg--icon'>
										<svg
											width='14'
											height='18'
											viewBox='0 0 14 18'
											fill='none'
										>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M11.4118 5.07584C11.4118 7.52336 9.44941 9.48588 7.00016 9.48588C4.55174 9.48588 2.5885 7.52336 2.5885 5.07584C2.5885 2.62831 4.55174 0.666626 7.00016 0.666626C9.44941 0.666626 11.4118 2.62831 11.4118 5.07584ZM7.00016 17.3333C3.38547 17.3333 0.333496 16.7458 0.333496 14.4791C0.333496 12.2116 3.40465 11.6449 7.00016 11.6449C10.6157 11.6449 13.6668 12.2324 13.6668 14.4991C13.6668 16.7666 10.5957 17.3333 7.00016 17.3333Z'
												fill='#526892'
											/>
										</svg>
									</span>
									<input
										className='custom-login-field--inputs form-control'
										type='text'
										id='fullName'
										aria-describedby='fullName'
										name='name'
										placeholder='Full Name'
										onChange={this.handleChange}
										value={this.state.name}
									/>
								</div>
							</div>
							{/* <div className="form-group col-sm-6 pr-sm-3 custom-login-field">
                <label htmlFor="lastName" className="custom-login-field--label">
                  Last Name
                </label>
                <div className="position-relative">
                  <span className="svg--icon">
                    <svg width="14" height="18" viewBox="0 0 14 18" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.4118 5.07584C11.4118 7.52336 9.44941 9.48588 7.00016 9.48588C4.55174 9.48588 2.5885 7.52336 2.5885 5.07584C2.5885 2.62831 4.55174 0.666626 7.00016 0.666626C9.44941 0.666626 11.4118 2.62831 11.4118 5.07584ZM7.00016 17.3333C3.38547 17.3333 0.333496 16.7458 0.333496 14.4791C0.333496 12.2116 3.40465 11.6449 7.00016 11.6449C10.6157 11.6449 13.6668 12.2324 13.6668 14.4991C13.6668 16.7666 10.5957 17.3333 7.00016 17.3333Z"
                        fill="#526892"
                      />
                    </svg>
                  </span>
                  <input
                    className="custom-login-field--inputs form-control"
                    type="text"
                    id="lastName"
                    aria-describedby="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    onChange={this.handleChange}
                    value={this.state.lastName}
                  />
                </div>
              </div> */}
							<div className='form-group col-sm-6 pl-sm-3 custom-login-field'>
								<label
									htmlFor='email'
									className='custom-login-field--label'
								>
									Email
								</label>
								<div className='position-relative'>
									<span className='svg--icon'>
										<svg
											width='18'
											height='16'
											viewBox='0 0 18 16'
											fill='none'
										>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M13.1162 0.5C14.2337 0.5 15.3087 0.941667 16.0995 1.73417C16.8912 2.525 17.3337 3.59167 17.3337 4.70833V11.2917C17.3337 13.6167 15.442 15.5 13.1162 15.5H4.88366C2.55783 15.5 0.666992 13.6167 0.666992 11.2917V4.70833C0.666992 2.38333 2.54949 0.5 4.88366 0.5H13.1162ZM14.442 5.94999L14.5087 5.88332C14.7078 5.64165 14.7078 5.29165 14.4995 5.04999C14.3837 4.92582 14.2245 4.84999 14.0587 4.83332C13.8837 4.82415 13.717 4.88332 13.5912 4.99999L9.83367 7.99999C9.35034 8.40082 8.65784 8.40082 8.167 7.99999L4.417 4.99999C4.15784 4.80832 3.7995 4.83332 3.58367 5.05832C3.35867 5.28332 3.33367 5.64165 3.5245 5.89165L3.63367 5.99999L7.42534 8.95832C7.892 9.32499 8.45784 9.52499 9.05034 9.52499C9.64117 9.52499 10.217 9.32499 10.6828 8.95832L14.442 5.94999Z'
												fill='#526892'
											/>
										</svg>
									</span>
									<input
										className='custom-login-field--inputs form-control'
										type='email'
										id='email'
										aria-describedby='email'
										readOnly
										name='email'
										value={this.state.email}
										placeholder='Email'
										onChange={this.handleChange}
									/>
								</div>
							</div>
							<div className='form-group col-sm-6 pr-sm-3 custom-login-field'>
								<label
									htmlFor='phone'
									className='custom-login-field--label'
								>
									Contact Number
								</label>
								<div className='position-relative'>
									<PhoneInput
										country={this.state.country_code}
										// country={"us"}
										countryCodeEditable={false}
										placeholder='Enter phone number'
										value={this.state.formatted_phone_number}
										onChange={this.handleOnChange}
									/>
								</div>
							</div>
							<div className='form-group col-sm-6 pl-sm-3 custom-login-field'>
								<label
									htmlFor='company'
									className='custom-login-field--label'
								>
									Company Name
								</label>
								<div className='position-relative'>
									<span className='svg--icon'>
										<svg
											width='18'
											height='18'
											viewBox='0 0 18 18'
											fill='none'
										>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M7.92012 1.93245C7.36151 1.93245 6.88627 2.32064 6.75287 2.84384H11.2384C11.105 2.32064 10.6298 1.93245 10.0712 1.93245H7.92012ZM12.5058 2.84383H14.1566C15.9075 2.84383 17.3332 4.28686 17.3332 6.059C17.3332 6.059 17.2831 6.80921 17.2665 7.85393C17.2648 7.93663 17.2248 8.01764 17.1589 8.06659C16.7579 8.36279 16.391 8.60751 16.3577 8.62439C14.9737 9.55266 13.3654 10.2058 11.652 10.5307C11.5403 10.5527 11.4302 10.4944 11.3735 10.3948C10.8933 9.56278 9.99617 9.02101 8.99567 9.02101C8.00184 9.02101 7.09639 9.55688 6.60197 10.3898C6.54444 10.4877 6.43606 10.5442 6.32517 10.5231C4.62598 10.1974 3.01768 9.54506 1.64199 8.63283L0.841591 8.07587C0.774891 8.03368 0.733204 7.95773 0.733204 7.87334C0.708191 7.44296 0.666504 6.059 0.666504 6.059C0.666504 4.28686 2.09222 2.84383 3.84309 2.84383H5.48558C5.64399 1.62021 6.66951 0.666626 7.92013 0.666626H10.0712C11.3218 0.666626 12.3473 1.62021 12.5058 2.84383ZM17.0497 9.67933L17.0163 9.69621C15.3322 10.827 13.3061 11.5781 11.1801 11.8903C10.8799 11.9325 10.5798 11.7384 10.4964 11.4346C10.313 10.7426 9.72102 10.2869 9.01233 10.2869H9.004H8.98732C8.27863 10.2869 7.68667 10.7426 7.50325 11.4346C7.41987 11.7384 7.11972 11.9325 6.81957 11.8903C4.69351 11.5781 2.6675 10.827 0.983321 9.69621C0.974983 9.68777 0.891608 9.63714 0.824908 9.67933C0.749871 9.72153 0.749871 9.82279 0.749871 9.82279L0.808233 14.1266C0.808233 15.8987 2.22561 17.3333 3.97648 17.3333H14.0148C15.7657 17.3333 17.1831 15.8987 17.1831 14.1266L17.2498 9.82279C17.2498 9.82279 17.2498 9.72153 17.1747 9.67933C17.1331 9.65401 17.083 9.66245 17.0497 9.67933ZM9.62098 13.2152C9.62098 13.5696 9.34584 13.8481 8.99567 13.8481C8.65383 13.8481 8.37036 13.5696 8.37036 13.2152V12.1266C8.37036 11.7806 8.65383 11.4937 8.99567 11.4937C9.34584 11.4937 9.62098 11.7806 9.62098 12.1266V13.2152Z'
												fill='#526892'
											/>
										</svg>
									</span>
									<input
										className='custom-login-field--inputs form-control'
										id='company'
										name='company_name'
										type='text'
										value={this.state.company_name}
										onChange={this.handleChange}
										placeholder='Enter your company name'
									/>
								</div>
							</div>
							<div className='form-group col-sm-6 pr-sm-3 custom-login-field'>
								<label
									htmlFor='profession'
									className='custom-login-field--label'
								>
									What describes you best?
								</label>
								{/* <span
                    className="text-muted d-block"
                    style={{ fontSize: "11px" }}
                  >

                  </span> */}
								<div className='position-relative'>
									<span className='svg--icon'>
										<svg
											width='14'
											height='18'
											viewBox='0 0 14 18'
											fill='none'
										>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M11.4118 5.07584C11.4118 7.52336 9.44941 9.48588 7.00016 9.48588C4.55174 9.48588 2.5885 7.52336 2.5885 5.07584C2.5885 2.62831 4.55174 0.666626 7.00016 0.666626C9.44941 0.666626 11.4118 2.62831 11.4118 5.07584ZM7.00016 17.3333C3.38547 17.3333 0.333496 16.7458 0.333496 14.4791C0.333496 12.2116 3.40465 11.6449 7.00016 11.6449C10.6157 11.6449 13.6668 12.2324 13.6668 14.4991C13.6668 16.7666 10.5957 17.3333 7.00016 17.3333Z'
												fill='#526892'
											/>
										</svg>
									</span>

									<select
										className='custom-login-field--inputs form-control'
										id='profession'
										name='profession'
										onChange={this.handleChange}
										value={this.state.profession}
										aria-describedby='profession'
									>
										<option value=''>Select option</option>
										{profession().map(op => {
											return (
												<option key={op} value={op}>
													{op}
												</option>
											);
										})}
									</select>
								</div>
							</div>

							<div className='form-group col-sm-6 pl-sm-3 custom-login-field'>
								<label
									htmlFor='source_from'
									className='custom-login-field--label'
								>
									How did you discover PrepAI?
								</label>
								<div className='position-relative'>
									<span className='svg--icon'>
										<svg
											width='17'
											height='22'
											viewBox='0 0 17 22'
											fill='none'
										>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												fill='#526892'
												d='M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM7.194 6.766a1.688 1.688 0 0 0-.227-.272 1.467 1.467 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 5.734 6C4.776 6 4 6.746 4 7.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 0 0-.227-.4zM11 9.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 0 0-.228-.4 1.686 1.686 0 0 0-.227-.273 1.466 1.466 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 10.07 6c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z'
											/>
										</svg>
									</span>
									<select
										name='source_from'
										aria-describedby='source_from'
										id='source_from'
										className='custom-login-field--inputs form-control'
										onChange={this.handleChange}
										value={this.state.source_from}
										disabled={this.state.referralActive}
									>
										<option value=''>Select option</option>

										{findUsList().map(op => {
											return (
												<option key={op} value={op}>
													{op}
												</option>
											);
										})}
									</select>
								</div>
							</div>
							{this.state.profession == "Others" ? (
								<div className='form-group col-sm-6 pr-sm-3 custom-login-field'>
									<label
										htmlFor='profession_other'
										className='custom-login-field--label'
									>
										Please specify Profession
									</label>
									<div className='position-relative '>
										<span className='svg--icon'>
											<svg
												width='14'
												height='18'
												viewBox='0 0 14 18'
												fill='none'
											>
												<path
													fillRule='evenodd'
													clipRule='evenodd'
													d='M11.4118 5.07584C11.4118 7.52336 9.44941 9.48588 7.00016 9.48588C4.55174 9.48588 2.5885 7.52336 2.5885 5.07584C2.5885 2.62831 4.55174 0.666626 7.00016 0.666626C9.44941 0.666626 11.4118 2.62831 11.4118 5.07584ZM7.00016 17.3333C3.38547 17.3333 0.333496 16.7458 0.333496 14.4791C0.333496 12.2116 3.40465 11.6449 7.00016 11.6449C10.6157 11.6449 13.6668 12.2324 13.6668 14.4991C13.6668 16.7666 10.5957 17.3333 7.00016 17.3333Z'
													fill='#526892'
												/>
											</svg>
										</span>
										<input
											className='custom-login-field--inputs form-control'
											type='text'
											id='profession_other'
											name='profession_other'
											onChange={this.handleChange}
											value={this.state.profession_other}
											aria-describedby='profession_other'
											placeholder='Enter your option'
										/>
									</div>
								</div>
							) : (
								<div className='form-group col-sm-6 pr-sm-3 custom-login-field'></div>
							)}
							{this.state.source_from === "Others" && (
								// this.props.user.update_profile_index == 2 && (
								<>
									{/* <hr className="mb-2" style={{width: "15%"}}/> */}
									<div className='form-group col-sm-6 pl-sm-3 custom-login-field'>
										<label
											htmlFor='source_from_other'
											className='custom-login-field--label'
										>
											Please Specify Source
										</label>
										<div className='position-relative '>
											<span className='svg--icon'>
												<svg
													width='17'
													height='22'
													viewBox='0 0 17 22'
													fill='none'
												>
													<path
														fillRule='evenodd'
														clipRule='evenodd'
														fill='#526892'
														d='M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM7.194 6.766a1.688 1.688 0 0 0-.227-.272 1.467 1.467 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 5.734 6C4.776 6 4 6.746 4 7.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 0 0-.227-.4zM11 9.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 0 0-.228-.4 1.686 1.686 0 0 0-.227-.273 1.466 1.466 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 10.07 6c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z'
													/>
												</svg>
											</span>
											<input
												className='custom-login-field--inputs form-control'
												type='text'
												name='source_from_other'
												id='source_from_other'
												value={this.state.source_from_other}
												onChange={this.handleChange}
												aria-describedby='source_from_other'
												placeholder='Enter your option'
											/>
										</div>
									</div>
								</>
							)}
							<div className='col-12 profile-action-btn-grp'>
								<button
									onClick={this.handleSubmit}
									className='btn--submit w-100 m-9'
								>
									Save Profile
								</button>
								{/* <button
									onClick={() => {
										this.setState({
											showDeletePrompt: true,
										});
									}}
									className='btn--delete w-100'
								>
									Delete Profile
								</button> */}
							</div>
						</div>
					</div>
				</div>
				{/* <SweetAlert
					customClass='sweet-alert'
					show={this.state.showDeletePrompt}
					title={
						<div className=''>
							<h4>Why do you want to delete you account ?</h4>
							<hr />
						</div>
					}
					showCancel
					confirmBtnText='Delete'
					confirmBtnStyle={{fontSize: "16px"}}
					confirmBtnBsStyle='error'
					onConfirm={this.deleteAccount}
					onEscapeKey={() => {}}
					onOutsideClick={() => {}}
					onCancel={() => {
						this.setState({
							showDeletePrompt: false,
						});
					}}
				>
					<div className='position-relative'>
						<textarea
							className={classNames(
								// styles["question-generate-tab-input"],
								"ecustom-login-field--inputs form-control px-2 resize--non"
							)}
							id='reason1'
							name='reason1'
							value={this.state.reason1}
							onChange={e => {
								this.setState({
									reason1: e.target.value,
								});
							}}
							rows='7'
							placeholder='Type your reason for deleting your account'
						></textarea>
					</div>
					<br />
				</SweetAlert> */}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	};
};

export default connect(mapStateToProps)(EditProfile);
