import { Component } from 'react'
import SweetAlert from "react-bootstrap-sweetalert";
import { Checkbox, FormGroup, FormControlLabel, TextField } from '@mui/material'
import { FilterAlt } from '@mui/icons-material';

export default class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFilter: false,
            numError: false,
            quesNum: 0,
            filterCount: 0,
            checked: Array.from({ length: 6 }).fill(false),
            filterApplied: false,
            req_QuestionCount:{
                easy: 1,
                medium: 1,
                hard: 1,
                true_false: 1,
                fillups: 1,
                descriptive: 1
            }
        }
    }


    openFilterModal = (e) => {
        this.setState({ showFilter: true });
    }


    handleCount = (e, index) => {
        if (e.target.checked) {
            this.setState(state => ({ filterCount: state.filterCount + 1, checked: [...state.checked.slice(0, index), !state.checked[index], ...state.checked.slice(index + 1, 6)] }));
        } else {
            this.setState(state => ({ filterCount: state.filterCount - 1, checked: [...state.checked.slice(0, index), !state.checked[index], ...state.checked.slice(index + 1, 6)] }));
        }
    }


    handleQuestionCount = (que_category, value) => {

        if(value <= 0) return;

        const obj = this.state.req_QuestionCount;
        obj[que_category] = value;
        this.setState({req_QuestionCount: obj});
    }


    resetQuestionCount = () => {
        const obj = this.state.req_QuestionCount;
        
        for(let key in obj){
            obj[key] = 1;
        }

        this.setState({req_QuestionCount: obj});
    }


    filterClose = () => {
        this.setState({ 
            filterApplied: false, 
            showFilter: false, 
            filterCount: 0, 
            checked: Array.from({ length: 6 }).fill(false) 
        });
        this.resetQuestionCount();
    }

    filterConfirm = () => { 
        this.setState({ showFilter: false, filterApplied: true }) 
    }

    checkRow = (idx, category, que_category) => {
        return (
            <div className='check__row'>
                <Checkbox 
                    size='small' 
                    checked={this.state.checked[idx]} 
                    onChange={(e) => this.handleCount(e, idx)}
                />
                <span className='check__row--text'> {category} </span>
                
                <TextField 
                    label="" 
                    type="number" 
                    value={this.state.req_QuestionCount[que_category]} 
                    size='small' 
                    variant='outlined' 
                    disabled={!this.state.checked[idx]}
                    // onChange={() => { this.setState(state => ({filterCount: state.filterCount, checked: [...state.checked.slice(0, idx), true, ...state.checked.slice(idx + 1, 6)]}) )}}
                    onChange={(e) => {this.handleQuestionCount(que_category, e.target.value)}}
                />
            </div>
        )
    }


    render() {
        return (

            <>
                <div className='filter'>
                    <button onClick={this.openFilterModal} className=' filter__btn'> <FilterAlt /> </button>
                    {this.state.filterCount > 0 && <div className='filter_count'> {this.state.filterCount} </div>}
                </div>

                <SweetAlert
                    show={this.state.showFilter}
                    showCancel
                    showCloseButton
                    showConfirm
                    confirmBtnText={`${this.state.filterApplied ? "Update" : "Apply"}`}
                    cancelBtnText={`${this.state.filterApplied ? "Remove Filters" : "Cancel"}`}
                    onCancel={this.filterClose}
                    onConfirm={this.filterConfirm}
                    title={
                        <div className="swal-title" >
                            <h3 className='filter__heading'>Premium Filters</h3>
                            <hr />

                            <h5 className='filter__subheading'> Type of Questions </h5>
                            <div className="filterOptions">

                                <FormGroup className='filter__box'>
                                    <FormControlLabel
                                        className='filter__check'
                                        label=""
                                        control={this.checkRow(0, "Easy MCQ", 'easy')}
                                    />


                                    <FormControlLabel
                                        className='filter__check'
                                        control={this.checkRow(3, "Descriptive", 'descriptive')}
                                        label=""
                                    />

                                    <FormControlLabel
                                        className='filter__check'
                                        control={this.checkRow(1, "Medium MCQ", 'medium')}
                                        label=""

                                    />
                                    
                                </FormGroup>


                                <FormGroup className='filter__box'>

                                    <FormControlLabel
                                        className='filter__check'
                                        control={this.checkRow(4, "True/False", 'true_false')}
                                        label=""
                                    />


                                    <FormControlLabel
                                        className='filter__check'
                                        control={this.checkRow(2, "Hard MCQ", 'hard')}
                                        label=""
                                    />
                                    <FormControlLabel
                                        className='filter__check'
                                        control={this.checkRow(5, "Fillups", 'fillups')}
                                        label=""
                                    />

                                </FormGroup>
                            </div>

                        </div>
                    }
                />

            </>
        )
    }
}
