import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import produce from "immer";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { trackEvent } from "../utils/mixpanel";
import PhoneInput from "react-phone-input-2";
import {
  fetchUser
} from "./../services";
import MSG, {
  notifySuccess,
  notifyError,
  profession,
  findUsList,
} from "../utils/Helper";
import {
  closeReferral,
  setInitialQues,
  showReferral,
  startLoader,
  stopLoader,
  setSelectedPlan,
} from "../store/reducers/generalActions";
import { authLogout } from "../store/reducers/auth";
import Tooltip from "./common/tooltip";

import "react-dropzone-uploader/dist/styles.css";

import { isMobile } from "react-device-detect";
import ReferPopup from "./common/ReferPopup";
import { Dialog, DialogTitle, TextField, Typography } from "@mui/material";
import ReactSelect, { components } from "react-select";
import {
  APTITUDE_TOPIC,
  CHEMISTRY_FORMULA,
  CHEMISTRY_TOPIC,
  MATHS_FORMULA,
  MATHS_TOPIC,
  PHYSICS_FORMULA,
  PHYSICS_TOPIC,
} from "./common/SpecialistData";
import ToggleForGQ from "./common/ToggleForGQDemo";
import SubjectExpertise from "./questionTypes/SubjectExpertise";
import {
  Elements,
  ElementsConsumer,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./common/AddOnsCheckoutForm";
import QuestionPaperTopUp from "./common/QuestionPaperTopUp";
import textData from "./common/textData";
import Salebar from "./sales";
import DemoHeader from "./common/DemoHeader";
import DemoGenerateQuestionsSpecialist from "./common/DemoGenerateQuestionsSpecialist";

import styles from "../styles/demoResponsive.module.css";
import classNames from "classnames";

const InjectedCheckoutForm = (props, data) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm
        stripe={stripe}
        elements={elements}
        {...props}
        data={data}
      />
    )}
  </ElementsConsumer>
);

class SubjectExpertiseQuestions extends Component {
  state = {
    subject: [
      { value: "Mathematics", label: "Mathematics" },
      { value: "Physics", label: "Physics" },
      { value: "Chemistry", label: "Chemistry" },
      { value: "Aptitude", label: "Aptitude" },
    ],
    typeOfQuestion: [
      { value: "Formula Based", label: "Formula Based" },
      { value: "Topic Based", label: "Topic Based" },
    ],
    topic: [{ value: "", label: "" }],
    buttonType: "Easy",
    key_pcma: 1,
    level_pcma: "easy level",
    questionPaperGenerated: false,
    topic_formula: MATHS_TOPIC,
    topic_formula_name: MATHS_TOPIC[0],
    subject_name: { value: "Mathematics", label: "Mathematics" },
    typeOfQuestion_name: { value: "Topic Based", label: "Topic Based" },
    disableTopicFormula: false,
    titleofQuestion: this.props.defaultContent?.title,
    titleofQuestion_emsg: "",
    contentForQuestions: this.props.defaultContent?.content,
    wikiSearchInput: "",
    refreshing: false,
    typing: false,
    typingTimeout: 0,
    selectedWikiTopic: null,
    tmpselectedWikiTopic: "",
    showAlert: false,
    wikiContent: "",
    searchTopic: "",
    iseditpaper: true,
    currentActiveTab: 2,
    questions: [],
    ques_count: {
      easyCount: 0,
      mediumCount: 0,
      hardCount: 0,
      descriptiveCount: 0,
      trueFalseCount: 0,
      fillUpsCount: 0,
    },
    addquestionmodal: false,
    questiontypetab: "knowledge",
    defaultActiveTab: "custom-tab",
    addeasymcq: false,
    addmediummcq: false,
    addhardmcq: false,
    addtruefalse: false,
    adddescriptive: false,
    addfillups: false,
    isUploaded: false,
    videoFile: null,
    pdfDocFile: null,
    feedbackAlert: false,
    showRatingAlert: false,
    callRatingAPI: true,
    questionPaperId: null,
    questionPaperRating: null,
    timeLeft: {},
    highlightBadEmoji: null,
    highlightNeutralEmoji: null,
    highlightGoodEmoji: null,
    showPremiumPrompt: false,
    premiumPromptText: "",
    isShowPremiumPrompt: false,
    totalPages: 0,
    pageNo: 1,
    requiredQuestionCount: 11110,
    lessQuestionsGenerated: false,
    moreQuestionsGenerated: false,
    showPopup: false,
    firstEventScroll: false,
    activity_count: 0,
    isQuestionGenerated: false,
    source: "Direct",
    scrollAfterGenerate: false,
    leavePageEvent: false,
    showUpdateProfilePopUp: false,
    source_from: "",
    source_from_other: "",
    profession: "",
    profession_other: "",
    data: {
      contact_number: "",
      country_code: "in",
      dial_code: "",
      formatted_phone_number: "",
    },
    showLoader: false,
    showFeedbackForm: true,
    ratingComment: "",
    showWordLimit: false,
    isCustomPages: false,
    ques_count: null,
    totalQuestionsFormed: 0,
    stripePromise: null,
    stripeClientSecret: "",
    showStripeForm: false,
    stripeKey: process.env.REACT_APP_STRIPE_KEY,
    showAddOn: false,
    addOnText: "",
    addOnCTAText: "",
    freeAddOnSubText: "",
    addOnPlans: [],
    activatePlan: [],
    showTopUp: false,
    showDemoPopUp: false,
  };

  calculateTimeLeft = () => {
    // Set the date we're counting down to
    const countDownDate = new Date("Oct 31, 2022 23:59:59").getTime();

    // get today's date
    const today = new Date().getTime();
    // get the difference
    let diff = countDownDate - today;

    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: undefined };

    if (diff > 0) {
      timeLeft = {
        // math
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((diff % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    //this.handleGenerateQuestion();
  };

  isFutureDate = (idate) => {
    var today = new Date().getTime(),
      idate = idate.split("/");

    idate = new Date(idate[2], idate[1] - 1, idate[0]).getTime();
    return today - idate < 0 ? true : false;
  };

  componentDidMount() {
		if (this.props.isAuthenticated) {
		  this.props.dispatch(fetchUser());
		  const refData = localStorage.getItem("refData");
		  if (refData) {
			this.props.history.replace("/plans");
		  } else {
			this.props.history.replace("/generate-questions");
		  }
		}
  }

  componentDidUpdate() {
    window.onclick = () => {
      if (!this.state.firstEventScroll && this.state.activity_count == 1) {
        //trackEvent(("First Activity - Click");
      }
    };

    window.onscroll = (e) => {
      // console.log("Scroll");
      if (
        !this.state.firstEventScroll &&
        this.state.activity_count == 0 &&
        this.state.source == "Direct"
      ) {
        //trackEvent(("First Activity - Scroll");
        this.setState({
          firstEventScroll: true,
        });
      }

      if (this.state.scrollAfterGenerate) {
        //trackEvent(("Scroll After Question Generation");
        this.setState({
          scrollAfterGenerate: false,
        });
      }
      // Detect user scroll to the bottom of the page.
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        const element = document.getElementById("generated_questions");
        if (element && this.state.source == "Direct") {
          //trackEvent(("Scroll Down To Bottom - Generate Question");
        }
      }
    };

    if (this.props.isAuthenticated) {
      const refData = localStorage.getItem("refData");
      if (refData) {
        this.props.history.replace("/plans");
      }
    }
  }

  componentWillUnmount() {
    window.onscroll = () => {};
    window.onclick = () => {};
  }

  tabChanged = (e) => {
    this.props.dispatch(setInitialQues());
    this.setState({ currentActiveTab: e });
  };

  redirectToPlansPage = () => {
    this.props.history.push("/plans-bloom");
  };

  handleCallback = (childData) => {
    let ques_count = {
      easyCount: 0,
      mediumCount: 0,
      hardCount: 0,
      descriptiveCount: 0,
      trueFalseCount: 0,
      fillUpsCount: 0,
    };
    ques_count.easyCount = childData.total_easy_mcq;
    ques_count.mediumCount = childData.total_medium_mcq;
    ques_count.hardCount = childData.total_hard_mcq;
    ques_count.trueFalseCount = childData.total_true_false;
    ques_count.descriptiveCount = childData.total_descriptive;
    ques_count.fillUpsCount = childData.total_fill_ups;

    var que_obj = childData.questions;
    this.setState({
      questions: [...que_obj],
      ques_count: { ...ques_count },
      pageNo: childData.page_no,
      totalPages: childData.page_no,
    });
    // console.log(this.state)

    // if (this.state.questions.length < this.state.requiredQuestionCount) {
    //   this.setState({ lessQuestionsGenerated: true });
    // }
  };

  onInputChange = (e) => {
    this.setState({ tmpselectedWikiTopic: e.target.value });
  };

  resetQuestionState = () => {
    this.setState({
      questions: [],
      questionPaperGenerated: false,
      ques_count: {
        easyCount: 0,
        mediumCount: 0,
        hardCount: 0,
        descriptiveCount: 0,
        trueFalseCount: 0,
        fillUpsCount: 0,
      },
    });
  };

  scrollToQuestions = () => {
    const position = document.getElementById("generated_questions").offsetTop;
    window.scrollTo(0, position);
  };

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  handleAddQuestionOnClick = () => {
    this.addquestion();
    this.scrollToBottom();
  };

  countWords = (str) => {
    const arr = str.split(" ");
    return arr.filter((word) => word !== "").length;
  };

  validateInput = () => {
    const { subject_name, key_pcma, topic_formula_name } = this.state;
    let ret = true;
    if (!subject_name) {
      this.setState({
        errorEvent: "subject_name",
        errorMsg: "Please select a Subject Name",
      });
      return false;
    } else if (_.isEmpty(subject_name.label)) {
      this.setState({
        errorEvent: "subject_name",
        errorMsg: "Please select a Subject Name",
      });
      ret = false;
    } else if (!key_pcma) {
      this.setState({
        errorEvent: "topic_or_formula",
        errorMsg: "Please select an Option Topic/Formula Based",
      });
      ret = false;
    } else if (!topic_formula_name) {
      this.setState({
        errorEvent: "topic_formula",
        errorMsg:
          key_pcma === 2 ? "Please select a Formula" : "Please select a Topic",
      });
      return false;
    } else if (_.isEmpty(topic_formula_name.label)) {
      this.setState({
        errorEvent: "topic_formula",
        errorMsg:
          key_pcma === 2 ? "Please select a Formula" : "Please select a Topic",
      });
      ret = false;
    }
    return ret;
  };

  handleGenerateQuestion = async (e) => {
    this.props.dispatch(startLoader());
    setTimeout(() => {
      this.setState({ showDemoPopUp: true });
      this.props.dispatch(stopLoader());
    }, 1000);
  };

  //GET Timestamp for the video files
  startTimeStampHandler = (secs) => {
    this.setState({ startTime: secs });
  };

  endTimeStampHandler = (secs) => {
    this.setState({ endTime: secs });
  };

  isCustomDurationHandler = () => {
    this.setState({ isCustomDuration: true });
  };

  startTypingTime = () => {
    if (this.state.timeInputMsg) {
      this.setState({ timeInputMsg: null });
    }
  };

  handleClearCustomDuration = () => {
    this.setState({
      isCustomDuration: false,
      timeInputMsg: null,
      startTime: null,
      endTime: null,
    });
  };

  validateTimeInput = () => {
    const { startTime, endTime } = this.state;
    if (!startTime || !endTime) {
      return true;
    } else if (startTime === endTime) {
      this.setState({ timeInputMsg: "Start & End time can't be same" });
      return false;
    } else if (startTime > endTime) {
      this.setState({ timeInputMsg: "End time can't be less than Start time" });
      return false;
    }
    return true;
  };

  scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  handleChange = ({ currentTarget: input }) => {
    if (this.state.showWordLimit) this.setState({ showWordLimit: false });
    this.setState({
      [input.name]: input.value,
    });
  };

  handleTitleChange = ({ currentTarget: input }) => {
    if (_.trim(input.value) != "") {
      this.setState({
        titleofQuestion_emsg: "",
      });
    }
    this.setState({
      [input.name]: input.value,
    });
  };

  handleQuestionUpdate = (question) => {
    try {
      this.setState(
        produce(this.state, (draftState) => {
          const index = draftState.questions.findIndex(
            (m) => m.question_id === question.question_id
          );
          draftState.questions[index] = question;
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  getSortedOptions = () => {
    let sortedArray = [];
    try {
      if (this.state.topic_formula) {
        let newValues = this.state.topic_formula;
        sortedArray = newValues
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
      }
    } catch (err) {
      console.log(err);
    }
    return sortedArray;
  };

  handlePopUpClose = () => {
    this.setState({
      showDemoPopUp: false,
    });
  };

  radioButtonValue = (event) => {
    const { name } = event.target;
    if (name === "topic_") {
      this.setState(
        {
          typeOfQuestion_name: { value: "Topic Based", label: "Topic Based" },
        },
        () => this.setTypeOfQuestion(this.state.typeOfQuestion_name)
      );
    } else {
      this.setState(
        {
          typeOfQuestion_name: {
            value: "Formula Based",
            label: "Formula Based",
          },
        },
        () => this.setTypeOfQuestion(this.state.typeOfQuestion_name)
      );
    }
  };

  setTypeOfQuestion = (type) => {
    const { subject_name } = this.state;

    if (!type) {
      return;
    }

    this.setState({ topic_formula_name: "" });

    const subject = subject_name.value;
    let topic_formula = [];
    let topic_formula_name = {};
    let key_pcma = 0;

    switch (type.value) {
      case "Topic Based":
        switch (subject) {
          case "Mathematics":
            topic_formula = MATHS_TOPIC;
            topic_formula_name = MATHS_TOPIC[0];
            key_pcma = 1;
            break;
          case "Physics":
            topic_formula = PHYSICS_TOPIC;
            topic_formula_name = PHYSICS_TOPIC[0];
            key_pcma = 1;
            break;
          case "Chemistry":
            topic_formula = CHEMISTRY_TOPIC;
            topic_formula_name = CHEMISTRY_TOPIC[0];
            key_pcma = 1;
            break;
          case "Aptitude":
            topic_formula = APTITUDE_TOPIC;
            topic_formula_name = APTITUDE_TOPIC[0];
            key_pcma = 3;
            break;
          default:
            break;
        }
        break;
      case "Formula Based":
        switch (subject) {
          case "Aptitude":
            key_pcma = 0;
            break;
          case "Mathematics":
            topic_formula = MATHS_FORMULA;
            topic_formula_name = MATHS_FORMULA[0];
            key_pcma = 2;
            break;
          case "Physics":
            topic_formula = PHYSICS_FORMULA;
            topic_formula_name = PHYSICS_FORMULA[0];
            key_pcma = 2;
            break;
          case "Chemistry":
            topic_formula = CHEMISTRY_FORMULA;
            topic_formula_name = CHEMISTRY_FORMULA[0];
            key_pcma = 2;
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    this.setState({
      typeOfQuestion_name: type,
      topic_formula,
      topic_formula_name,
      key_pcma,
      errorEvent: "",
    });
  };

  setSubject = (subject) => {
    // this.props.setQuesType(ques_type)
    this.setState({
      subject_name: subject,
      topic_formula: [],
      typeOfQuestion_name: "",
      topic_formula_name: "",
      errorEvent: "",
      key_pcma: "",
    });

    let topic_formula = [];
    let topic_formula_name = {};
    let key_pcma = 0;
    let disableTopicFormula = false;

    switch (subject.value) {
      case "Mathematics":
        topic_formula = MATHS_TOPIC;
        topic_formula_name = MATHS_TOPIC[0];
        key_pcma = 1;
        break;
      case "Physics":
        topic_formula = PHYSICS_TOPIC;
        topic_formula_name = PHYSICS_TOPIC[0];
        key_pcma = 1;
        break;
      case "Chemistry":
        topic_formula = CHEMISTRY_TOPIC;
        topic_formula_name = CHEMISTRY_TOPIC[0];
        key_pcma = 1;
        break;
      case "Aptitude":
        topic_formula = APTITUDE_TOPIC;
        topic_formula_name = APTITUDE_TOPIC[0];
        key_pcma = 3;
        disableTopicFormula = true;
        break;
      default:
        break;
    }
    this.setState({
      // typeOfQuestion_name: type,
      topic_formula,
      topic_formula_name,
      key_pcma,
      errorEvent: "",
      disableTopicFormula,
    });
  };

  showHeaderTextPlan = () => {
    if (this.props.user.plan_details) {
      var planButtonText = "Upgrade Plan";

      if (this.props.user.plan_details.plan_name == "Yearly") {
        var planButtonText = "View Plan";
      }
      return (
        <React.Fragment>
          <React.Fragment>
            <li className="nav-item">
              {/* Get the most of PrepAI with{" "}
                <Link className="nav-link text-success-prep ml-1" to="/plans">
                  Premium Plans
                </Link> */}
              <Link className="btn upgrade-plan-btn" to="/plans">
                {planButtonText}
              </Link>
            </li>
          </React.Fragment>
        </React.Fragment>
      );
    }
  };
  updateState = (state) => {
    this.setState(state)
  }
  render() {
    let que_number_start_pt = this.state.pageNo * 10 - 10; // ques number for first question of every page say 1 for page one 11 for page 2
    let countQues = que_number_start_pt;
    return (
      <React.Fragment>
        <DemoHeader
          handlePopUpOpen={this.handlePopUpOpen}
          showHeaderTextPlan={this.showHeaderTextPlan}
          props={this.props}
        />
        <div style={{ top: "80px", position: "relative" }}>
          <Salebar></Salebar>
        </div>

        <Tooltip title="Coming Soon" id="global" />
        <div>
          <Dialog
            id="free-dialog-box"
            open={this.state.showDemoPopUp}
            onClose={this.handlePopUpClose}
          >
            <DialogTitle
              style={{
                textAlign: "center",
              }}
            >
              <img
                src={`${process.env.REACT_APP_PROJECT_URL}/img/prepAI-new-logo.svg`}
                className={classNames(styles["demo-popup-logo"])}
                style={{
                  height: "200px",
                  width: "250px",
                  objectFit: "contain",
                  objectPosition: "30% 30%",
                  zIndex: "1",
                }}
                alt="prepAi-logo"
              />
            </DialogTitle>

            <Typography
              variant="h3"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "22px",
                zIndex: "5",
                marginTop: "-65px",
                marginBottom: "25px",
              }}
            >
              Start Your 15-day Free Trial
            </Typography>
            <Typography>
              <div
                style={{
                  textAlign: "center",
                  margin: "5px 10px 20px",
                  fontSize: "13px",
                }}
              >
                <Typography variant="h7" style={{ marginBottom: "20px" }}>
                  Generate assessments with AI, at no cost, today!
                </Typography>
                {/* <br />
                <Typography variant="h7">
                  Register on PrepAI and get started.
                </Typography> */}
              </div>
            </Typography>

            <Typography>
              <div style={{ textAlign: "center", lineHeight: "10px" }}>
                <Link to={`/register${this.props.location.search}`}>
                  <p className="btn upgrade-plan-btn">Sign Up Now </p>
                </Link>
                <p style={{ fontSize: "12px", color: "gray" }}>
                  (No CC required)
                </p>
              </div>
            </Typography>

            <Typography>
              <Link to={`/login${this.props.location.search}`}>
                <div style={{ textAlign: "center", fontSize: "14px" }}>
                  <p>Already have an account?</p>
                </div>
              </Link>
            </Typography>
          </Dialog>
        </div>

        <div className="height-cover bg-e5">
          <div className="bg--overlay--top"></div>
          <div
            className={classNames(
              "container",
              styles["demo-custom-margin"],
              "mb-4 position-relative"
            )}
          >
            <div
              className={classNames(
                styles["generate--bg--title"],
                "generate--bg--title"
              )}
            >
              <h3>Generate Advanced Numerical Questions</h3>
              <span>Based on CBSE Curriculum</span>
            </div>
            <ToggleForGQ
              tab="Special"
              credits={this.props.user.credits}
              plan_details={this.props.plan_details}
            />
            <DemoGenerateQuestionsSpecialist 
            setState={this.updateState}
            state={this.state}
            radioButtonValue={this.radioButtonValue}
            setSubject={this.setSubject}
            getSortedOptions={this.getSortedOptions}
            setTopicFormula={this.setTopicFormula}
            handleGenerateQuestion={this.handleGenerateQuestion}
            />
          </div>
        </div>

        {this.state.questionPaperGenerated && (
          <div className="section--padding bg-e5" id="generated_questions">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-10 mb-4">
                  <div className="row justify-content-around">
                    <div className="col-md-5">
                      <p className="section-generated-title">
                        Generated Questions
                      </p>
                    </div>
                    <div
                      className="col-md-7  text-md-right"
                      style={{ display: "contents" }}
                    >
                      <div className="col-md-4">
                        <Link
                          type="button"
                          role="button"
                          style={{ textDecoration: "none" }}
                          className="preview--paper--btn"
                          to={{
                            pathname: "/question-paper-preview-expertise",
                            state: this.state.questions[0],
                          }}
                          onClick={(e) =>
                            trackEvent("Preview Question Paper Clicked", {
                              source: this.state.source,
                            })
                          }
                        >
                          Preview Question Paper
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="question-wrapper--outer">
                    {this.state.totalQuestionsFormed == 0 && (
                      <p>No Questions Formed!</p>
                    )}
                    {this.state.questions.map((question) => {
                      countQues += 1;
                      return (
                        <SubjectExpertise
                          key={`question_${question.question_id}`}
                          question={question}
                          count={countQues}
                          handleQuestionUpdate={this.handleQuestionUpdate}
                          data={{ source: this.state.source }}
                        />
                      );
                    })}
                  </div>
                  {this.state.totalQuestionsFormed != 0 && (
                    <div className="each-question-wrapper-box-pagination">
                      <div className="row align-items-center">
                        <div className="col-md-4">
                         <p
                          className={classNames(
                            styles["pagination-text"],
                            "showing-page-results"
                          )}
                        >
                            Showing {this.state.pageNo}-{this.state.totalPages}{" "}
                            of {this.state.totalPages} items
                          </p>
                        </div>
                        <div className="col-md-8">
                          <ul className="list-unstyled-pagination">
                            <li
                              className={`prev-navigation show-pointer ${
                                this.state.pageNo == 1 ? "sm-disabled" : ""
                              }`}
                              onClick={this.previousPage}
                            >
                              <a>
                                <svg
                                  width="7"
                                  height="12"
                                  viewBox="0 0 7 12"
                                  fill="none"
                                >
                                  <path
                                    d="M5.64209 10.6667L0.999975 6.02457L5.64209 1.38246"
                                    stroke="#B7CCEB"
                                    strokeWidth="1.3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </a>
                            </li>
                            <li className="page-count-navigation">
                              <a>{this.state.pageNo}</a>
                            </li>
                            <li
                              className={`next-navigation show-pointer ${
                                this.state.pageNo == this.state.totalPages
                                  ? "sm-disabled"
                                  : ""
                              }`}
                              onClick={this.nextPage}
                            >
                              <a>
                                <svg
                                  width="7"
                                  height="12"
                                  viewBox="0 0 7 12"
                                  fill="none"
                                >
                                  <path
                                    d="M1.35791 1.33331L6.00003 5.97543L1.35791 10.6175"
                                    stroke="#B7CCEB"
                                    strokeWidth="1.3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {this.state.showFeedbackForm && (
              <div className="question-generate-tab-outer maxw-820 feedback">
                <button
                  type="button"
                  className="close close_buttons"
                  aria-label="Close"
                  style={{ outline: "none" }}
                  onClick={() => this.setState({ showFeedbackForm: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="swal-titile">
                  <h4>
                    {/* <span>Your Question Paper is being Generated.</span>
                    <br />
                    <span>
                      Meanwhile, what do you think of your recent Question
                      Paper?{" "}
                    </span> */}
                    <span>Please rate your recent Question Paper? </span>
                  </h4>
                </div>
                <div className="rate--question large-star1">
                  <span className="feedbackLabel">
                    <span id="labelFeedbackLabel"></span>
                  </span>
                  <div className="line-height-0 d-flex justify-content-center">
                    <div id="bad" className="emoji-scale">
                      <span className="describeEmoji">Bad</span>
                      <i
                        className={`emoji ${
                          this.state.questionPaperRating == 1 ? "fas" : "far"
                        } fa-frown highlight ${this.state.highlightBadEmoji}`}
                        onClick={() =>
                          this.setState({
                            questionPaperRating: 1,
                            highlightNeutralEmoji: null,
                            highlightGoodEmoji: null,
                            highlightBadEmoji: "highlight-emoji",
                          })
                        }
                      ></i>
                    </div>
                    <div id="neutral" className="emoji-scale">
                      <span className="describeEmoji">Neutral</span>
                      <i
                        className={`emoji ${
                          this.state.questionPaperRating == 2 ? "fas" : "far"
                        } fa-meh highlight ${this.state.highlightNeutralEmoji}`}
                        onClick={() =>
                          this.setState({
                            questionPaperRating: 2,
                            highlightBadEmoji: null,
                            highlightGoodEmoji: null,
                            highlightNeutralEmoji: "highlight-emoji",
                          })
                        }
                      ></i>
                    </div>
                    <div id="Good" className="emoji-scale">
                      <span className="describeEmoji">Good</span>
                      <i
                        className={`emoji ${
                          this.state.questionPaperRating == 3 ? "fas" : "far"
                        } fa-grin-alt highlight ${
                          this.state.highlightGoodEmoji
                        }`}
                        onClick={() =>
                          this.setState({
                            questionPaperRating: 3,
                            highlightBadEmoji: null,
                            highlightNeutralEmoji: null,
                            highlightGoodEmoji: "highlight-emoji",
                          })
                        }
                      ></i>
                    </div>
                  </div>
                  <TextField
                    id="outlined-basic"
                    label="Enter Comment"
                    variant="outlined"
                    type="email"
                    fullWidth
                    value={this.state.ratingComment}
                    onChange={this.handleRatingComment}
                    style={{ width: "80%", marginLeft: "11%" }}
                  ></TextField>
                </div>
                <button
                  type="submit"
                  className="btn--submit"
                  onClick={this.handleQPRating}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        )}
        <SweetAlert
          show={this.state.showRatingAlert}
          showConfirm
          showCloseButton
          style={{ zIndex: 99999999 }}
          title={
            <>
              <div className="swal-titile">
                <h4>
                  <span>Your Question Paper is being Generated.</span>
                  <br />
                  <span>
                    Meanwhile, what do you think of your recent Question Paper?{" "}
                  </span>
                </h4>
              </div>
              <div className="rate--question large-star1 mt-4">
                <span className="feedbackLabel">
                  <span id="labelFeedbackLabel"></span>
                </span>
                <div className="line-height-0 d-flex justify-content-center pt-4">
                  <div id="bad" className="emoji-scale">
                    <span className="describeEmoji">Bad</span>
                    <i
                      className={`emoji ${
                        this.state.questionPaperRating == 1 ? "fas" : "far"
                      } fa-frown highlight ${this.state.highlightBadEmoji}`}
                      onClick={() =>
                        this.setState({
                          questionPaperRating: 1,
                          highlightNeutralEmoji: null,
                          highlightGoodEmoji: null,
                          highlightBadEmoji: "highlight-emoji",
                        })
                      }
                    ></i>
                  </div>
                  <div id="neutral" className="emoji-scale">
                    <span className="describeEmoji">Neutral</span>
                    <i
                      className={`emoji ${
                        this.state.questionPaperRating == 2 ? "fas" : "far"
                      } fa-meh highlight ${this.state.highlightNeutralEmoji}`}
                      onClick={() =>
                        this.setState({
                          questionPaperRating: 2,
                          highlightBadEmoji: null,
                          highlightGoodEmoji: null,
                          highlightNeutralEmoji: "highlight-emoji",
                        })
                      }
                    ></i>
                  </div>
                  <div id="Good" className="emoji-scale">
                    <span className="describeEmoji">Good</span>
                    <i
                      className={`emoji ${
                        this.state.questionPaperRating == 3 ? "fas" : "far"
                      } fa-grin-alt highlight ${this.state.highlightGoodEmoji}`}
                      onClick={() =>
                        this.setState({
                          questionPaperRating: 3,
                          highlightBadEmoji: null,
                          highlightNeutralEmoji: null,
                          highlightGoodEmoji: "highlight-emoji",
                        })
                      }
                    ></i>
                  </div>
                </div>
              </div>
            </>
          }
          confirmBtnText="Submit"
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={this.handleRatingPromptCloseEvent}
          onOutsideClick={this.handleRatingPromptCloseEvent}
          onConfirm={this.handleQPRating}
          onCancel={this.handleRatingPromptCloseEvent}
        ></SweetAlert>

        <SweetAlert
          customClass="sweet-alert"
          show={this.state.showPremiumPrompt}
          showConfirm={false}
          title={
            <div className="swal-title">
              <p className="style-text">{this.state.premiumPromptText}</p>
              <Link className="btn prompt-plan-btn" to="/plans">
                Upgrade Plan
              </Link>
              <span style={{ margin: "20px", fontSize: "20px" }}>OR</span>
              <Link className="btn prompt-referral-btn" to="/refer-prepai">
                Refer PrepAI
              </Link>
            </div>
          }
          onEscapeKey={() => this.setState({ showPremiumPrompt: false })}
          onOutsideClick={() => this.setState({ showPremiumPrompt: false })}
          onConfirm={() => this.setState({ showPremiumPrompt: false })}
          onCancel={() => this.setState({ showPremiumPrompt: false })}
        />
        <SweetAlert
          customClass="sweet-alert"
          show={window.innerWidth > 767 && this.state.showUpdateProfilePopUp}
          showConfirm
          title="Please complete your profile to continue"
          confirmBtnText={
            this.props.user.update_profile_index == 3 ? "Finish" : "Continue"
          }
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={() => this.setState({ showUpdateProfilePopUp: false })}
          onOutsideClick={() =>
            this.setState({ showUpdateProfilePopUp: false })
          }
          onConfirm={this.UpdateProfileSubmit}
          onCancel={() => this.setState({ showUpdateProfilePopUp: false })}
          style={{ overflow: "initial", marginTop: "150px" }}
        >
          <form className="mt-4">
            {this.props.user.update_profile_index == 1 && (
              <div
                className="d-flex justify-content-start"
                style={{
                  marginBottom: "0",
                  marginTop: "18px",
                  marginLeft: "98px",
                }}
              >
                <label className="form--label">
                  What best describes your role?*
                </label>

                <div
                  className="select-list"
                  style={{ marginLeft: "35px", fontSize: "larger" }}
                >
                  <select
                    name="profession"
                    className="source_from"
                    required
                    value={this.state.profession}
                    onChange={this.handleUpdateProfileChange}
                  >
                    <option value="">Select</option>
                    {profession().map((op) => {
                      return (
                        <option key={op} value={op}>
                          {op}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}{" "}
            {this.props.user.update_profile_index == 2 && (
              <div
                className="d-flex justify-content-start"
                style={{
                  marginBottom: "0",
                  marginTop: "18px",
                  marginLeft: "98px",
                }}
              >
                <label className="form--label">
                  How did you discover PrepAI?*
                </label>
                <div
                  className="select-list"
                  style={{ marginLeft: "35px", fontSize: "larger" }}
                >
                  <select
                    name="source_from"
                    className="source_from"
                    required
                    onChange={this.handleUpdateProfileChange}
                    value={this.state.source_from}
                    disabled={this.state.referralActive}
                  >
                    <option value="">Select</option>

                    {findUsList().map((op) => {
                      return (
                        <option key={op} value={op}>
                          {op}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
            {this.props.user.update_profile_index == 3 && (
              <div
                className="d-flex justify-content-start"
                style={{
                  marginBottom: "0",
                  marginTop: "18px",
                  marginLeft: "98px",
                }}
              >
                <label className="form--label">
                  Update your contact number*
                </label>
                <div
                  className="position-relative"
                  style={{ marginLeft: "35px", fontSize: "larger" }}
                >
                  <PhoneInput
                    country={this.state.data.country_code}
                    countryCodeEditable={false}
                    placeholder="Enter phone number"
                    name="formatted_phone_number"
                    value={this.state.data.formatted_phone_number}
                    onChange={this.handleOnChange}
                    buttonStyle={{ backgroundColor: "transparent" }}
                  />
                </div>
              </div>
            )}
            <div
              style={{
                position: "relative",
                marginLeft: "98px",
                marginTop: "18px",
              }}
            >
              {this.state.source_from === "Others" &&
                this.props.user.update_profile_index == 2 && (
                  <div
                    className="form-group form-input"
                    // style={{ position: "absolute", left: "0", top: "-35px" }}
                  >
                    <label htmlFor="source_from_other" className="form--label">
                      Please Specify*
                    </label>
                    <input
                      type="text"
                      name="source_from_other"
                      id="source_from_other"
                      value={this.state.source_from_other}
                      onChange={this.handleChange}
                      required
                      style={{ marginLeft: "35px", fontSize: "larger" }}
                    />
                  </div>
                )}

              {this.state.profession === "Others" &&
                this.props.user.update_profile_index == 1 && (
                  <div
                    className="form-group form-input"
                    // style={{
                    //   marginLeft:
                    //     this.state.source_from === "Others" && "17px",
                    //   position: "absolute",
                    //   right: "5px",
                    //   top: "-35px",
                    // }}
                  >
                    <label htmlFor="profession_other" className="form--label">
                      Please Specify*
                    </label>
                    <input
                      type="text"
                      name="profession_other"
                      id="profession_other"
                      value={this.state.profession_other}
                      onChange={this.handleChange}
                      required
                      style={{ marginLeft: "35px", fontSize: "larger" }}
                    />
                  </div>
                )}
            </div>
          </form>
          {this.state.showLoader ? (
            <div className="spinner-border mt-3" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {/* For the case when less than required number of questions are generated */}
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.lessQuestionsGenerated}
          showCloseButton
          showConfirm={false}
          title={
            <div className="swal-title">
              <p className="style-text">
                Only {this.state.totalQuestionsFormed} questions generated. Add
                more content to generate the required number of questions.
              </p>
            </div>
          }
          onEscapeKey={() => this.setState({ lessQuestionsGenerated: false })}
          onOutsideClick={() =>
            this.setState({ lessQuestionsGenerated: false })
          }
          onConfirm={() => this.setState({ lessQuestionsGenerated: false })}
          onCancel={() => this.setState({ lessQuestionsGenerated: false })}
        />

        {/* For the case when more than required number of questions are generated */}
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.moreQuestionsGenerated}
          showConfirm={false}
          showCancel
          title={
            <div className="swal-title">
              <p className="style-text">
                {" "}
                Questions generated are more than requested{" "}
              </p>
            </div>
          }
          onEscapeKey={() => this.setState({ moreQuestionsGenerated: false })}
          onOutsideClick={() =>
            this.setState({ moreQuestionsGenerated: false })
          }
          onConfirm={() => this.setState({ moreQuestionsGenerated: false })}
          onCancel={() => this.setState({ moreQuestionsGenerated: false })}
        />

        <SweetAlert
          show={this.state.showAddOn}
          showCloseButton
          // confirmBtnBsStyle="success"
          confirmBtnCssClass="d-none"
          // title="Purchase Plan"
          confirmBtnText={`Proceed`}
          // disabled={!stripe}
          onEscapeKey={() => this.setState({ showAddOn: false })}
          onOutsideClick={() => this.setState({ showAddOn: false })}
          onCancel={() => this.setState({ showAddOn: false })}
        >
          <div style={{ padding: "20px" }}>
            <h4>Upgrade to access Premium add ons</h4>
            <div className="login--form">
              <div
                style={{ textAlign: "center" }}
                dangerouslySetInnerHTML={{ __html: this.state.addOnText }}
              ></div>

              <div>
                <button
                  onClick={() => this.displayStripe(this.state.activatePlan)}
                  type="button"
                  role="button"
                  className="preview--paper--btn"
                  style={{
                    width: "max-content",
                  }}
                  disabled={this.state.showLoader}
                >
                  {this.state.addOnCTAText}
                </button>
              </div>
              {/* {this.state.activatePlan.plan_name === "Free" && (
                <Link to="/plans" className="ff-tdd">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.state.freeAddOnSubText,
                    }}
                  ></span>
                </Link>
              )} */}
              {this.state.showLoader ? (
                <div className="spinner-border mt-3" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </SweetAlert>

        <SweetAlert
          show={this.state.showStripeForm}
          showCloseButton
          // confirmBtnBsStyle="success"
          confirmBtnCssClass="d-none"
          title="Pay Now"
          confirmBtnText={`Pay Now`}
          showConfirm={false}
          // disabled={!stripe}
          onEscapeKey={() => this.setState({ showStripeForm: false })}
          onOutsideClick={() => this.setState({ showStripeForm: false })}
          onCancel={() => this.setState({ showStripeForm: false })}
        >
          <div className="login--form">
            {this.state.stripeClientSecret && (
              <Elements
                options={{ clientSecret: this.state.stripeClientSecret }}
                stripe={this.state.stripePromise}
              >
                <InjectedCheckoutForm
                  {...this.props}
                  data={this.state.stripeClientSecret}
                />
              </Elements>
            )}
          </div>
        </SweetAlert>

        {this.state.showTopUp && (
          <QuestionPaperTopUp
            data={
              this.props.addOnsList["Question-Paper"][
                this.props.user.plan_details.plan_name
              ]
            }
            action={this.showTopUp}
            callBack={this.questionPaperTopUPCallBack}
          ></QuestionPaperTopUp>
        )}

        <ReferPopup />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    addOnsList: state.auth.addOnsList,
    showPopup: state.generalActions.showPopUp,
    defaultContent: state.generalActions.defaultContent,
    customQuesType: state.generalActions,
  };
};

export default connect(mapStateToProps)(SubjectExpertiseQuestions);
