import HTTP from "./../../utils/Http";

// Actions
const AUTH_CHECK = "AUTH_CHECK";
const AUTH_LOGIN = "AUTH_LOGIN";
const AUTH_LOGOUT = "AUTH_LOGOUT";
const SET_USER_DATA = "SET_USER_DATA";
const GET_ADD_ON = "GET_ADD_ON";
const RESET_NOTIFICATION_COUNT = "RESET_NOTIFICATION_COUNT";
const UPDATE_BILLING_ADDRESS_FLAG = "UPDATE_BILLING_ADDRESS_FLAG";
const SET_USER_ID = "SET_USER_ID";
const UPDATE_PROFILE_DETAILS = "UPDATE_PROFILE_DETAILS";

// Action Creators
export function updateProfileDetails(payload) {
  return { type: UPDATE_PROFILE_DETAILS, payload: payload };
}

export function authCheck() {
  return { type: AUTH_CHECK };
}

export function authLogin(payload) {
  return { type: AUTH_LOGIN, payload };
}

export function setUserId(payload) {
  return { type: SET_USER_ID, payload };
}

export function setUserData(payload) {
  return { type: SET_USER_DATA, payload };
}

export function getAddOns(payload) {
  return { type: GET_ADD_ON, payload };
}

export function authLogout() {
  return { type: AUTH_LOGOUT };
}

export function resetNotificationCount() {
  return { type: RESET_NOTIFICATION_COUNT };
}

export function updateBillingAddressFlag(payload) {
  return { type: UPDATE_BILLING_ADDRESS_FLAG, payload };
}

const initialState = {
  isAuthenticated: false,
  user: {},
  currentPlan: {},
};

// Reducer
export default function reducer(
  state = initialState,
  { type, payload = null }
) {
  switch (type) {
    case AUTH_LOGIN:
      return login(state, payload);
    case AUTH_CHECK:
      return checkAuth(state);
    case AUTH_LOGOUT:
      return logout(state);
    case SET_USER_DATA:
      return setLoginUserData(state, payload);
    case GET_ADD_ON:
      return setAddOnData(state, payload);
    case SET_USER_ID:
      return userId(state, payload);
    case RESET_NOTIFICATION_COUNT:
      return resetNotificationCountReducer(state);
    case UPDATE_BILLING_ADDRESS_FLAG:
      return changeBillingAddressFlag(state, payload);
    case UPDATE_PROFILE_DETAILS:
      return updateProfileDetail(state, payload)
    default:
      return state;
  }
}

function updateProfileDetail(state, payload) {
  return {
    ...state,
    user: { ...state.user, ...payload},
  };
}

function login(state, payload) {
  localStorage.setItem("access_token", payload);
  HTTP.defaults.headers.common["Authorization"] = `Bearer ${payload}`;

  return {
    ...state,
    isAuthenticated: true,
  };
}
function userId(state, payload) {
  localStorage.setItem("question_mode", payload);
  return {
    ...state,
  };
}

function checkAuth(state) {
  // console.log("in auth check", state);
  state = Object.assign({}, state, {
    isAuthenticated: !!localStorage.getItem("access_token"),
  });

  if (state.isAuthenticated) {
    HTTP.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return state;
}

function logout(state) {
  localStorage.removeItem("access_token");
  return {
    ...state,
    isAuthenticated: false,
  };
}

function setLoginUserData(state, payload) {
  return {
    ...state,
    user: { ...payload },
    currentPlan: { ...payload.plan_details },
  };
}

function setAddOnData(state, payload) {
  return {
    ...state,
    addOnsList: { ...payload },
  };
}

function resetNotificationCountReducer(state) {
  return {
    ...state,
    user: { ...state.user, new_notify_count: 0 },
  };
}

function changeBillingAddressFlag(state, payload) {
  return {
    ...state,
    user: { ...state.user, is_billing_address: payload },
  };
}
