import React, {Component} from "react";
import {connect} from "react-redux";
import {activateAddOns} from "../../services";
import {startLoader, stopLoader} from "../../store/reducers/generalActions";
import MSG, {notifySuccess, notifyError} from "../../utils/Helper";
import {PaymentElement} from "@stripe/react-stripe-js";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../styles/planList.css";
import EditProfile from "../edit-profile";
import {RxCross1} from "react-icons/rx";
import {IoIosArrowForward} from "react-icons/io";

const CheckoutFormHeader = ({onClose}) => {
	return (
		<>
			<div className='checkout-header'>
				<img
					src={`${process.env.REACT_APP_PROJECT_URL}/img/prepAI-new-logo.svg`}
					alt='prepAi-logo'
					style={{width: "120px"}}
				/>
				<div onClick={onClose} className='checkout-header-x'>
					<RxCross1 />
				</div>
			</div>
		</>
	);
};

class CheckoutForm extends Component {
	state = {
		showLoader: false,
		showStepThreeModal: false,
		showStepTwoModal: true,
		showEditProfile: false,
		isAgreed: false,
	};

	handleIsAgreed = () => {
		this.setState(prevState => ({
			isAgreed: !prevState.isAgreed,
		}));
	};

	componentDidMount() {
		this.setState({
			showStepTwoModal: true,
		});
		console.log("test");
	}

	handleStripeCheckoutFormSubmit = async event => {
		event.preventDefault();
		if (!this.state.isAgreed) {
			notifyError("Please agree to the terms and conditions.");
			return;
		  }
		this.setState({
			showLoader: !this.state.showLoader,
		});
		
		const {stripe, elements} = this.props;
		if (!stripe) {
			return false;
		}

		const {paymentIntent, error} = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Return URL where the customer should be redirected after the PaymentIntent is confirmed.
				return_url: "",
			},
			redirect: "if_required",
		});

		console.log("error", error);

		// const { error } = await stripe.confirmSetup({
		//   //`Elements` instance that was used to create the Payment Element
		//   elements,
		//   // confirmParams: {
		//   //   return_url: 'https://example.com/account/payments/setup-complete',
		//   // };
		//   redirect: "if_required",
		// });

		if (error) {
			console.log("err", error);
			if (error.message) {
				notifyError(error.message);
			} else {
				notifyError("Paymet failed. Please try again.");
			}
			this.setState({
				showLoader: !this.state.showLoader,
			});
			return;
		}

		// if (paymentIntent) {
		//   notifySuccess("Plan purchased successfully.");
		// }

		// const paymentMethod = await stripe.createPaymentMethod({
		//   type: 'card',
		//   card: elements,
		// });

		// console.log("paymentMethod", paymentMethod)

		this.props.dispatch(startLoader());
		try {
			let req = {
				client_secret: this.props.selectedPurPlan.clientSecret,
				payment_details: paymentIntent,
				is_redeemed: this.props.selectedPurPlan.is_coupon_applied,
				coupon: this.props.selectedPurPlan.coupon,
				team_size: this.props.selectedPurPlan.team_size,
			};

			const {data: res} = await activateAddOns(req);
			if (res.success === true) {
				notifySuccess(res.message);
				this.props.history.replace("my-account");
			} else {
				notifyError(res.message);
			}
		} catch (err) {
			console.log(err);
			if (err.response && err.response.status === 422) {
				const {data} = err.response;
				notifyError(data.message);
			} else {
				notifyError(MSG.commonApiError);
			}
		}

		this.setState({
			showLoader: !this.state.showLoader,
		});
		this.props.dispatch(stopLoader());
	};

	showCurrencySymbol = () => {
		var currency = this.props.selectedPurPlan.currency.toUpperCase();
		if (currency === "INR") return <span className='cur_sign'>₹</span>;
		else if (currency === "BWP") return <span className='cur_sign'>P</span>;
		else if (currency === "GBP") return <span className='cur_sign'>£</span>;
		else if (currency === "EUR") return <span className='cur_sign'>€</span>;
		else return <span className='cur_sign'>$</span>;
	};

	showPriceTotal = () => {
		if (this.props.selectedPurPlan.plan_name === "Quarterly") {
			return this.props.selectedPurPlan.price_usd * 3;
		} else if (this.props.selectedPurPlan.plan_name === "Yearly") {
			return this.props.selectedPurPlan.price_usd * 12;
		}
		return this.props.selectedPurPlan.price_usd;
	};

	handleSubmit = async event => {
		event.preventDefault();
		this.props.dispatch(startLoader());
		await this.setState(prevState => ({
			showStepThreeModal: true,
		}));
		this.props.dispatch(stopLoader());
	};

	redirectToEditProfile = () => {
		this.setState(prevState => ({
			showEditProfile: true,
		}));
	};

	redirectToBilling = () => {
		this.props.handleAddressPopup("edit");
	};

	redirectToPayment = () => {
		this.setState(prevState => ({
			showStepThreeModal: false,
			showStepTwoModal: true,
		}));
	};
	redirectToAddons = () => {
		this.setState(prevState => ({
			showStepThreeModal: false,
			showStepTwoModal: true,
		}));
		// this.props.handleAddonPopup()
	};

	render() {
		const {stripe} = this.props;
		const CARD_OPTIONS = {
			iconStyle: "solid",
			style: {
				base: {
					iconColor: "#fff",
					color: "#fff",
					fontWeight: 500,
					fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
					fontSize: "16px",
					fontSmoothing: "antialiased",
					":-webkit-autofill": {color: "#fff"},
					"::placeholder": {color: "#fff"},
				},
				invalid: {
					iconColor: "#ffc7ee",
					color: "#ffc7ee",
				},
			},
		};
		return (
			<div className='plan-list'>
				<SweetAlert
					show={this.state.showStepTwoModal}
					showConfirm={false}
					onEscapeKey={() => {}}
					onOutsideClick={() => {}}
					onCancel={() => {}}
					style={{
						height: "90% !important",
						overflowY: "auto",
						width: "78rem !important",
					}}
				>
					<div className='address-form-container'>
						<CheckoutFormHeader
							onClose={() => {
								this.setState({
									showStepTwoModal: false,
								});
								this.props.handleClose();
							}}
						/>
						<h1 className='address-form-heading'>Payment</h1>
						<p className='step-desc'>step 2 of 3</p>
						<h2 className='checkout-sub-heading'>
							Add your payment details
						</h2>
						<form
							onSubmit={this.handleSubmit}
							className='address-form-sub-container'
						>
							{/* <div className='checkout-sub-heading2'>
								PrepAI Solo - Lifetime{" "}
								{this.props.selectedPurPlan.is_coupon_applied ? (
									<>
										<span className='pricecrossover line-through mr-2'>
											{this.showCurrencySymbol()}
											{this.showPriceTotal()}{" "}
										</span>{" "}
										{this.showCurrencySymbol()}
										{this.props.selectedPurPlan.amount}{" "}
									</>
								) : (
									<span>
										{this.showCurrencySymbol()}
										{this.props.selectedPurPlan.amount}
									</span>
								)}
							</div> */}
							<PaymentElement />
							<button
								// type='submit'
								className='checkout-submitButton'
								onClick={e => {
									this.handleSubmit(e);
								}}
								// disabled={this.state.showLoader}
							>
								Next
							</button>
							{this.props.selectedPurPlan.is_coupon_applied ? (
								<span className='checkout-agree'>
									Coupon Code Applied - 20% Off
								</span>
							) : (
								""
							)}
						</form>
						<div className='address-form-address'>
							© 2023 DataToBiz Pvt. Ltd., F-429, Industrial Area, <br />
							Phase 8B, Mohali - 160059, India
						</div>
					</div>
				</SweetAlert>

				<SweetAlert
					show={this.state.showStepThreeModal}
					showConfirm={false}
					onEscapeKey={() => {}}
					onOutsideClick={() => {}}
					onCancel={() => {}}
					style={{
						height: "90% !important",
						overflowY: "auto",
						width: "78rem !important",
					}}
				>
					<div className='address-form-container'>
						<CheckoutFormHeader
							onClose={() => {
								this.setState({
									showStepThreeModal: false,
								});
							}}
						/>
						<h1 className='address-form-heading'>Payment</h1>
						<p className='step-desc'>step 3 of 3</p>
						<h2 className='checkout-sub-heading'>Complete your order</h2>
						<div className='checkout-sub-container'>
							<div className='checkout-sub-heading2'>
								PrepAI Lifetime Plan{" "}
								{this.props.selectedPurPlan.is_coupon_applied ? (
									<>
										<span className='pricecrossover line-through mr-2'>
											{this.showCurrencySymbol()}
											{this.showPriceTotal()}{" "}
										</span>{" "}
										{this.showCurrencySymbol()}
										{this.props.selectedPurPlan.amount}{" "}
									</>
								) : (
									<span>
										{this.showCurrencySymbol()}
										{this.props.selectedPurPlan.amount}
									</span>
								)}
							</div>
							<div className='sub-links'>
								<div>
									<h2 className='checkout-sub-topics'>
										Account Details
									</h2>
									<p className="step3-details">{this.props.user.name}</p>
									<p className="step3-details">{this.props.user.email}</p>
									{this.props.user.contact_number && (
										<p className="step3-details">{`${this.props.user.dial_code} ${this.props.user.contact_number}`}</p>
									)}
								</div>
								<div
									className='checkout-edit-icon'
									onClick={this.redirectToEditProfile}
								>
									Edit
									<IoIosArrowForward />
								</div>
							</div>

							<div className='sub-links'>
								<div>
									<h2 className='checkout-sub-topics'>
										Billing Address Details
									</h2>
									<p className='step3-details'>
										{this.props.user?.company_name &&
											this.props.user?.company_name}
									</p>
									<p className='step3-details'>
										{this.props.user?.address && this.props.user?.city
											? `${this.props.user?.address}, ${this.props.user?.city}`
											: "-"}
									</p>
									<p className='step3-details'>
										{this.props.user?.country_name &&
										this.props.user?.state
											? `${this.props.user?.state}, ${this.props.user?.country_name}`
											: "-"}
									</p>
								</div>
								<div
									onClick={this.redirectToBilling}
									className='checkout-edit-icon'
								>
									Edit
									<IoIosArrowForward />
								</div>
							</div>

							<div className='sub-links'>
								<div>
									<h2 className='checkout-sub-topics'>
										Payment Details
									</h2>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "flex-start",
										}}
									>
										<img style={{width:"40px", height: "20px", marginRight: "3px"}}
											src="https://res.cloudinary.com/dnegbyf6u/image/upload/v1710152844/phlmmahjldvukuo8kdii.jpg"
											alt="creditcard"
										/>
										<p className="step3-details">ending XXXX</p>
									</div>
								</div>
								<div
									onClick={this.redirectToPayment}
									className='checkout-edit-icon'
								>
									Edit
									<IoIosArrowForward />
								</div>
							</div>
							<p className='checkout-agree'>
								<input
									type='checkbox'
									id='addOnCheckbox'
									onChange={this.handleIsAgreed}
									checked={this.state.isAgreed}
									style={{
										color: "#01d6a6",
										marginRight: "5px"
									}}
								/>
								I agree to{" "}
								<a
									href='https://www.prepai.io/terms-and-conditions/'
									target="_blank"
									rel="noopener noreferrer"
									className='checkout-terms'
								>
									Terms of Service
								</a>{" "}
								and{" "}
								<a
									href='https://www.prepai.io/privacy-policy/'
									className='checkout-terms'
									target="_blank"
									rel="noopener noreferrer"
								>
									Privacy Policy
								</a>
							</p>
							{this.state.showStepThreeModal && (
								<button
									onClick={this.handleStripeCheckoutFormSubmit}
									className='checkout-submitButton'
									disabled={!this.state.isAgreed || this.state.showLoader}
								>
									Pay
								</button>
							)}

							<p className='checkout-agree'>
								You may be redirected to your bank page for 3D secure
								verification.
							</p>
						</div>
						<div className='address-form-address'>
							© 2023 DataToBiz Pvt. Ltd., F-429, Industrial Area, <br />
							Phase 8B, Mohali - 160059, India
						</div>
					</div>
				</SweetAlert>

				<SweetAlert
					show={this.state.showEditProfile}
					showCloseButton
					showConfirm={false}
					onEscapeKey={() => {}}
					onOutsideClick={() => {
						this.setState(prevState => ({
							showEditProfile: false,
							showStepThreeModal: true,
						}));
					}}
					onCancel={() => {
						this.setState(prevState => ({
							showEditProfile: false,
							showStepThreeModal: true,
						}));
					}}
					style={{
						height: "90% !important",
						overflowY: "auto",
						width: "78rem !important",
					}}
				>
					<EditProfile />
				</SweetAlert>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
		currentPlan: state.auth.currentPlan,
		selectedPurPlan: state.generalActions.plan,
	};
};

export default connect(mapStateToProps)(CheckoutForm);
