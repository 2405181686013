import React, { useEffect, useState } from "react";
import styles from "../../styles/demoResponsive.module.css";
import classNames from "classnames";
import ReactSelect, { components } from "react-select";
import { useDispatch } from "react-redux";
import { setGenerateDiagram } from "../../store/reducers/generalActions";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactSwitch from "react-switch";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const DIAGRAM_OPTIONS = [
  // { value: 0, label: "None" },
  { value: 1, label: "Diagrams" },
  { value: 2, label: "Graphs" },
  { value: 3, label: "Tables" },
  { value: 4, label: "All Possible" },
];

const DIFFICULTY_OPTIONS = [
  { value: "Easy", label: "Easy" },
  { value: "Medium", label: "Medium" },
  { value: "Hard", label: "Hard" },
];

const CUSTOM_STYLES = {
  option: (provided, state) => ({
    ...provided,
  }),

  container: (base) => ({
    ...base,

    // border: this.state.occupation_message ? "1px solid red" : "",
    borderRadius: 5,
  }),

  // control: () => ({
  //   // none of react-select's styles are passed to <Control />
  //   border:"1px solid red"
  // }),
  menu: (provided, state) => ({
    ...provided,
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 13,
    color: "#8898aa",
  }),
};

const GenerateQuestionSpecialist = ({
  state,
  setState,
  is_new_pricing,
  props,
  setSubject,
  radioButtonValue,
  getSortedOptions,
  setTopicFormula,
  handleGenerateQuestion,
  handleCustomTopic,
  setCustomTopic,
  removeTopicMissmatchErr,
}) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [difficulty, setDifficulty] = useState([]);
  const [diagram, setDiagram] = useState([]);
  useEffect(() => {
    dispatch(setGenerateDiagram(0));
    setState({
      buttonType: "Easy",
      level_pcma: "easy level",
    });
  }, [dispatch]);

  useEffect(() => {
    const valueSelected = !_.isEmpty(diagram) ? diagram.value : 0;
    dispatch(setGenerateDiagram(valueSelected));
  }, [diagram]);

  const handleDiagram = (value) => {
    if (value) {
      setDiagram(value);
    } else {
      setDiagram([]);
    }
  };

  const handleDifficultyLevel = (value) => {
    if (value) {
      setDifficulty(value);
      switch (value.value) {
        case "Easy":
          setState({
            buttonType: "Easy",
            level_pcma: "easy level",
          });
          break;
        case "Medium":
          setState({
            buttonType: "Medium",
            level_pcma: "medium level",
          });
          break;
        case "Hard":
          setState({
            buttonType: "Hard",
            level_pcma: "hard level",
          });
          break;
        default:
          // Optional default case
          // You can add additional logic here if needed
          break;
      }
    } else {
      setDifficulty([]);
      setState({
        buttonType: "Easy",
        level_pcma: "easy level",
      });
    }
  };

  return (
    <div
      className="question-generate-tab-outer maxw-820"
      style={{ position: "relative" }}
    >
      <div>
        <label
          for="selectques_type"
          className={classNames(
            styles["question-generate-tab-label"],
            "form-label d-flex align-items-center"
          )}
          style={{ fontWeight: 500, color: "#344054", gap: "1rem" }}
        >
          {" "}
          Subject
        </label>
        <div className="text-left" style={{ position: "relative" }}>
          <ReactSelect
            hideSelectedOptions={false}
            className={classNames(styles["question-generate-tab-label"])}
            maxMenuHeight={200}
            components={{
              Option,
            }}
            options={state.subject}
            type="text"
            // defaultValue={state.subject[0]}
            id="selectques_type"
            // onInputChange={(e) => handleCompanyinput(e)}
            onChange={setSubject}
            value={state.subject_name}
            style={{ background: "#f8b8be !important" }}
          />
          {/* error red color on topic missmatch */}

          {state.showTopicMismatchErr && (
            <div
              className="reveal-err"
              style={{
                position: "absolute",
                top: "0px",
                height: "38px",
                width: "100%",
                background: "#f8b8be80",
              }}
            ></div>
          )}
        </div>
        {state.errorEvent === "subject_name" && (
          <span className="error-msg-expertise">{state.errorMsg}</span>
        )}
      </div>
      <div className="mt-3">
        <label
          for="selectques_type"
          className={classNames(
            styles["question-generate-tab-label"],
            "form-label d-flex align-items-center"
          )}
          style={{ fontWeight: 500, color: "#344054", gap: "1rem" }}
        >
          {" "}
          Input Type{" "}
        </label>
        <div style={{ gap: "1rem" }} className="d-flex text-left">
          <label
            className={classNames(
              styles["question-generate-tab-label"],
              "mordern-radiobox-label my-0"
            )}
          >
            <input
              name="topic_"
              type="radio"
              // disabled={state.disableTopicFormula}
              checked={[1, 3].includes(state.key_pcma) ? true : false}
              onChange={(e) => radioButtonValue(e)}
            />
            <span style={{ top: "1px" }} className="design"></span> Topic Name
          </label>
          <label
            style={
              state.disableTopicFormula
                ? { opacity: "0.7", cursor: "no-drop" }
                : null
            }
            className={classNames(
              styles["question-generate-tab-label"],
              "mordern-radiobox-label my-0"
            )}
          >
            <input
              name="formula_"
              type="radio"
              disabled={state.disableTopicFormula}
              checked={state.key_pcma === 2 ? true : false}
              onChange={(e) => radioButtonValue(e)}
            />
            <span style={{ top: "1px" }} className="design"></span> Formula Name
          </label>
        </div>
        {state.errorEvent === "topic_or_formula" && (
          <span className="error-msg-expertise">{state.errorMsg}</span>
        )}
      </div>
      <div className="mt-3">
        {/* <label
          for="selectques_type"
          className={classNames(
            styles["question-generate-tab-label"],
            "form-label d-flex align-items-center"
          )}
          style={{ fontWeight: 500, color: "#344054", gap: "1rem" }}
        >
          {" "}
          {/* Select Topic{" "} */}
        {/* </label> */}
        <div
          style={{
            gap: "1rem",
            display: "flex",
            alignItems: "center",
          }}
          className="d-flex text-left"
        >
          <ReactSwitch
            onColor="#49c0a0"
            height={20}
            width={36}
            // checkedIcon={false}
            // uncheckedIcon={false}
            checked={state.isCustomTopic}
            onChange={() => setCustomTopic()}
          />
          {!state.isCustomTopic ? (
            <label
              className={classNames(
                styles["question-generate-tab-label"],
                "form-label d-flex align-items-center",
                "mordern-radiobox-label my-0"
              )}
              style={{ fontWeight: 500, color: "#344054", gap: "1rem" }}
            >
              {/* <input
              name="topic_1"
              type="radio"
              checked={!state.isCustomTopic}
              onChange={() => setCustomTopic()}
            /> */}
              {/* <span style={{ top: "1px" }} className="design"></span>  */}
              Predefined Topic
            </label>
          ) : (
            <label
              className={classNames(
                styles["question-generate-tab-label"],
                "form-label d-flex align-items-center",
                "mordern-radiobox-label my-0"
              )}
              style={{ fontWeight: 500, color: "#344054", gap: "1rem" }}
            >
              {/* <input
              name="formula_1"
              type="radio"
              // disabled={state.disableTopicFormula}
              checked={state.isCustomTopic}
              onChange={() => setCustomTopic()}
            /> */}
              {/* <span style={{ top: "1px" }} className="design"></span> */}
              Custom Topic
            </label>
          )}
        </div>
      </div>
      <div
        className="custom-login-field"
        style={{ marginBottom: "0", marginTop: "-0.5rem" }}
      >
        {state.isCustomTopic ? (
          <div className="position-relative" style={{ marginTop: "1rem" }}>
            <input
              className={classNames(
                styles["question-generate-tab-input"],
                `custom-login-field--inputs form-control px-2`
              )}
              style={{
                height: "2.35rem",
                borderColor: "#cccccc",
                background: "none",
              }}
              type="text"
              value={state.customTopic}
              onChange={handleCustomTopic}
              placeholder={`Enter Custom ${
                state.key_pcma === 2 ? "Formula" : "Topic"
              }`}
            />
            {state.errorEvent === "custom_topic" && (
              <span className="error-msg-expertise">
                &nbsp;{state.errorMsg}
              </span>
            )}
          </div>
        ) : (
          <div className="mt-3">
            <div className="text-left">
              <ReactSelect
                hideSelectedOptions={false}
                maxMenuHeight={200}
                className={classNames(styles["question-generate-tab-label"])}
                components={{
                  Option,
                }}
                options={getSortedOptions()}
                type="text"
                placeholder={
                  state.key_pcma === 2 ? "Select Formula" : "Select Topic"
                }
                id="selectques_type"
                // onInputChange={(e) => handleCompanyinput(e)}
                onChange={setTopicFormula}
                value={state.topic_formula_name}
                styles={state.customStyles}
                isDisabled={state.isCustomTopic}
              />
            </div>
            {state.errorEvent === "topic_formula" && (
              <span className="error-msg-expertise">{state.errorMsg}</span>
            )}
          </div>
        )}
      </div>

      <div style={{ gap: "1rem" }} className="mt-3 flex-row d-flex">
        <div className="position-relative" style={{ width: "50%" }}>
          <label
            for="selectques_type"
            className={classNames(
              styles["question-generate-tab-label"],
              "form-label d-flex "
            )}
            style={{ fontWeight: 500, color: "#344054", gap: "1rem" }}
          >
            {" "}
            Difficulty Level{" "}
          </label>
          <ReactSelect
            className={classNames(styles["question-generate-tab-label"])}
            // closeMenuOnSelect={false}
            hideSelectedOptions={false}
            maxMenuHeight={200}
            // components={{
            //   Option,
            // }}
            isClearable
            options={DIFFICULTY_OPTIONS}
            type="text"
            placeholder={difficulty?.length > 0 ? difficulty : "Easy"}
            id="selectDifficulty"
            // onInputChange={(e) => this.handleCompanyinput(e)}
            onChange={handleDifficultyLevel}
            name="difficulty"
            value={difficulty}
            styles={CUSTOM_STYLES}
            // isDisabled={
            //   !_.isEmpty(this.props.user) &&
            //   !this.props.user.plan_details.enter_number_of_question
            // }
          />
        </div>
        {/* <div className="easy-med-hard">
          <button
            className={classNames(
              styles[`question-generate-tab-label`],
              styles[`btn-px-custom`],
              `btn py-2 ${
                state.buttonType === "Easy" &&
                "active-diff-btn-sub border-radius-left-diff"
              }`
            )}
            onClick={() =>
              setState({
                buttonType: "Easy",
                level_pcma: "easy level",
              })
            }
          >
            Easy
          </button>
          |
          <button
            className={classNames(
              styles[`question-generate-tab-label`],
              styles[`btn-px-custom`],
              `btn py-2 ${
                state.buttonType === "Medium" && "active-diff-btn-sub"
              }`
            )}
            onClick={() =>
              setState({
                buttonType: "Medium",
                level_pcma: "medium level",
              })
            }
          >
            Medium
          </button>
          |
          <button
            className={classNames(
              styles[`question-generate-tab-label`],
              styles[`btn-px-custom`],
              `btn py-2 ${
                state.buttonType === "Hard" &&
                "active-diff-btn-sub border-radius-right-diff"
              }`
            )}
            onClick={() =>
              setState({
                buttonType: "Hard",
                level_pcma: "hard level",
              })
            }
          >
            Hard
          </button>
        </div> */}
        {/* <div className="generate-diagrams d-flex mt-3">
          <label className="mordern-radiobox-label my-0">
            <input
              name="radio"
              type="radio"
              onClick={(e) => handleCheckBox(e.target.checked)}
              checked={checked}
            />
            <span className="design"></span>
          </label>
          <p className="generate-diagrams-text m-0">
            <span>Generate Diagrams</span>{" "}
            <span style={{ fontSize: "8px" }}>
              (Enabling diagram generation will correspondingly extend the
              duration of question generation by a factor of 2.5 times the
              standard time.)
            </span>
          </p>
        </div> */}
        {/* With Diagrams toggle section */}
        <div className="position-relative" style={{ width: "50%" }}>
          <label
            for="selectques_type"
            className={classNames(
              styles["question-generate-tab-label"],
              "form-label d-flex align-items-center"
            )}
            style={{ fontWeight: 500, color: "#344054", gap: "8px" }}
          >
            {" "}
            Include Visuals{" "}
            {/* {diagram?.value > 0 ? (
              <span className="badge badge-danger badge-standard-hots">
                High Processing Time
              </span>
            ) : (
              <span
                style={{ fontSize: "8px", background: "#49c0a0" }}
                className="badge badge-warning text-white"
              >
                Beta
              </span>
            )} */}
            <span
              style={{ fontSize: "8px" }}
              className="badge badge-warning text-white"
            >
              COMING SOON
            </span>
          </label>
          <ReactSelect
            className={classNames(styles["question-generate-tab-label"])}
            // closeMenuOnSelect={false}
            hideSelectedOptions={false}
            maxMenuHeight={200}
            // isDisabled={true}
            // components={{
            //   Option,
            // }}
            isClearable
            options={DIAGRAM_OPTIONS}
            type="text"
            placeholder={diagram?.length > 0 ? diagram : "None."}
            id="selectdiagram"
            // onInputChange={(e) => this.handleCompanyinput(e)}
            onChange={handleDiagram}
            name="diagram"
            value={diagram}
            styles={CUSTOM_STYLES}
            isDisabled={true}
            // isDisabled={
            //   !_.isEmpty(this.props.user) &&
            //   !this.props.user.plan_details.enter_number_of_question
            // }
          />
        </div>
      </div>
      <div
        className={classNames(
          "btn-flex100",
          styles["specialist-btn-margin-top-custom"]
        )}
      >
        <button
          onClick={handleGenerateQuestion}
          type="submit"
          className={classNames(
            styles["question-generate-submit"],
            "btn--submit"
          )}
        >
          {props.user.credits <= 1 && !props.user.plan_details.is_new_pricing
            ? "Buy Credits"
            : "Generate Questions"}
        </button>
      </div>

      {/* topic mismatch err alert */}
      {/* <SweetAlert
        show={state.showTopicMismatchErr}
        showCloseButton={false}
        showConfirm={false}
        confirmBtnCssclassName="d-none"
        // onEscapeKey={() => removeTopicMissmatchErr()}
        // onOutsideClick={() => removeTopicMissmatchErr()}
        onCancel={() => removeTopicMissmatchErr()}
        customclassName="custom-sweetalert"
        style={{
          backgroundColor: "transparent !important",
          background: "none !important",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "70%",
              background: "#484565e3",
              borderRadius: "20px",
              color: "white",
              padding: "3rem 1rem",
            }}
          >
            Please enter a valid topic related to the selected subject
          </div>
        </div>
      </SweetAlert> */}
    </div>
  );
};

export default GenerateQuestionSpecialist;
