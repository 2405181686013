import { React, Component } from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { inviteEnterpriseMembers, fetchUser } from "../services";
import { startLoader, stopLoader } from "../store/reducers/generalActions";
import MSG, { notifySuccess, notifyError } from "../utils/Helper";
import _ from "lodash";

class InviteMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendTestPopup: true,
      emails: "",
      errorMsg: "",
      isValid: true,
      isApiError: false,
    };
    this.handleEmailsChange = this.handleEmailsChange.bind(this); // bind the function to the this context
  }

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  handleEmailsChange(event) {
    const { value } = event.target;
    const emails = value.split(/,|\n/).map((email) => email.trim());
    let errorMsg = "";

    let isValid = emails.every((email) => this.validateEmail(email));
    if (!isValid) {
      errorMsg = "Please enter a valid email address";
    }

    // this.setState({
    //   emails: value,
    //   errorMsg,
    //   isValid,
    // });

    // if (value.trim() === this.props.user.email.trim()) {
    //   errorMsg = "You cannot invite yourself.";
    //   isValid = false;
    // }

    this.setState({
      emails: value,
      errorMsg: errorMsg,
      isValid: isValid,
    });
  }

  handleOnKeyDown = (event) => {
    if (event.code == "Space" || event.code == "Enter") {
      if (this.state.isValid) {
        let addComma = this.state.emails.concat(",");
        this.setState({ emails: addComma });
      }
    }
  };

  inviteEnterpriseMembers = async (Emails) => {
    this.props.dispatch(startLoader());
    try {
      const { data: response } = await inviteEnterpriseMembers({
        inviteEmails: Emails,
      });

      if (response.success == true) {
        await this.props.dispatch(fetchUser());
        notifySuccess(response.message);
        this.props.callBack({ hidePopUp: true, success: true });

        // this.setState({
        //   showInvitePopUp: false,
        // });
      } else {
        // notifyError(response.message);
        this.setState({ errorMsg: response.message, isApiError: true });
      }
    } catch (err) {
      console.log(err);
      notifyError(MSG.commonApiError);
    }
    this.props.dispatch(stopLoader());
  };

  handleSubmit = async (e) => {
    let inviteMembers = this.state.emails.split(",").map((item) => item.trim());
    // this.props.callBack({ hidePopUp: false, inviteMembers: inviteMembers, success: true  });

    if (inviteMembers.includes(this.props.user.email.trim())) {
      this.setState({
        errorMsg: "You cannot invite yourself.",
        isValid: false,
      });
      return;
    }

    const total_seats = this.props.user.team_size;
    const remaining_seats = this.props.user.remaining_seats;

    if (inviteMembers.length > total_seats) {
      this.setState({
        errorMsg: `As per your active plan, your team size limit is exceeded`,
        isValid: false,
      });
      return;
    }

    if (remaining_seats === 0) {
      this.setState({
        errorMsg: `You have reached your team size limit.`,
        isValid: false,
      });
      return;
    }

    if (inviteMembers.length > remaining_seats) {
      this.setState({
        errorMsg: `As per your active plan, your team size limit is exceeded.`,
        isValid: false,
      });
      return;
    }

    if (!_.isEmpty(inviteMembers)) {
      this.inviteEnterpriseMembers(inviteMembers);
      return;
    }
  };

  handleClose = () => {
    this.props.callBack({ hidePopUp: true, inviteMembers: [], success: false });
  };

  render() {
    return (
      <div
        className="modal show-modal"
        tabIndex="-1"
        id="share-modal"
        aria-labelledby="share-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" style={{ top: "25%" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Invite Members</h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <br />
            <div className="modal-body" style={{ padding: "2rem" }}>
              <textarea
                id="emails"
                placeholder="Enter one or more email addresses"
                value={this.state.emails}
                onChange={this.handleEmailsChange}
                onKeyDown={this.handleOnKeyDown}
                rows={5}
                cols={50}
                style={{
                  border: this.state.isValid
                    ? "1px solid #ccc"
                    : "1px solid red",
                }}
              />
              {(!this.state.isValid || this.state.isApiError) && (
                <span style={{ color: "red" }}>{this.state.errorMsg}</span>
              )}
              <br />
              <label style={{ fontSize: "10px", fontWeight: "500" }}>
                Note: Add one or multiple users to share (Enter input as:
                abc@example.com, xyz@example.com ……. 123@example.com)
              </label>
              <br />

              <div className="container-fluid">
                <div className="row">
                  <div className="col-xs-4 col-sm-4 col-md-4 col-lg-8"></div>
                  <div
                    className="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                    style={{ paddingLeft: "85px" }}
                  >
                    <br />
                    <div className="btn--group--theme">
                      <div className="btn-group">
                        <button
                          data-toggle="#exampleModal"
                          data-dismiss="#exampleModal"
                          type="submit"
                          className="btn btn-secondary"
                          disabled={
                            !this.state.isValid || this.state.emails === ""
                              ? true
                              : false
                          }
                          onClick={() => this.handleSubmit()}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(InviteMembers);
