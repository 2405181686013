import React, { Component } from "react";
import { Link } from "react-router-dom";

class NotFound extends Component {
  state = {};
  render() {
    return (
      <main>
        <div className="custom-navbar">
          <nav className="navbar navbar-expand-md">
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img
                  src={`${process.env.REACT_APP_PROJECT_URL}/img/prepAi-logo.svg`}
                  alt="prepAi-logo"
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="newaccount--text">
                <p>
                  New account?<Link to="/register">Register</Link>
                </p>
              </div>
            </div>
          </nav>
        </div>

        <div
          className="login-bg bg-cover height-cover item-centered"
          style={{
            backgroundImage: `url(
          ${process.env.REACT_APP_PROJECT_URL}/img/bg-gradient.png
        )`,
          }}
        >
          <div className="login--box maxw-400">
            <div className="row align-items-center no-gutters">
              <div className="col-6">
                <h4 className="login--title">404</h4>
              </div>
            </div>
            <p className="login--support--text">Oops! Page not found</p>
            <div className="devider--shape"></div>
            <div className="login--form">
              <div className="text-center mt-2">
                <Link to="/" className="forgot--password--text">
                  Go home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default NotFound;
