import React from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { createSets, createSetsExpertise } from "../../services";
import { startLoader, stopLoader } from "../../store/reducers/generalActions";
import MSG, { notifySuccess, notifyError } from "../../utils/Helper";
import _ from "lodash";
import classNames from "classnames";
import styles from "../../styles/popupResponsive.module.css";

class QuestionPaperSetsExpertise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.data.show,
      showLoader: false,
      data: {
        topic: "",
        content_id: props.data.content_id,
        questionCount: 0,
        totalsets: 0,
      },
      maxQueLimit: 0,
      category: "",
    };
  }

  // componentWillReceiveProps(nextProps) {
  //   // console.log("nextProps", nextProps);
  //   console.log("ran")
  //   this.setState({
  //     show: nextProps.data.show,
  //     data: {
  //       ...this.state.data,
  //       content_id: nextProps.data.content_id,
  //     },
  //   });
  // }

  handleInputChange = (event) => {
    event.preventDefault();
    const target = event.target;
    const value = target.value ? parseInt(target.value) : 0;
    const name = target.name;

    this.setState({
      maxQueLimit: 0,
      category: "",
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  handleSubmit = async () => {
    const { data } = this.state;

    if (!data.topic) {
      notifyError("Enter topic");
      return;
    }

    if (!data.questionCount) {
      notifyError("Please Enter number of Questions");
      return;
    }

    let queCount = this.props.data.que_count;

    if (data.questionCount > queCount) {
      this.setState({
        maxQueLimit: queCount,
        category: "Questions",
      });
      return;
    }

    if (!data.totalsets) {
      notifyError("Enter total sets");
      return;
    }

    this.setState({
      showLoader: true,
    });

    try {
      const { data: res } = await createSetsExpertise(data);
      if (res.success == true) {
        notifySuccess(res.message);
        this.setState({
          show: false,
          data: {
            ...this.state,
            topic: "",
          },
        });
        this.props.callBack({
          showPopUpBloom: this.state.show,
          isSuccess: true,
        });
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      notifyError(MSG.commonApiError);
    }
    this.setState({
      showLoader: false,
    });
  };

  handleClose = () => {
    this.setState({ show: false }, () => {
      this.props.callBack({ showPopUp: this.state.show, isSuccess: false });
    });
  };

  render() {
    const quesCount = this.props.data.que_count;
    return (
      <SweetAlert
        show={this.state.show}
        showConfirm={false}
        style={{ zIndex: 99999999 }}
        title={
          <>
            <div className="swal-titile">
              <h4 style={{ marginTop: "0.65rem" }}>
                <span>Create Secondary Question Paper</span>
                <br />
              </h4>
              <span className={classNames(styles["parah"], "subset-sub--text")}>
                Choose the number of questions to be added to your set in each
                category
              </span>
            </div>
          </>
        }
        // onEscapeKey={this.handleClose}
        // onOutsideClick={this.handleSubmit}
        // onCancel={this.handleClose}
      >
        <button
          type="button"
          className="close sets-standard-close"
          aria-label="Close"
          style={{ outline: "none" }}
          onClick={this.handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <div className="position-relative" style={{ top: "-27px" }}>
          <input
            className={classNames(styles["topic"], "subset-topic")}
            type="text"
            name="topic"
            value={this.state.data.topic}
            onChange={(e) =>
              this.setState({
                data: { ...this.state.data, topic: e.target.value },
              })
            }
            id="titleofTest"
            aria-describedby="textHelp"
            placeholder="Enter Topic"
          />
        </div>

        <div className={classNames(styles["form-container"], "set-container")}>
          <div className="col-md-4">
            <div className="form-group">
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"], "form-label")}
                style={{ fontWeight: "400" }}
              >
                Question Count ({quesCount})
              </label>
            </div>

            <div className="form-group">
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"], "form-label")}
                style={{ fontWeight: "500" }}
              >
                Total Sets
              </label>
            </div>
          </div>

          <div className="col-md-2">
            <div id="d1" style={{ position: "relative", top: "-5px" }}>
              <input
                className="form-control"
                id="easy-mcq"
                name="questionCount"
                // placeholder=""
                pattern="[0-9]*"
                value={
                  this.state.data.questionCount
                    ? this.state.data.questionCount
                    : ""
                }
                onChange={this.handleInputChange}
                style={{
                  textAlign: "center",
                }}
                disabled={quesCount === 0}
              ></input>
            </div>
            <div id="d7" style={{ paddingTop: "18px" }}>
              <input
                className="form-control"
                id="total-sets"
                name="totalsets"
                // placeholder=""
                pattern="[0-9]*"
                value={
                  this.state.data.totalsets ? this.state.data.totalsets : ""
                }
                onChange={this.handleInputChange}
                style={{
                  textAlign: "center",
                }}
              ></input>
            </div>
          </div>
        </div>

        {this.state.maxQueLimit > 0 && (
          <span className="subset-que_maxlimit">
            You cannot add more than {this.state.maxQueLimit} questions to{" "}
            {this.state.category}.
          </span>
        )}
        {this.state.showLoader ? (
          <div className="spinner-border mt-3" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          ""
        )}
        <div
          style={{
            padding: "30px 0 10px",
          }}
        >
          <button
            onClick={this.handleSubmit}
            type="button"
            role="button"
            className="preview--paper--btn"
          >
            Generate Now
          </button>
        </div>

        <p className="subset-note" style={{ color: "#999898" }}>
          Note: If you need specific questions in your final set, choose the
          preview option to include or exclude your questions.
        </p>
      </SweetAlert>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(QuestionPaperSetsExpertise);
// export default QuestionPaperSetsStandard;
