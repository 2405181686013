import React, { Component } from "react";
import { connect } from "react-redux";
import { addQuestion } from "../../services";
import { startLoader, stopLoader } from "../../store/reducers/generalActions";
import { notifyError, notifySuccess } from "../../utils/Helper";
import _ from "lodash";

class AddDescriptive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newquestions: {
        question: [],
        options: [""],
        category_type: 5,
        content_id: props.questions[0].content_id,
        topic: props.questions[0].topic,
      },
      titleofQuestion_emsg: "",
      option_emsg: "",
      selectedOption: null,
      optionIndex: null,
      isEmptyIndex: null,
      isOptionEmpty: null,
    };
  }

  validatemainquestion = () => {
    var que = this.state.newquestions.question;
    if (que.length == 0) {
      this.setState({
        titleofQuestion_emsg: "Please enter the question.",
      });
      return false;
    }

    if (que.trim() == "") {
      this.setState({
        titleofQuestion_emsg: "Please enter the question.",
      });
      return false;
    }

    if (!que.endsWith("?")) {
      this.setState({
        titleofQuestion_emsg: "Please put ? in the end.",
      });
      return false;
    }
    if (que.length == 1) {
      this.setState({
        titleofQuestion_emsg: "Please write an actual question.",
      });
      return false;
    }

    this.setState({
      titleofQuestion_emsg: "",
    });

    return true;
  };

  validateAnswer = () => {
    var opt = this.state.newquestions.options;
    var option = opt.map((val) => val.replace("*", ""));
    if (option.length == 0) {
      this.setState({
        isOptionEmpty: 0,
        option_emsg: "Please Enter the answer.",
      });
      return false;
    }

    for (var i = 0; i < option.length; i++) {
      if (option[i].trim() == "") {
        this.setState({
          isOptionEmpty: i,
          option_emsg: "Please Enter the answer.",
        });
        return false;
      }
    }
    this.setState({
      option_emsg: "",
    });

    return true;
  };

  add = (event) => {
    this.setState({
      titleofQuestion_emsg: "",
    });

    var newquestion = this.state.newquestions;
    newquestion.question = event.target.value;
    this.setState({
      newquestions: newquestion,
    });
  };

  option = (event) => {
    this.setState({
      isOptionEmpty: null,
      option_emsg: "",
    });

    var newquestion = this.state.newquestions;
    newquestion.options[0] = event.target.value;
    this.setState({
      newquestions: newquestion,
    });
  };

  handlesubmit = () => {
    // this.props.handlestate(this.state.newquestions);
    if (!this.validatemainquestion() || !this.validateAnswer()) {
      return false;
    }
    const data = this.state;
    var reqObj = _.cloneDeep(this.state);
    reqObj.newquestions.question = ["Ques  : " + data.newquestions.question];
    this.doSubmit(reqObj);
  };

  doSubmit = async (req) => {
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await addQuestion(req);
      if (res.success) {
        notifySuccess(res.message);
        const allQuestions = res.response;
        this.props.parentCallback(allQuestions);
        this.discard();
      } else {
        notifyError(res.message);
      }
      this.props.dispatch(stopLoader());
    } catch (err) {
      console.log(err);
    }
  };

  discard = () => {
    this.props.discardquestion("descriptive");
  };
  render() {
    return (
      <React.Fragment>
        <div className="each-question-wrapper-box">
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee">Q.</div>
            <input
              className={
                !this.state.titleofQuestion_emsg == ""
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              value={this.state.newquestions.question}
              onChange={this.add}
              placeholder="Write your Question here..."
            />
          </div>

          {this.state.titleofQuestion_emsg && (
            <span className="text-danger" style={{ marginLeft: "3em" }}>
              {this.state.titleofQuestion_emsg}
            </span>
          )}

          <div className="editable--questions">
            <div className="quetsion--edit--markquee">Ans:</div>
            <input
              className={
                this.state.isOptionEmpty == 0
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              value={this.state.newquestions.options}
              onChange={this.option}
              placeholder="Answer"
            />
            <span className="design"></span>
          </div>

          {this.state.option_emsg && (
            <span className="text-danger" style={{ marginLeft: "3em" }}>
              {this.state.option_emsg}
            </span>
          )}

          <div className="editable-question-submitbox">
            <button
              type="button"
              role="button"
              className="preview--paper--btn font--400 font--14 w-110"
              onClick={this.handlesubmit}
            >
              Add
            </button>
            <button
              role="button"
              type="putton"
              className="preview--button font--400 font--14 w-110 ml-2"
              onClick={this.discard}
            >
              Discard
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(AddDescriptive);
