import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { scheduleCallStatus } from "./../services";

class Footer extends Component {
  state = {
    showScheduleBar: false,
  };

  async componentDidMount() {
    try {
      const { data: res } = await scheduleCallStatus();
      if (res.success == true) {
        if (res.response.schedule_call == 0) {
          this.setState({
            showScheduleBar: !this.state.showScheduleBar,
          });
        }
      } else {
        return;
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleClick = async (e) => {
    e.preventDefault();

    // console.log("e.target.name", e.target.name);
    if (e.target.name == "redirect") {
      window.open(
        `https://calendly.com/prepai/demo-brief-for-prepai?name=${this.props.user.name}&email=${this.props.user.email}`
      );
    }

    this.setState({
      showScheduleBar: !this.state.showScheduleBar,
    });

    try {
      await scheduleCallStatus(false);
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showScheduleBar && this.props.user.email && (
          <div id="footer-liveplan" className="d-none d-lg-block">
            {" "}
            <div id="footer-liveplan-wrapper">
              {" "}
              <div id="footer-liveplan-main">
                {" "}
                <span
                  id="footer-liveplan-hide-footer"
                  onClick={this.handleClick}
                >
                  ╳
                </span>{" "}
                <div>
                  {" "}
                  <aside id="footer-liveplan-copy">
                    Want to get the most out of PrepAI?
                  </aside>{" "}
                  <a
                    name="redirect"
                    className="w-btn us-btn-style_3 ush_btn_1 bookdemobtn"
                    onClick={this.handleClick}
                  >
                    Schedule a call
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default withRouter(connect(mapStateToProps)(Footer));
