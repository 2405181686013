import React from "react";
import style from "../cssmodule/bannner.module.css";
import { Link } from "react-router-dom/cjs/react-router-dom";
const BannerQuiz = () => {
  return (
    <div className={style.design}>
      {/* <Link className={style.design} to="/quiz-multiplier"> */}
      <div className={style.banner}>
        <img
          src="/img/ala.png"
          alt="added"
          height="100%"
          width="65px"
          style={{
            marginLeft: "40px",
          }}
        />
        <div className={style.txt}>Get Ready for Smarter SAT Prep!</div>
        <div style={{ overflow: "hidden" }}>
          <img
            src="/img/soon.png"
            alt="added"
            // width="100%"
            // height="170px"
            style={{ height: "150px", marginBottom: "-10px" }}

            // style={{ objectFit: "contain" }}
          />
        </div>
      </div>
      {/* </Link> */}
    </div>
  );
};

export default BannerQuiz;
