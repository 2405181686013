import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getTestUser } from "./../../services";
import MSG, { notifySuccess, notifyError } from "./../../utils/Helper";

class Header extends Component {
  state = {
    userName: "",
  };

  componentDidMount = async () => {
    try {
      const { data: res } = await getTestUser();
      if (res.success == true) {
        this.setState({
          userName: res.response.user_name,
        });
      }
    } catch (err) {
      notifyError(MSG.commonApiError);
    }
  };

  render() {
    return (
      <React.Fragment>
        {/*<Tooltip title="Coming Soon" id="global" /> */}
        <div className="survey-custom-navbar">
          <nav className="navbar navbar-expand-md">
            <div className="container">
              <div className="style-logo">
                <Link className="navbar-brand">
                  <img
                    src={`${process.env.REACT_APP_PROJECT_URL}/img/prepAI-new-logo.svg`}
                    alt="prepAi-logo"
                    style={{ width: "120px" }}
                  />
                </Link>
              </div>

              <div
                className="collapse navbar-collapse custom-navlinks"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{ marginTop: "10px" }}
                    >
                      <span className="dinline-block mr-2">
                        <svg
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.4118 5.07588C11.4118 7.52341 9.44941 9.48593 7.00016 9.48593C4.55174 9.48593 2.5885 7.52341 2.5885 5.07588C2.5885 2.62836 4.55174 0.666672 7.00016 0.666672C9.44941 0.666672 11.4118 2.62836 11.4118 5.07588ZM7.00016 17.3333C3.38547 17.3333 0.333496 16.7458 0.333496 14.4791C0.333496 12.2116 3.40465 11.6449 7.00016 11.6449C10.6157 11.6449 13.6668 12.2325 13.6668 14.4991C13.6668 16.7667 10.5957 17.3333 7.00016 17.3333Z"
                            fill="#526892"
                          />
                        </svg>
                      </span>
                      {this.state.userName}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notificationBarShow: state.generalActions.notificationBarShow,
    user: state.auth.user,
  };
};
export default withRouter(connect(mapStateToProps)(Header));
