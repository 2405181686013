import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Component } from "react";
import configData from "../../config.json";
import { connect } from "react-redux";
import { startLoader, stopLoader } from "../../store/reducers/generalActions";
import { referUser } from "../../services";
import MSG, { notifySuccess, notifyError } from "./../../utils/Helper";
import { closeReferral } from "../../store/reducers/generalActions";

class ReferPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      referralEmail: "",
      referralMessage: "",
      referralLink: "",
      helperText: "",
    };
  }

  handleClose = () => {
    this.setState({ helperText: "" });
    this.props.dispatch(closeReferral());
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.referralEmail) {
      notifyError("Please enter an email to submit");
      return false;
    }
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!regex.test(this.state.referralEmail)) {
      notifyError("Please enter a valid email address");
      return false;
    }

    if (this.props.user.email === this.state.referralEmail) {
      notifyError("You cannot refer yourself.");
      return false;
    }
    const data = {
      referralEmail: this.state.referralEmail,
      referralMessage: this.state.referralMessage,
      referralLink: `${process.env.REACT_APP_PROJECT_URL}/register/?referral=${this.props.user.referral_code}`,
    };
    this.doSubmit(data);
  };

  doSubmit = async (req) => {
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await referUser(req);
      if (res.success == true) {
        notifySuccess(res.message);
        this.props.dispatch(closeReferral());
      } else {
        notifyError(res.message);
      }
      this.props.dispatch(stopLoader());
    } catch (err) {
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
      this.props.dispatch(stopLoader());
    }

    this.setState({
      referralEmail: "",
      referralMessage: "",
    });
  };

  copyLinkToClipboard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(
      this.props.user.referral_code
        ? `${process.env.REACT_APP_PROJECT_URL}/register/?referral=${this.props.user.referral_code}`
        : ""
    );
    this.setState({ helperText: "Share the link with your friends" });
    notifySuccess("Link Copied");
  };

  render() {
    return (
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            fontFamily: "Poppins",
          },

          h2: {
            textAlign: "center",
            fontSize: "1.5rem",
            fontWeight: "700",
          },
        }}
        open={this.props.showPopup}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle> {this.props.referContent?.title} </DialogTitle>

        <DialogContent
          sx={{
            margin: "0.5rem 3rem",
            padding: 0,
            fontWeight: "500",
          }}
        >
          <span className="line"></span>
          <p> {this.props.referContent?.content} </p>
          <span className="close__referral">
            <IconButton style={{ outline: "none" }} onClick={this.handleClose}>
              {" "}
              <Close />{" "}
            </IconButton>
          </span>

          <div className="referral__content">
            <div className="content__email rounded">
              <input
                type="email"
                placeholder="Friend's email address"
                value={this.state.referralEmail}
                onChange={(e) =>
                  this.setState({ referralEmail: e.target.value })
                }
              />
              <button className="rounded" onClick={this.handleSubmit}>
                Invite
              </button>
            </div>

            <button
              onClick={this.copyLinkToClipboard}
              className="referral__invite shadow rounded"
            >
              Copy Invite Link
            </button>
          </div>
        </DialogContent>

        <DialogActions>
          <span className="helper"> {this.state.helperText} </span>
          <p className="close__link" onClick={this.handleClose}>
            {" "}
            No, I don't have any friends{" "}
          </p>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showPopup: state.generalActions.showPopUp,
    user: state.auth.user,
    referContent: state.generalActions.referContent,
  };
};

export default connect(mapStateToProps)(ReferPopup);
