import React from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { createSetsBloom } from "../../services";
import { startLoader, stopLoader } from "../../store/reducers/generalActions";
import MSG, { notifySuccess, notifyError } from "../../utils/Helper";

import classNames from "classnames";
import styles from "../../styles/popupResponsive.module.css";

class QuestionPaperSetsBloom extends React.Component {
  state = {
    show: false,
    showLoader: false,
    data: {
      topic: "",
      content_id: null,
      knowledge: 0,
      comprehension: 0,
      application: 0,
      analysis: 0,
      evaluation: 0,
      synthesis: 0,
      totalsets: 0,
    },
    maxQueLimit: 0,
    category: "",
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      show: nextProps.data.show,
      data: {
        ...this.state.data,
        content_id: nextProps.data.content_id,
      },
    });
  }

  handleInputChange = (event) => {
    event.preventDefault();
    const target = event.target;
    const value = target.value ? parseInt(target.value) : 0;

    const name = target.name;

    this.setState({
      maxQueLimit: 0,
      category: "",
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  handleSubmit = async () => {
    const { data } = this.state;

    if (!data.topic) {
      notifyError("Enter topic");
      return;
    }

    if (
      !data.knowledge &&
      !data.comprehension &&
      !data.application &&
      !data.analysis &&
      !data.evaluation &&
      !data.synthesis
    ) {
      notifyError("Enter number of questions in at least one category.");
      return;
    }

    let queCount = this.props.data.que_count;

    if (data.knowledge > queCount.easyCount) {
      this.setState({
        maxQueLimit: queCount.easyCount,
        category: "Knowledge",
      });
      return;
    } else if (data.comprehension > queCount.mediumCount) {
      this.setState({
        maxQueLimit: queCount.mediumCount,
        category: "Comprehension",
      });
      return;
    } else if (data.application > queCount.hardCount) {
      this.setState({
        maxQueLimit: queCount.hardCount,
        category: "Application",
      });
      return;
    } else if (data.analysis > queCount.trueFalseCount) {
      this.setState({
        maxQueLimit: queCount.trueFalseCount,
        category: "Analysis",
      });
      return;
    } else if (data.evaluation > queCount.descriptiveCount) {
      this.setState({
        maxQueLimit: queCount.descriptiveCount,
        category: "Evaluation",
      });
      return;
    } else if (data.synthesis > queCount.fillUpsCount) {
      this.setState({
        maxQueLimit: queCount.fillUpsCount,
        category: "Synthesis",
      });
      return;
    }

    if (!data.totalsets) {
      notifyError("Enter total sets");
      return;
    }

    this.setState({
      showLoader: true,
    });

    try {
      const { data: res } = await createSetsBloom(data);
      if (res.success == true) {
        notifySuccess(res.message);
        this.setState({
          show: false,
          data: {
            ...this.state,
            topic: "",
            knowledge: 0,
            comprehension: 0,
            application: 0,
            analysis: 0,
            evaluation: 0,
            synthesis: 0,
            totalsets: 0,
          },
        });
        this.props.callBack({
          showPopUpBloom: this.state.show,
          isSuccess: true,
        });
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      notifyError(MSG.commonApiError);
    }
    this.setState({
      showLoader: false,
    });
  };

  handleClose = () => {
    this.setState({ show: false }, () => {
      this.props.callBack({ showPopUp: this.state.show, isSuccess: false });
    });
  };

  render() {
    const quesCount = this.props.data.que_count;
    return (
      <SweetAlert
        show={this.state.show}
        showConfirm={false}
        style={{ zIndex: 99999999 }}
        title={
          <>
            <div className="swal-titile">
              <h4 style={{ marginTop: "0.65rem" }}>
                <span>Create Secondary Question Paper</span>
                <br />
              </h4>
              <span className={classNames(styles["parah"], "subset-sub--text")}>
                Choose the number of questions to be added to your set in each
                category
              </span>
            </div>
          </>
        }
        // onEscapeKey={this.handleClose}
        // onOutsideClick={this.handleSubmit}
        // onCancel={this.handleClose}
      >
        <button
          type="button"
          className="close sets-standard-close"
          aria-label="Close"
          style={{ outline: "none" }}
          onClick={this.handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <div className="position-relative" style={{ top: "-27px" }}>
          <input
            className={classNames(styles["topic"], "subset-topic")}
            type="text"
            name="topic"
            value={this.state.data.topic}
            onChange={(e) =>
              this.setState({
                data: { ...this.state.data, topic: e.target.value },
              })
            }
            id="titleofTest"
            aria-describedby="textHelp"
            placeholder="Enter Topic"
          />
        </div>

        <div className={classNames(styles["form-container"], "set-container")}>
          <div className="col-md-4">
            <div className="form-group">
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"],`form-label ${
                  quesCount.easyCount === 0 && "disabled-set"
                }`)}
                style={{ fontWeight: "400" }}
              >
                Remember ({quesCount.easyCount})
              </label>
            </div>
            <div className="form-group">
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"],`form-label ${
                  quesCount.mediumCount === 0 && "disabled-set"
                }`)}
                style={{ fontWeight: "400" }}
              >
                Understand ({quesCount.mediumCount})
              </label>
            </div>
            <div className="form-group">
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"],`form-label ${
                  quesCount.hardCount === 0 && "disabled-set"
                }`)}
                style={{ fontWeight: "400" }}
              >
                Apply ({quesCount.hardCount})
              </label>
            </div>
            <div className="form-group">
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"],`form-label ${
                  quesCount.trueFalseCount === 0 && "disabled-set"
                }`)}
                style={{ fontWeight: "400" }}
              >
                Analyze ({quesCount.trueFalseCount})
              </label>
            </div>
            <div className="form-group">
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"],`form-label ${
                  quesCount.descriptiveCount === 0 && "disabled-set"
                }`)}
                style={{ fontWeight: "400" }}
              >
                Evaluate ({quesCount.descriptiveCount})
              </label>
            </div>
            <div className="form-group">
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"],`form-label ${
                  quesCount.fillUpsCount === 0 && "disabled-set"
                }`)}
                style={{ fontWeight: "400" }}
              >
                Create ({quesCount.fillUpsCount})
              </label>
            </div>

            <div className="form-group">
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"],`form-label`)}
                style={{ fontWeight: "500" }}
              >
                Total Sets
              </label>
            </div>
          </div>

          <div className="col-md-2">
            <div
              id="d1"
              className="set-input-container"
              style={{ position: "relative", top: "-5px", paddingTop: 0 }}
            >
              <input
                className="form-control set-input"
                id="knowledge"
                name="knowledge"
                style={{width:'100%'}}
                // placeholder=""
                pattern="[0-9]*"
                value={
                  this.state.data.knowledge ? this.state.data.knowledge : ""
                }
                onChange={this.handleInputChange}
                disabled={quesCount.easyCount === 0}
              ></input>
              {this.state.category === "Knowledge" &&
                this.state.maxQueLimit > 0 && (
                  <span className="subset-que_maxlimit">
                    max limit is {this.state.maxQueLimit}
                  </span>
                )}
            </div>
            <div
              id="d2"
              className="set-input-container"
              style={{ paddingTop: "14px" }}
            >
              <input
                className="form-control set-input"
                id="comprehension"
                name="comprehension"
                style={{width:'100%'}}
                // placeholder=""
                pattern="[0-9]*"
                value={
                  this.state.data.comprehension
                    ? this.state.data.comprehension
                    : ""
                }
                onChange={this.handleInputChange}
                disabled={quesCount.mediumCount === 0}
              ></input>
              {this.state.category === "Comprehension" &&
                this.state.maxQueLimit > 0 && (
                  <span className="subset-que_maxlimit">
                    max limit is {this.state.maxQueLimit}
                  </span>
                )}
            </div>
            <div
              id="d3"
              className="set-input-container"
              style={{ paddingTop: "18px" }}
            >
              <input
                className="form-control set-input"
                id="application"
                name="application"
                style={{width:'100%'}}
                // placeholder=""
                pattern="[0-9]*"
                value={
                  this.state.data.application ? this.state.data.application : ""
                }
                onChange={this.handleInputChange}
                disabled={quesCount.hardCount === 0}
              ></input>
              {this.state.category === "Application" &&
                this.state.maxQueLimit > 0 && (
                  <span className="subset-que_maxlimit">
                    max limit is {this.state.maxQueLimit}
                  </span>
                )}
            </div>
            <div
              id="d4"
              className="set-input-container"
              style={{ paddingTop: "18px" }}
            >
              <input
                className="form-control set-input"
                id="analysis"
                name="analysis"
                style={{width:'100%'}}
                // placeholder=""
                pattern="[0-9]*"
                value={this.state.data.analysis ? this.state.data.analysis : ""}
                onChange={this.handleInputChange}
                disabled={quesCount.trueFalseCount === 0}
              ></input>
              {this.state.category === "Analysis" &&
                this.state.maxQueLimit > 0 && (
                  <span className="subset-que_maxlimit">
                    max limit is {this.state.maxQueLimit}
                  </span>
                )}
            </div>
            <div
              id="d5"
              className="set-input-container"
              style={{ paddingTop: "18px" }}
            >
              <input
                className="form-control set-input"
                id="evaluation"
                name="evaluation"
                style={{width:'100%'}}
                // placeholder=""
                pattern="[0-9]*"
                value={
                  this.state.data.evaluation ? this.state.data.evaluation : ""
                }
                onChange={this.handleInputChange}
                disabled={quesCount.descriptiveCount === 0}
              ></input>
              {this.state.category === "Evaluation" &&
                this.state.maxQueLimit > 0 && (
                  <span className="subset-que_maxlimit">
                    max limit is {this.state.maxQueLimit}
                  </span>
                )}
            </div>
            <div
              id="d6"
              className="set-input-container"
              style={{ paddingTop: "18px" }}
            >
              <input
                className="form-control set-input"
                id="synthesis"
                name="synthesis"
                style={{width:'100%'}}
                // placeholder=""
                pattern="[0-9]*"
                value={
                  this.state.data.synthesis ? this.state.data.synthesis : ""
                }
                onChange={this.handleInputChange}
                disabled={quesCount.fillUpsCount === 0}
              ></input>
              {this.state.category === "Synthesis" &&
                this.state.maxQueLimit > 0 && (
                  <span className="subset-que_maxlimit">
                    max limit is {this.state.maxQueLimit}
                  </span>
                )}
            </div>
            <div
              id="d7"
              className="set-input-container"
              style={{ paddingTop: "18px" }}
            >
              <input
                className="form-control set-input"
                id="total-sets"
                name="totalsets"
                // placeholder=""
                style={{
                  border: "2.4px solid black",
                  width:'100%'
                }}
                pattern="[0-9]*"
                value={
                  this.state.data.totalsets ? this.state.data.totalsets : ""
                }
                onChange={this.handleInputChange}
              ></input>
            </div>
          </div>
        </div>
        {this.state.showLoader ? (
          <div className="spinner-border mt-3" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          ""
        )}

        <div
          style={{
            padding: "30px 0 10px",
          }}
        >
          <button
            onClick={this.handleSubmit}
            type="button"
            role="button"
            className="preview--paper--btn"
          >
            Generate Now
          </button>
        </div>

        <p className="subset-note" style={{ color: "#999898" }}>
          Note: If you need specific questions in your final set, choose the
          preview option to include or exclude your questions.
        </p>
      </SweetAlert>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(QuestionPaperSetsBloom);
