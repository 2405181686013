import React from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { createSets } from "./../../services";
import MSG, { notifySuccess, notifyError } from "./../../utils/Helper";
import _ from "lodash";

import classNames from "classnames";
import styles from "../../styles/popupResponsive.module.css";

class QuestionPaperSetsStandard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.data.show,
      showLoader: false,
      data: {
        topic: "",
        content_id: props.data.content_id,
        easymcq: 0,
        mediummcq: 0,
        hardmcq: 0,
        truefalse: 0,
        descriptive: 0,
        fillups: 0,
        totalsets: 0,
      },
      maxQueLimit: 0,
      category: "",
    };
  }

  // componentWillReceiveProps(nextProps) {
  //   // console.log("nextProps", nextProps);

  //   this.setState({
  //     show: nextProps.data.show,
  //     data: {
  //       ...this.state.data,
  //       content_id: nextProps.data.content_id,
  //     },
  //   });
  // }

  handleInputChange = (event) => {
    event.preventDefault();
    const target = event.target;
    const value = target.value ? parseInt(target.value) : 0;
    const name = target.name;

    this.setState({
      maxQueLimit: 0,
      category: "",
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  handleSubmit = async () => {
    const { data } = this.state;

    if (!data.topic) {
      notifyError("Enter topic");
      return;
    }

    if (
      !data.easymcq &&
      !data.mediummcq &&
      !data.hardmcq &&
      !data.truefalse &&
      !data.descriptive &&
      !data.fillups
    ) {
      notifyError("Enter number of questions in at least one category.");
      return;
    }

    let queCount = this.props.data.que_count;

    if (data.easymcq > queCount.easyCount) {
      this.setState({
        maxQueLimit: queCount.easyCount,
        category: "Easy MCQ",
      });
      return;
    } else if (data.mediummcq > queCount.mediumCount) {
      this.setState({
        maxQueLimit: queCount.mediumCount,
        category: "Medium MCQ",
      });
      return;
    } else if (data.hardmcq > queCount.hardCount) {
      this.setState({
        maxQueLimit: queCount.hardCount,
        category: "Hard MCQ",
      });
      return;
    } else if (data.truefalse > queCount.trueFalseCount) {
      this.setState({
        maxQueLimit: queCount.trueFalseCount,
        category: "True/False",
      });
      return;
    } else if (data.descriptive > queCount.descriptiveCount) {
      this.setState({
        maxQueLimit: queCount.descriptiveCount,
        category: "Descriptive",
      });
      return;
    } else if (data.fillups > queCount.fillUpsCount) {
      this.setState({
        maxQueLimit: queCount.fillUpsCount,
        category: "Fill Ups",
      });
      return;
    }

    if (!data.totalsets) {
      notifyError("Enter total sets");
      return;
    }

    this.setState({
      showLoader: true,
    });

    try {
      const { data: res } = await createSets(data);
      if (res.success == true) {
        notifySuccess(res.message);
        this.setState({
          show: false,
          data: {
            ...this.state,
            topic: "",
            knowledge: 0,
            comprehension: 0,
            application: 0,
            analysis: 0,
            evaluation: 0,
            synthesis: 0,
            totalsets: 0,
          },
        });
        this.props.callBack({
          showPopUpBloom: this.state.show,
          isSuccess: true,
        });
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      notifyError(MSG.commonApiError);
    }
    this.setState({
      showLoader: false,
    });
  };

  handleClose = () => {
    this.setState({ show: false }, () => {
      this.props.callBack({ showPopUp: this.state.show, isSuccess: false });
    });
  };

  render() {
    const quesCount = this.props.data.que_count;
    return (
      <SweetAlert
        show={this.state.show}
        showConfirm={false}
        style={{ zIndex: 99999999 }}
        title={
          <>
            <div className="swal-titile">
              <h4 style={{ marginTop: "0.65rem" }}>
                <span>Create Secondary Question Paper</span>
                <br />
              </h4>
              <span className={classNames(styles["parah"], "subset-sub--text")}>
                Choose the number of questions to be added to your set in each
                category
              </span>
            </div>
          </>
        }
        // onEscapeKey={this.handleClose}
        // onOutsideClick={this.handleSubmit}
        // onCancel={this.handleClose}
      >
        <button
          type="button"
          className="close sets-standard-close"
          aria-label="Close"
          style={{ outline: "none" }}
          onClick={this.handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <div className="position-relative" style={{ top: "-27px" }}>
          <input
            className={classNames(styles["topic"], "subset-topic")}
            type="text"
            name="topic"
            value={this.state.data.topic}
            onChange={(e) =>
              this.setState({
                data: { ...this.state.data, topic: e.target.value },
              })
            }
            id="titleofTest"
            aria-describedby="textHelp"
            placeholder="Enter Topic"
          />
        </div>

        <div className={classNames(styles["form-container"], "set-container")}>
          <div className="col-md-4">
            <div className="form-group">
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"], `form-label ${
                  quesCount.easyCount === 0 && "disabled-set"
                }`)}
                style={{ fontWeight: "400" }}
              >
                Easy MCQ ({quesCount.easyCount})
              </label>
            </div>
            <div className="form-group">
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"],`form-label ${
                  quesCount.mediumCount === 0 && "disabled-set"
                }`)}
                style={{ fontWeight: "400" }}
              >
                Medium MCQ ({quesCount.mediumCount})
              </label>
            </div>
            <div className="form-group">
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"],`form-label ${
                  quesCount.hardCount === 0 && "disabled-set"
                }`)}
                style={{ fontWeight: "400" }}
              >
                Hard MCQ ({quesCount.hardCount})
              </label>
            </div>
            <div className="form-group">
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"],`form-label ${
                  quesCount.trueFalseCount === 0 && "disabled-set"
                }`)}
                style={{ fontWeight: "400" }}
              >
                True/False ({quesCount.trueFalseCount})
              </label>
            </div>
            <div className="form-group">
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"],`form-label ${
                  quesCount.descriptiveCount === 0 && "disabled-set"
                }`)}
                style={{ fontWeight: "400" }}
              >
                Descriptive ({quesCount.descriptiveCount})
              </label>
            </div>
            <div className="form-group" style={{ fontWeight: "400" }}>
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"],`form-label ${
                  quesCount.fillUpsCount === 0 && "disabled-set"
                }`)}
              >
                Fill Ups ({quesCount.fillUpsCount})
              </label>
            </div>

            <div className="form-group">
              <label
                htmlFor="input2"
                className={classNames(styles["item-font"],`form-label`)}
                style={{ fontWeight: "500" }}
              >
                Total Sets
              </label>
            </div>
          </div>

          <div className="col-md-2">
            <div
              id="d1"
              className="set-input-container"
              style={{ position: "relative", top: "-5px", paddingTop: 0 }}
            >
              <input
                className="form-control set-input"
                id="easy-mcq"
                name="easymcq"
                style={{width:'100%'}}
                // placeholder=""
                pattern="[0-9]*"
                value={this.state.data.easymcq ? this.state.data.easymcq : ""}
                onChange={this.handleInputChange}
                disabled={quesCount.easyCount === 0}
              ></input>
              {this.state.category === "Easy MCQ" &&
                this.state.maxQueLimit > 0 && (
                  <span className="subset-que_maxlimit">
                    max limit is {this.state.maxQueLimit}
                  </span>
                )}
            </div>

            <div
              id="d2"
              className="set-input-container"
              style={{ paddingTop: "14px" }}
            >
              <input
                className="form-control set-input"
                id="medium-mcq"
                name="mediummcq"
                style={{width:'100%'}}
                // placeholder=""
                pattern="[0-9]*"
                value={
                  this.state.data.mediummcq ? this.state.data.mediummcq : ""
                }
                onChange={this.handleInputChange}
                disabled={quesCount.mediumCount === 0}
              ></input>
              {this.state.category === "Medium MCQ" &&
                this.state.maxQueLimit > 0 && (
                  <span className="subset-que_maxlimit">
                    max limit is {this.state.maxQueLimit}
                  </span>
                )}
            </div>
            <div id="d3" className="set-input-container">
              <input
                className="form-control set-input"
                id="hard-mcq"
                name="hardmcq"
                style={{width:'100%'}}
                // placeholder=""
                pattern="[0-9]*"
                value={this.state.data.hardmcq ? this.state.data.hardmcq : ""}
                onChange={this.handleInputChange}
                disabled={quesCount.hardCount === 0}
              ></input>
              {this.state.category === "Hard MCQ" &&
                this.state.maxQueLimit > 0 && (
                  <span className="subset-que_maxlimit">
                    max limit is {this.state.maxQueLimit}
                  </span>
                )}
            </div>
            <div id="d4" className="set-input-container">
              <input
                className="form-control set-input"
                id="true-flase"
                name="truefalse"
                style={{width:'100%'}}
                pattern="[0-9]*"
                value={
                  this.state.data.truefalse ? this.state.data.truefalse : ""
                }
                onChange={this.handleInputChange}
                disabled={quesCount.trueFalseCount === 0}
              ></input>
              {this.state.category === "True/False" &&
                this.state.maxQueLimit > 0 && (
                  <span className="subset-que_maxlimit">
                    max limit is {this.state.maxQueLimit}
                  </span>
                )}
            </div>
            <div id="d5" className="set-input-container">
              <input
                className="form-control set-input"
                id="descriptive"
                name="descriptive"
                style={{width:'100%'}}
                pattern="[0-9]*"
                value={
                  this.state.data.descriptive ? this.state.data.descriptive : ""
                }
                onChange={this.handleInputChange}
                disabled={quesCount.descriptiveCount === 0}
              ></input>
              {this.state.category === "Descriptive" &&
                this.state.maxQueLimit > 0 && (
                  <span className="subset-que_maxlimit">
                    max limit is {this.state.maxQueLimit}
                  </span>
                )}
            </div>
            <div id="d6" className="set-input-container">
              <input
                className="form-control set-input"
                id="fill-ups"
                name="fillups"
                style={{width:'100%'}}
                pattern="[0-9]*"
                value={this.state.data.fillups ? this.state.data.fillups : ""}
                onChange={this.handleInputChange}
                disabled={quesCount.fillUpsCount === 0}
              ></input>
              {this.state.category === "Fill Ups" &&
                this.state.maxQueLimit > 0 && (
                  <span className="subset-que_maxlimit">
                    max limit is {this.state.maxQueLimit}
                  </span>
                )}
            </div>
            <div id="d7" className="set-input-container">
              <input
                className="form-control set-input"
                id="total-sets"
                name="totalsets"
                // placeholder=""
                style={{
                  border: "2.4px solid black",
                  width:'100%'
                }}
                pattern="[0-9]*"
                value={
                  this.state.data.totalsets ? this.state.data.totalsets : ""
                }
                onChange={this.handleInputChange}
              ></input>
            </div>
          </div>
        </div>

        {this.state.showLoader ? (
          <div className="spinner-border mt-3" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          ""
        )}
        <div
          style={{
            padding: "30px 0 10px",
          }}
        >
          <button
            onClick={this.handleSubmit}
            type="button"
            role="button"
            className="preview--paper--btn"
          >
            Generate Now
          </button>
        </div>

        <p className="subset-note" style={{ color: "#999898" }}>
          Note: If you need specific questions in your final set, choose the
          preview option to include or exclude your questions.
        </p>
      </SweetAlert>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(QuestionPaperSetsStandard);
// export default QuestionPaperSetsStandard;
