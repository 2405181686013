import React, { Component } from "react";
import {
  CircularProgressbar,
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import { addProcessToBackground } from "../../services";
import RadialSeparators from "./radialSeparators";
import { fontSize } from "@mui/system";

class ProgressBar extends Component {
  updateToBackground = async () => {
    clearInterval(this.intervalId);
    try {
      const { data: res } = await addProcessToBackground({
        content_id: this.props.content_id,
      });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <div className="app progress-bar-position" style={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        gap:'4px',
        marginRight:'14px'
      }}>
        {/* <div style={{ width: 150, height: 150 }}>
          <CircularProgressbar
            value={this.props.progress === null ? 0 : this.props.progress}
            text={`${this.props.progress === null ? 0 : this.props.progress}%`}
            strokeWidth={10}
            styles={buildStyles({
              strokeLinecap: "butt",
            })}
          />
          ;
        </div> */}
        <div
          style={{
            width: 150,
            height: 150,
            fontSize: "25px",
          }}
        >
          <CircularProgressbarWithChildren
            value={this.props.progress === null ? 0 : this.props.progress}
            // text={`${this.props.progress === null ? 0 : this.props.progress}%`}
            strokeWidth={10}
            styles={buildStyles({
              strokeLinecap: "butt",
              textColor: "#49c0a0",
              dominantBaseline: "central",
              pathColor: "#49c0a0",
            })}
          >
            <span style={{ color: "#49c0a0" }}>{this.props.progress}%</span>
            <RadialSeparators
              count={10}
              style={{
                background: "#fff",
                width: "2px",
                height: `${10}%`,
              }}
            />
          </CircularProgressbarWithChildren>
        </div>
        {this.props.content_id !== null && (
          <>
            <span
              style={{ position: "relative", top: "5px" }}
            >
              Working our Magic <img src="/img/magic.png" style={{ width: "20px" }} alt="✨" />
            </span>
            <div style={{ marginTop: "4px" }}>
              <Link
                type="button"
                className="btn--upbar"
                to="/question-paper"
                onClick={this.updateToBackground}
              >
                Run in background
              </Link>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ProgressBar;
