import React from "react";
import { useState } from "react";
import classes from "./TimeInput.module.css";

const TimeInput = (props) => {
  const [value, setValue] = useState("0:00");
  const [isValid, setIsValid] = useState(true);

  const onChange = (event) => {
    const value = event.target.value;
    const regEx = /^[0-9:]+$/;
    if (value) {
      props.startTyping();
      if (regEx.test(value)) {
        setValue(value);
      }
    } else {
      props.startTyping();
      setValue(value);
    }
  };

  const onBlur = (event) => {
    const value = event.target.value;
    const seconds = Math.max(0, getSecondsFromHHMMSS(value));
    props.timeStampHandler(seconds);
    const time = toHHMMSS(seconds);
    setValue(time);
  };

  const getSecondsFromHHMMSS = (value) => {
    const [str1, str2, str3] = value.split(":");

    const val1 = Number(str1);
    const val2 = Number(str2);
    const val3 = Number(str3);

    if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
      return val1;
    }

    if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
      return val1 * 60 + val2;
    }

    if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
      return val1 * 60 * 60 + val2 * 60 + val3;
    }

    return 0;
  };

  const toHHMMSS = (secs) => {
    const secNum = parseInt(secs.toString(), 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [hours, minutes, seconds]
      .map((val) => (val < 10 ? `0${val}` : val))
      .filter((val, index) => val !== "00" || index > 0)
      .join(":")
      .replace(/^0/, "");
  };

  return (
    <input
      style={props.isValid && { border: "1px solid #dc3545" }}
      className={classes.input}
      type="text"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
  );
};

export default TimeInput;
