import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { addQuestion } from "../../services";
import { startLoader, stopLoader } from "../../store/reducers/generalActions";
import { notifyError, notifySuccess } from "../../utils/Helper";

class Truefalse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newquestions: {
        question: [],
        options: ["", ""],
        category_type: 4,
        content_id: props.questions[0].content_id,
        topic: props.questions[0].topic,
      },
      titleofQuestion_emsg: "",
      option_emsg: "",
      selectedOption: null,
      optionIndex: null,
      isEmptyIndex: null,
      isOptionEmpty: null,
      explanation: "",
    };
  }

  validatemainquestion = () => {
    var que = this.state.newquestions.question;
    if (que.length == 0) {
      this.setState({
        isEmptyIndex: 0,
        titleofQuestion_emsg: "Please enter text in the input field.",
      });
      return false;
    }

    if (que.trim() == "") {
      this.setState({
        isEmptyIndex: 0,
        titleofQuestion_emsg: "Please enter text in the input field.",
      });
      return false;
    }

    this.setState({
      titleofQuestion_emsg: "",
    });

    return true;
  };

  validateoption = () => {
    var opt = this.state.newquestions.options;
    if (opt.length < 2) {
      this.setState({
        isOptionEmpty: Math.max(0, opt.length - 1),
        option_emsg: "Please Enter the option.",
      });
      return false;
    }

    for (var i = 0; i < opt.length; i++) {
      if (opt[i].trim() == "") {
        this.setState({
          isOptionEmpty: i,
          option_emsg: "Plase Enter the option.",
        });
        return false;
      }
    }
    if (!this.state.optionIndex) {
      this.setState({
        option_emsg: "Please mark the correct answer.",
      });
      return false;
    }

    return true;
  };

  radioButtonValue = (event) => {
    this.setState({
      optionIndex: event.target.name,
      selectedOption: event.target.value,
    });
  };

  add = (event) => {
    this.setState({
      titleofQuestion_emsg: "",
      isEmptyIndex: null,
    });
    var newquestion = this.state.newquestions;
    newquestion.question = event.target.value;
    this.setState({
      newquestions: newquestion,
    });
  };
  option = (event) => {
    this.setState({
      isOptionEmpty: null,
      option_emsg: "",
    });

    var newquestion = this.state.newquestions;
    var options = newquestion.options;
    var index = event.target.name;
    var val = options[index - 1];
    if (val.endsWith("*")) {
      options[index - 1] = event.target.value + " *";
    } else {
      options[index - 1] = event.target.value;
    }
    newquestion.options = options;

    this.setState({
      newquestions: newquestion,
    });
  };
  handlecheck = (event) => {
    var optionValue = this.state.newquestions.options;
    var optionValue = optionValue.map((val) => val.replace("*", ""));
    var index = event.target.name;
    optionValue[index - 1] += "*";

    var newQuestionObj = this.state.newquestions;
    newQuestionObj.options = optionValue;
    newQuestionObj.optionIndex = index;

    this.setState({
      newquestions: newQuestionObj,
    });
  };

  handleExplanation = async (event) => {
    const value = event.target.value;
    this.setState({ explanation: value });
  };

  handlesubmit = () => {
    if (!this.validatemainquestion() || !this.validateoption()) {
      return false;
    }
    var reqObj = _.cloneDeep(this.state);
    reqObj.newquestions.question = ["Ques  : " + reqObj.newquestions.question];

    if (this.state.optionIndex != null) {
      var optionObj = reqObj.newquestions.options;
      var opt = optionObj.map((val) => val.replace("*", ""));
      opt[this.state.optionIndex - 1] = opt[this.state.optionIndex - 1] + " *";
      reqObj.newquestions.options = opt;
    }
    reqObj.newquestions.explanation = this.state.explanation;
    this.doSubmit(reqObj);
  };

  doSubmit = async (req) => {
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await addQuestion(req);
      if (res.success) {
        notifySuccess(res.message);
        const allQuestions = res.response;
        this.props.parentCallback(allQuestions);
        this.discard();
      } else {
        notifyError(res.message);
      }
      this.props.dispatch(stopLoader());
    } catch (err) {
      console.log(err);
    }
  };

  discard = () => {
    this.props.discardquestion("truefalse");
  };

  render() {
    return (
      <React.Fragment>
        <div className="each-question-wrapper-box">
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee">Q.</div>
            <input
              className={
                this.state.isEmptyIndex == 0
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              value={this.state.newquestions.question}
              onChange={this.add}
              placeholder="Write your Question here..."
            />
          </div>
          {this.state.titleofQuestion_emsg && (
            <span className="text-danger" style={{ marginLeft: "3em" }}>
              {this.state.titleofQuestion_emsg}
            </span>
          )}

          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label className="mordern-radiobox-label my-0">
                <input
                  type="radio"
                  name="1"
                  onChange={(e) => this.radioButtonValue(e)}
                  checked={this.state.optionIndex == 1 ? true : false}
                />
                <span className="design"></span>
              </label>
            </div>

            <input
              className={
                this.state.isOptionEmpty == 0
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="1"
              value={this.state.newquestions.options[0]}
              onChange={this.option}
              placeholder="Option 1"
            />
          </div>
          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label className="mordern-radiobox-label my-0">
                <input
                  type="radio"
                  name="2"
                  onChange={(e) => this.radioButtonValue(e)}
                  checked={this.state.optionIndex == 2 ? true : false}
                />
                <span className="design"></span>
              </label>
            </div>

            <input
              className={
                this.state.isOptionEmpty == 1
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="2"
              value={this.state.newquestions.options[1]}
              onChange={this.option}
              placeholder="Option 2"
            />
          </div>

          {this.state.option_emsg && (
            <span className="text-danger" style={{ marginLeft: "3em" }}>
              {this.state.option_emsg}
            </span>
          )}

          <div className="editable--questions">
            <div
              style={{
                position: "relative",
                width: "94%",
                textAlign: "left",
                marginTop: "10px",
              }}
              className="quetsion--edit--markquee p-0"
            >
              <label style={{ fontSize: "15px" }}>
                Add Explanation (Optional)
              </label>
              <textarea
                name="explanation"
                onChange={this.handleExplanation}
                className="form-control"
                rows={5}
              />
            </div>
          </div>

          <div class="editable-question-submitbox">
            <button
              type="button"
              role="button"
              className="preview--paper--btn font--400 font--14 w-110"
              onClick={this.handlesubmit}
            >
              Add
            </button>
            <button
              role="button"
              type="putton"
              className="preview--button font--400 font--14 w-110 ml-2"
              onClick={this.discard}
            >
              Discard
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Truefalse);
