const Loader = () => {
  return (
    <>
      <div class="loader-preview"></div>
      <style>
        {`
            .loader-preview {
              border: 4px solid #cdcdcd; /* Light gray border */
              border-top: 4px solid #49c0a0; /* Blue border */
              border-radius: 50%; /* Circular shape */
              width: 50px;
              height: 50px;
              animation: spin 1s linear infinite; /* Animation */
              margin: 0 auto; /* Center the loader */
            }
            
            @keyframes spin {
              0% { transform: rotate(0deg); } /* Start position */
              100% { transform: rotate(360deg); } /* End position */
            }
            `}
      </style>
    </>
  );
};

export default Loader;
