import Select from "react-select";
import { Dialog, Typography } from "@mui/material";
import { DialogTitle } from "@mui/material";
import Dropzone from "react-dropzone-uploader";
import { Link } from "react-router-dom";
import Tooltip from "./tooltip";

import styles from "../../styles/demoResponsive.module.css";
import classNames from "classnames";
import ReactSelect, { components } from "react-select";
import ReactSwitch from "react-switch";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const DemoGenerateQuestionsStdHots = ({
  setState,
  handleSubmit,
  state,
  handleTitleChange,
  handleChange,
  props,
  handlePdfDocFileStatusChange,
  handlePopUpOpen,
  handleWikiTopicBlur,
  handleWikiTopicFocus,
  onInputChange,
  getFilesFromEvent,
  handleFileStatusChange,
  handleGenerateQuestions,
  handlePopUpClose,
  handleWikiTopicSelect,
  videoExtractButtonContent,
  getDocPdfFromEvent,
  tmpselectedWikiTopic,
}) => {
  const QUESTION_COUNT_OPTIONS = [
    { value: 1, label: "Upto 10" },
    { value: 2, label: "Upto 25" },
    { value: 3, label: "Upto 50" },
    { value: 4, label: "All Possible" },
  ];

  const DIAGRAM_OPTIONS = [
    { value: 1, label: "Diagrams" },
    { value: 2, label: "Graphs" },
    { value: 3, label: "Tables" },
    { value: 4, label: "All Possible" },
  ];

  const ques_type_option = [
    { value: "1", label: "Easy MCQ" },
    { value: "2", label: "Medium MCQ" },
    { value: "3", label: "Hard MCQ" },
    { value: "4", label: "True/False" },
    { value: "5", label: "Descriptive" },
    { value: "6", label: "Fillups" },
  ];
  const ques_type_option_blooms = [
    { value: "1", label: "Remember" },
    { value: "2", label: "Understand" },
    { value: "3", label: "Apply" },
    { value: "4", label: "Analyze" },
    { value: "5", label: "Evaluate" },
    { value: "6", label: "Create" },
  ];

  const CUSTOM_STYLES = {
    option: (provided, state) => ({
      ...provided,
    }),

    container: (base) => ({
      ...base,

      // border: this.state.occupation_message ? "1px solid red" : "",
      borderRadius: 5,
    }),

    // control: () => ({
    //   // none of react-select's styles are passed to <Control />
    //   border:"1px solid red"
    // }),
    menu: (provided, state) => ({
      ...provided,
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: 13,
      color: "#8898aa",
    }),
  };

  const history = useHistory();
  const currentPath = history.location.pathname;
  const handleContentModeChange = () => {
    localStorage.removeItem("hotsToggle");

    // Toggle between the two URLs
    const targetPath =
      currentPath === "/demo" ? "/demo-generate-questions-blooms" : "/demo";

    history.push(targetPath);
  };
  const isSwitchChecked = currentPath !== "/demo";

  const QuestionTypes = () => {
    return (
      <>
        <div
          style={{ gap: "2%", flexWrap: "wrap" }}
          className="d-flex justify-content-center mb-3"
        >
          <div className="dropdown-1">
            <label
              for="selectques_type"
              className={classNames(
                styles["question-generate-tab-label"],
                "form-label d-flex align-items-center"
              )}
              style={{
                fontWeight: 500,
                color: "#344054",
                gap: "1rem",
              }}
            >
              {" "}
              Question Types{" "}
            </label>
            <div className=" text-left">
              <ReactSelect
                className={classNames(styles["question-generate-tab-label"])}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                maxMenuHeight={200}
                options={
                  currentPath === "/demo"
                    ? ques_type_option
                    : ques_type_option_blooms
                }
                components={{
                  Option,
                }}
                onChange={handlePopUpOpen}
                type="text"
                placeholder={"All Possible."}
                id="selectques_type"
                styles={CUSTOM_STYLES}
              />
            </div>
          </div>
          <div className="position-relative dropdown-2">
            <label
              for="selectques_type"
              className={classNames(
                styles["question-generate-tab-label"],
                "form-label d-flex align-items-center"
              )}
              onClick={handlePopUpOpen}
              style={{
                fontWeight: 500,
                color: "#344054",
                gap: "1rem",
              }}
            >
              {" "}
              Questions Count{" "}
            </label>

            <ReactSelect
              className={classNames(styles["question-generate-tab-label"])}
              hideSelectedOptions={false}
              maxMenuHeight={200}
              onChange={handlePopUpOpen}
              options={QUESTION_COUNT_OPTIONS}
              type="text"
              placeholder={"All possible."}
              id="selectques_count"
              name="ques_count"
              styles={CUSTOM_STYLES}
            />
          </div>

          <div className="position-relative dropdown-3">
            <label
              for="selectques_type"
              className={classNames(
                styles["question-generate-tab-label"],
                "form-label d-flex align-items-center"
              )}
              onClick={handlePopUpOpen}
              style={{
                fontWeight: 500,
                color: "#344054",
                gap: "8px",
              }}
            >
              {" "}
              Include Visuals{" "}
              <span
                style={{
                  fontSize: "8px",
                  right: "1px",
                  position: "absolute",
                  background: "#ffc107",
                }}
                className="badge text-white badge-warning"
              >
                Coming Soon!
              </span>
            </label>
            <ReactSelect
              className={classNames(styles["question-generate-tab-label"])}
              maxMenuHeight={200}
              isClearable
              isDisabled={true}
              options={DIAGRAM_OPTIONS}
              type="text"
              placeholder={"None."}
              id="selectdiagram"
              name="diagram"
              styles={CUSTOM_STYLES}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="question-generate-tab-outer maxw-820">
      <form className="question-generate-tab-form" onSubmit={handleSubmit}>
        <div className="form-group custom-login-field">
          <div className="d-flex justify-content-between align-items-center">
            <label
              htmlFor="titleofQuestion"
              className="custom-login-field--label"
            >
              Quiz Title
            </label>
            <div
              className="d-flex align-items-center"
              style={{ gap: "7px", marginBottom: "12px" }}
            >
              <label
                htmlFor="titleofQuestion"
                className="custom-login-field--label"
                style={{
                  fontSize: "13px",
                  fontWeight: "700",
                  margin: 0,
                  padding: 0,
                }}
              >
                Bloom's Taxonomy
              </label>
              <ReactSwitch
                onColor="#49c0a0"
                height={20}
                width={36}
                checked={isSwitchChecked}
                onChange={handleContentModeChange}
              />
            </div>
          </div>
          <div className="position-relative">
            <input
              className={classNames(
                styles["question-generate-tab-input"],
                `custom-login-field--inputs form-control px-2 ${
                  state.titleofQuestion_emsg !== "" ? "is-invalid" : ""
                }`
              )}
              type="text"
              name="titleofQuestion"
              value={state.titleofQuestion}
              onChange={handleTitleChange}
              id="titleofQuestion"
              aria-describedby="textHelp"
              placeholder="e.g. Chapter Name, Topic Name, Section, etc."
            />
            {state.titleofQuestion_emsg && (
              <span className="text-danger">{state.titleofQuestion_emsg}</span>
            )}
          </div>
        </div>
        <div className={classNames(styles["border-zero"], "navtab--outer")}>
          <label
            htmlFor=""
            className={classNames(
              styles["question-generate-tab-label"],
              "custom-login-field--label"
            )}
          >
            Content Type
          </label>
          <ul
            className={classNames(
              styles["question-generate-nav"],
              styles["border-zero"],
              "nav nav-tabs"
            )}
            id="myTab"
            role="tablist"
          >
            <li
              className={classNames(
                styles[`question-generate-item`],
                `nav-item ${
                  state.defaultActiveTab === "custom-tab"
                    ? styles["apply-bg"]
                    : ""
                }`
              )}
              role="presentation"
              onClick={() =>
                setState({
                  defaultActiveTab: "custom-tab",
                })
              }
            >
              <a
                className={classNames(
                  styles[`question-generate-item-text`],
                  styles["border-zero"],
                  `nav-link`
                )}
                id="custom-tab"
                href="#custom"
                role="tab"
                aria-controls="custom"
                aria-selected="true"
              >
                Text
              </a>
            </li>
            <li
              className={classNames(
                styles[`question-generate-item`],
                `nav-item ${
                  state.defaultActiveTab === "upload-tab"
                    ? styles["apply-bg"]
                    : ""
                }`
              )}
              style={{ position: "relative" }}
              role="presentation"
              onClick={() =>
                setState({
                  defaultActiveTab: "upload-tab",
                })
              }
            >
              <a
                className={classNames(
                  styles[`question-generate-item-text`],
                  styles["border-zero"],
                  `nav-link`
                )}
                id="PDF-tab"
                href="#PDF"
                role="tab"
                aria-controls="PDF"
                aria-selected="false"
              >
                Document
              </a>
            </li>
            <li
              className={classNames(
                styles[`question-generate-item`],
                `nav-item ${
                  state.defaultActiveTab === "video-tab"
                    ? styles["apply-bg"]
                    : ""
                }`
              )}
              style={{ position: "relative" }}
              role="presentation"
              onClick={() =>
                setState({
                  defaultActiveTab: "video-tab",
                })
              }
            >
              <a
                className={classNames(
                  styles[`question-generate-item-text`],
                  styles["border-zero"],
                  `nav-link`
                )}
                id="VideoUrl-tab"
                style={{ height: "100%" }}
                href="#VideoUrl"
                role="tab"
                aria-controls="VideoUrl"
                aria-selected="false"
              >
                Video
              </a>
            </li>
            <li
              className={classNames(
                styles[`question-generate-item`],
                `nav-item ${
                  state.defaultActiveTab === "wiki" ? styles["apply-bg"] : ""
                }`
              )}
              onClick={() =>
                setState({
                  defaultActiveTab: "wiki",
                })
              }
              role="presentation"
            >
              <a
                className={classNames(
                  styles[`question-generate-item-text`],
                  styles["border-zero"],
                  `nav-link`
                )}
                id="wiki-tab"
                href="#wiki"
                role="tab"
                aria-controls="wiki"
                aria-selected="false"
                style={{ height: "100%" }}
              >
                Topic
              </a>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${
                state.defaultActiveTab === "custom-tab" ? "active show" : ""
              }`}
              id="custom"
              role="tabpanel"
              aria-labelledby="custom-tab"
            >
              <div className="form-group custom-login-field">
                <label
                  htmlFor="contentForQuestions"
                  className={classNames(
                    styles["question-generate-tab-label"],
                    "custom-login-field--label"
                  )}
                >
                  Type/Paste your Content here
                </label>
                <div className="position-relative">
                  <textarea
                    className={classNames(
                      styles["question-generate-tab-input"],
                      "custom-login-field--inputs form-control px-2 resize--none"
                    )}
                    id="contentForQuestions"
                    name="contentForQuestions"
                    value={state.contentForQuestions}
                    onChange={handleChange}
                    aria-describedby="textareaHelp"
                    rows="9"
                    placeholder="e.g. Chapter Text, Paragraph, Summary or Description"
                  ></textarea>
                </div>
                <QuestionTypes />
                <div className="btn-flex100">
                  <button
                    type="submit"
                    className={classNames(
                      styles["question-generate-submit"],
                      "btn--submit"
                    )}
                    onClick={(e) => handleGenerateQuestions(e)}
                    disabled={state.contentForQuestions ? false : true}
                  >
                    Generate Questions
                  </button>
                </div>
              </div>
            </div>
            <div>
              <Dialog
                id="free-dialog-box"
                open={state.showDemoPopUp}
                onClose={handlePopUpClose}
              >
                <DialogTitle
                  style={{
                    textAlign: "center",
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_PROJECT_URL}/img/prepAI-new-logo.svg`}
                    className={classNames(styles["demo-popup-logo"])}
                    style={{
                      height: "200px",
                      width: "250px",
                      objectFit: "contain",
                      objectPosition: "30% 30%",
                      zIndex: "1",
                    }}
                    alt="prepAi-logo"
                  />
                </DialogTitle>

                <Typography
                  variant="h3"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "22px",
                    zIndex: "5",
                    marginTop: "-65px",
                    marginBottom: "25px",
                  }}
                >
                  Start Your 15-day Free Trial
                </Typography>
                <Typography>
                  <div
                    style={{
                      textAlign: "center",
                      margin: "5px 10px 20px",
                      fontSize: "13px",
                    }}
                  >
                    <Typography variant="h7" style={{ marginBottom: "20px" }}>
                      Generate assessments with AI, at no cost, today!
                    </Typography>
                    {/* <br />
                      <Typography variant="h7">
                        Register on PrepAI and get started.
                      </Typography> */}
                  </div>
                </Typography>

                <Typography>
                  <div style={{ textAlign: "center", lineHeight: "10px" }}>
                    <Link to={`/register${props.location.search}`}>
                      <p className="btn upgrade-plan-btn">Sign Up Now </p>
                    </Link>
                    <p style={{ fontSize: "12px", color: "gray" }}>
                      (No CC required)
                    </p>
                  </div>
                </Typography>

                <Typography>
                  <Link to={`/login${props.location.search}`}>
                    <div style={{ textAlign: "center", fontSize: "14px" }}>
                      <p>Already have an account?</p>
                    </div>
                  </Link>
                </Typography>
              </Dialog>
            </div>
            <div
              className={`tab-pane fade ${
                state.defaultActiveTab === "upload-tab" ? "active show" : ""
              }`}
              id="PDF"
              role="tabpanel"
              aria-labelledby="PDF-tab"
            >
              <div className="video--content" style={{ padding: "6rem 10px" }}>
                <div
                  className="m-auto text-center video--content-font"
                  style={{ width: "70%" }}
                >
                  <Dropzone
                    accept=".pdf, .doc, .docx"
                    maxFiles={1}
                    canCancel={true}
                    data-tip
                    inputContent="Drag Files or Click to Browse(.pdf, .docx, .doc)"
                    onChangeStatus={handlePdfDocFileStatusChange}
                    getUploadParams={() => ({
                      url: "https://httpbin.org/post",
                    })}
                    // InputComponent={Input}
                    getFilesFromEvent={getDocPdfFromEvent}
                    styles={{
                      dropzone: {
                        maxHeight: 250,
                        wordBreak: "break-word",
                      },
                    }}
                  />
                </div>
              </div>
              <QuestionTypes />
              <div className="btn-flex100">
                <button
                  type="submit"
                  className={classNames(
                    styles["question-generate-submit"],
                    "btn--submit"
                  )}
                  onClick={handlePopUpOpen}
                  disabled={state.pdfDocFile === null ? true : false}
                >
                  Generate Questions
                </button>
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                state.defaultActiveTab === "video-tab" ? "active show" : ""
              }`}
              id="VideoUrl"
              role="tabpanel"
              aria-labelledby="VideoUrl-tab"
            >
              {/* Video Content Goes Here */}
              <div className="video--content">
                <div
                  className="text-center video--content-font"
                  style={{ width: "70%" }}
                >
                  <label
                    htmlFor=""
                    className={classNames(
                      styles["question-generate-tab-label"]
                    )}
                  >
                    {" "}
                    Enter a Video URL{" "}
                  </label>
                  {/*<p className="finger-pointing-down"></p> */}
                  <div className="videoImg-content">
                    <img
                      src={`${process.env.REACT_APP_PROJECT_URL}/img/videoURL.svg`}
                      alt="videoImage"
                    />
                    <input
                      type="text"
                      className="voideourl--text"
                      name="videourl"
                      data-tip
                      data-for="upload_video"
                      value={state.videourl}
                      onChange={handleChange}
                      placeholder="Paste Video URL from Youtube"
                      disabled={state.isUploaded ? true : false}
                    />
                    <Tooltip
                      id="upload_video"
                      title="You can either use YouTube URL or Upload video"
                    />
                  </div>
                </div>
                <h5 className="question-generate-tab-label mt-2 mb-1 text-center videoordiv">
                  <span>OR</span>
                </h5>
                <div
                  className="m-auto text-center video--content-font"
                  style={{ width: "70%" }}
                >
                  <div className="videoImg-content">
                    <label
                      htmlFor=""
                      className={classNames(
                        styles["question-generate-tab-label"],
                        "mt-1"
                      )}
                    >
                      Upload Video
                    </label>
                    <Dropzone
                      accept="video/*"
                      maxFiles={1}
                      canCancel={true}
                      data-tip
                      data-for="upload_video"
                      inputContent="Drag Files or Click to Browse(.mp4, .avi, .mov, .wmv)"
                      onChangeStatus={handleFileStatusChange}
                      getUploadParams={() => ({
                        url: "https://httpbin.org/post",
                      })}
                      // InputComponent={Input}
                      getFilesFromEvent={getFilesFromEvent}
                      disabled={state.videourl ? true : false}
                      styles={{
                        dropzone: {
                          textAlign: "center !important",
                          maxHeight: 250,
                          wordBreak: "break-word",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <QuestionTypes />
              <div className="btn-flex100">
                <button
                  type="button"
                  className={classNames(
                    styles["question-generate-submit"],
                    "btn--submit"
                  )}
                  onClick={handlePopUpOpen}
                  disabled={state.videourl || state.isUploaded ? false : true}
                >
                  Generate Questions
                </button>
                {videoExtractButtonContent()}
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                state.defaultActiveTab === "wiki" ? "active show" : ""
              }`}
              id="wiki"
              role="tabpanel"
              aria-labelledby="wiki-tab"
            >
              <div className="form-group custom-login-field">
                <div className="position-relative">
                  {currentPath !== "/demo" ? (
                    <div style={{ display: "flex" }}>
                      <input
                        className={classNames(
                          styles["question-generate-tab-input"],
                          "custom-login-field--inputs form-control mt-3 px-2 resize--none"
                        )}
                        name="searchTopic"
                        id="searchTopic"
                        onChange={onInputChange}
                        onKeyDown={handlePopUpOpen}
                        // onChange={(option) =>
                        //   handleWikiTopicSelect("searchTopic", option)
                        // }
                        // onFocus={handleWikiTopicFocus}
                        // onBlur={handleWikiTopicBlur}
                        // onClick={handleWikiTopicFocus}
                        // value={state.tmpselectedWikiTopic}
                        // options={state.options}
                        placeholder="Enter Topic Name and Press Enter"
                        style={{ padding: "5px" }}
                      />
                      <i
                        className="fa fa-search search-hots"
                        aria-hidden="true"
                        onClick={handlePopUpOpen}
                      ></i>
                    </div>
                  ) : (
                    <>
                      <label
                        htmlFor="searchTopic"
                        className={classNames(
                          styles["question-generate-tab-label"],
                          "custom-login-field--label"
                        )}
                      >
                        Search Your Topic Here
                      </label>
                      <Select
                        className={classNames(
                          styles["question-generate-tab-input"]
                        )}
                        name="searchTopic"
                        id="searchTopic"
                        onInputChange={onInputChange}
                        onChange={(option) =>
                          handleWikiTopicSelect("searchTopic", option)
                        }
                        onFocus={handleWikiTopicFocus}
                        onBlur={handleWikiTopicBlur}
                        onClick={handlePopUpOpen}
                        value={state.tmpselectedWikiTopic}
                        options={state.options}
                        placeholder={`Enter Topic Name & Hold On`}
                      />
                    </>
                  )}

                  <textarea
                    className={classNames(
                      styles["question-generate-tab-input"],
                      "custom-login-field--inputs form-control mt-3 px-2 resize--none"
                    )}
                    id="wikiContent"
                    name="wikiContent"
                    aria-describedby="textareaHelp"
                    onChange={handleChange}
                    rows="9"
                    value={state.wikiContent}
                    disabled={state.searchTopic ? false : true}
                    placeholder="Generated Content will be shown here. Please wait a while, Good things take time 🙂"
                  ></textarea>
                </div>
                <QuestionTypes />
                <div className="btn-flex100">
                  <button
                    type="submit"
                    className={classNames(
                      styles["question-generate-submit"],
                      "btn--submit"
                    )}
                    id="wiki-submit-button"
                    onClick={handlePopUpOpen}
                    disabled={state.wikiContent ? false : true}
                  >
                    Generate Questions
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DemoGenerateQuestionsStdHots;
