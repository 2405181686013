import React from 'react'

import styles from '../styles/loaderResponsive.module.css'
import classNames from 'classnames'

const LoaderCircle = ({ isLoading }) => {
  if (isLoading) {
    return (
      <div id="smloadingDiv" className={classNames(styles['loader-container'])}>
        <div className={classNames(styles['loader-res'], 'smloader')}>
          Loading...
        </div>
      </div>
    )
  }
  return ''
}

export default LoaderCircle
