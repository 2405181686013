import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Rating from "react-rating";
import { ReactSortable } from "react-sortablejs";
// import Tooltip from "../common/tooltip";
import MSG, {
  notifySuccess,
  notifyError,
  randomKey,
} from "./../../utils/Helper";
import { startLoader, stopLoader } from "./../../store/reducers/generalActions";
import {
  updateQuestionRating,
  updateOptionRating,
  updateIsRequired,
  SaveEditQuestion,
} from "./../../services";
import EditMediuMCQ from "../editquestions/editmediummcq";
import SweetAlert from "react-bootstrap-sweetalert";
import { trackEvent } from "../../utils/mixpanel";
import Switch from "react-switch";
import ReadMore from "../common/ReadMore";
import { Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import Zoom from "react-medium-image-zoom";
import styles from "../../styles/qnaResponsive.module.css";
import classNames from "classnames";

class MediumMCQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionRequired: this.props.question.is_required,
      reasonBox: this.props.question.is_required,
      reasonComment: this.props.question.comment,
      ques_rating: this.props.question_rating,
      opt_rating: this.props.opt_rating,
      editquestion: false,
      openBoxDefault: false,
      alert: null,
      neutralize: false,
      disable: false,
      checked: this.props.question.is_required ? false : true,
      accordionText: "View Answer",
    };
  }

  async componentDidMount() {
    if (this.props.disable) {
      this.setState({
        neutralize: !this.state.neutralize,
        disable: !this.state.disable,
      });
    }
  }

  handleRatingQuestion = async (value) => {
    // update the rating
    if (!this.state.neutralize) {
      this.props.dispatch(startLoader());
      const req = {
        questionId: this.props.question.question_id,
        questionRating: value,
      };
      try {
        const { data: res } = await updateQuestionRating(req);
        if (res.success == true) {
          notifySuccess(res.message);
          this.setState({
            ques_rating: value,
          });
        } else {
          notifyError(res.message);
        }
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 422) {
          const { data } = err.response;
          notifyError(data.message);
        } else {
          notifyError(MSG.commonApiError);
        }
      }
      this.props.dispatch(stopLoader());
    }
  };

  handleRatingOptions = async (value) => {
    // update the rating
    if (!this.state.neutralize) {
      this.props.dispatch(startLoader());
      const req = {
        questionId: this.props.question.question_id,
        optionRating: value,
      };
      try {
        const { data: res } = await updateOptionRating(req);
        if (res.success == true) {
          notifySuccess(res.message);
          this.setState({
            opt_rating: value,
          });
        } else {
          notifyError(res.message);
        }
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 422) {
          const { data } = err.response;
          notifyError(data.message);
        } else {
          notifyError(MSG.commonApiError);
        }
      }
      this.props.dispatch(stopLoader());
    }
  };

  handleisRequiredChange = ({ currentTarget: input }) => {
    this.setState({
      questionRequired: input.value,
      reasonBox: input.value,
    });
  };

  handleChangeComment = ({ currentTarget: input }) => {
    this.setState({
      reasonComment: input.value,
    });
  };

  handleCloseReasonBox = (e) => {
    this.setState({
      reasonBox: 1,
    });
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  handleisRequiredClick = (val) => {
    this.setState({ checked: val });
    if (!this.state.neutralize) {
      // if ((val == 1 || val == 0) && this.state.questionRequired == 0) {
      // if (val == 0) {
      //   trackEvent("Click on IsRequired - No", {
      //     source: this.props.data.source,
      //   });
      // }

      // update on server silently
      const req = {
        questionId: this.props.question.question_id,
        IsRequired: !val,
        comment: this.state.reasonComment,
      };
      this.saveIsRequired(req);
      // }
      if (!this.state.openBoxDefault) {
        this.setState({
          openBoxDefault: true,
          questionRequired: val,
          reasonBox: val,
        });
      } else {
        this.setState({
          questionRequired: val,
          reasonBox: val,
        });
      }
    } else {
      //for demo delete
      localStorage.setItem(`Q${this?.props?.question?.question_id}`, val);
    }
  };

  saveIsRequired = async (req) => {
    try {
      const { data: res } = await updateIsRequired(req);
      // console.log(res);
      return res;
    } catch (err) {
      console.log(err);
      notifyError(MSG.commonApiError);
    }
  };

  handleCommentSave = async () => {
    if (!this.state.reasonComment) {
      notifyError("No comment to save");
      return false;
    }
    const req = {
      questionId: this.props.question.question_id,
      IsRequired: this.state.questionRequired,
      comment: this.state.reasonComment,
    };
    this.props.dispatch(startLoader());
    const res = await this.saveIsRequired(req);
    if (res.success == true) {
      notifySuccess(res.message);
      this.setState({
        reasonBox: 1,
      });
    } else {
      notifyError(res.message);
    }
    this.props.dispatch(stopLoader());
  };

  editbutton = (e) => {
    e.preventDefault();
    // if (e.target.name == "edit-button") {
    //   trackEvent("Edit Question Button Clicked", {
    //     source: this.props.data.source,
    //   });
    // }
    this.setState({
      editquestion: !this.state.editquestion,
    });
  };

  validatemainquestion = (question) => {
    var que = question.question;
    var question = que.map((val) => val.replace("Ques  :", ""));

    for (var i = 0; i < question.length; i++) {
      if (question[i].trim() == "") {
        this.setState({
          isEmptyIndex: i,
          titleofQuestion_emsg: "Please Enter Question.",
        });
        return false;
      }
    }

    this.setState({
      titleofQuestion_emsg: "",
    });
    return true;
  };

  validateoption = (question) => {
    var opt = question.options;
    var option = opt.map((val) => val.replace("*", ""));

    for (var i = 0; i < option.length; i++) {
      if (option[i].trim() == "") {
        this.setState({
          isOptionEmpty: i,
          option_emsg: "Please Enter the option",
        });
        return false;
      }
    }

    this.setState({
      option_emsg: "",
    });
    return true;
  };

  savechanges = async (question, optionIndex) => {
    if (
      !this.validatemainquestion(question) ||
      !this.validateoption(question)
    ) {
      return false;
    }

    const changedquestion = { ...question };

    if (optionIndex != null) {
      var optionsObj = changedquestion.options;
      var opt = optionsObj.map((val) => val.replace("*", ""));
      opt[optionIndex] = opt[optionIndex] + " *";
      changedquestion.options = opt;
    }

    var que = changedquestion.question;
    var opt = changedquestion.options;
    var queId = changedquestion.question_id;

    var reqData = {
      questionId: queId,
      questions: que,
      options: opt,
    };

    this.props.dispatch(startLoader());
    try {
      const { data: res } = await SaveEditQuestion(reqData);
      if (res.success == true) {
        notifySuccess(res.message);
        this.props.handleQuestionUpdate(changedquestion);
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.props.dispatch(stopLoader());
  };

  handlealert = (question, optionIndex) => {
    const getalert = (question, optionIndex) => {
      return (
        <SweetAlert
          showConfirm
          title={
            <div className="swal-title">
              {" "}
              Do you want to save your edited question?
            </div>
          }
          confirmBtnText="Yes"
          confirmBtnStyle={{ fontSize: "16px" }}
          showCancel
          cancelBtnText="No"
          cancelBtnBsStyle={{ fontSize: "16px" }}
          // cancelBtnBsStyle="default"
          confirmBtnBsStyle="success"
          onConfirm={() => {
            this.savechanges(question, optionIndex);
            this.setState({
              editquestion: !this.state.editquestion,
              alert: null,
            });
          }}
          onCancel={() => {
            this.setState({
              editquestion: !this.state.editquestion,
              alert: null,
            });
          }}
        />
      );
    };

    this.setState({
      alert: getalert(question, optionIndex),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  getAnswer = (options) => {
    let answer = "No Explanation Found!";
    const opt_index = ["A. ", "B. ", "C. ", "D. "];
    let count = 1;
    if (options.length > 1) {
      let filteredArray = [];
      options.filter((opt, index) => {
        if (opt.endsWith("*")) {
          // if (count > 1) {
          //   filteredArray.push(" <br /> ");
          // }
          filteredArray.push(
            opt_index[index] + opt.substring(0, opt.length - 1)
          );
          count += 1;
        }
      });
      let index = filteredArray.map((element) => options.indexOf(element));
      filteredArray.unshift(opt_index[index]);
      answer = filteredArray
      // answer = filteredArray.join(" ");
    } else {
      answer = options;
    }

    return (
      <>
        <span style={{ fontWeight: "bold", textDecoration: "underline", color: "black" }} className="mb-2">
          {count > 2 ? "Correct options are:": "The correct option is:"}
        </span>{" "}
        {answer.map((el) => <span className="color-prepai-green">{el}</span>)}
      </>
    );
  };

  getAccordion = (data) => {
    const {
      options,
      pro_tips: explanation,
      question_id: id,
    } = this.props.question;

    let answer = this.getAnswer(options);

    return (
      <React.Fragment>
        <p className="mb-0 mt-3">
          <button
            className="btn-sm btn-primary accordion-button"
            style={{ minWidth: "7.5rem !important" }}
            type="button"
            data-toggle="collapse"
            data-target={`#collapseExample_${id}`}
            aria-expanded="false"
            aria-controls={`collapseExample_${id}`}
            onClick={() =>
              this.setState({
                accordionText:
                  this.state.accordionText == "Hide Answer"
                    ? "View Answer"
                    : "Hide Answer",
              })
            }
          >
            {this.state.accordionText}
            {this.state.accordionText === "View Answer" ? (
              <span class="accordion-custom"> {">"} </span>
            ) : (
              <span class="rotate-accordion accordion-custom"> {">"} </span>
            )}
          </button>
        </p>
        <div class="collapse" id={`collapseExample_${id}`}>
          <div class="card-bootstrap card-body">
            <div className={classNames(styles["qna-ans"], "selected d-flex flex-column mb-2")}>
              {answer}
            </div>
            {_.isArray(data.image_data) && data.image_data[1] == "inside" && (
              <div className="">
                <Zoom>
                  <img
                    src={`data:image/png;base64, ${data.image_data[0]}`}
                    alt="refernce image"
                    style={{ width: "30%" }}
                  />
                </Zoom>
              </div>
            )}
            <ReadMore>{explanation}</ReadMore>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const data = this.props.question;
    const Ques = this.props.count;
    return (
      <React.Fragment>
        <div className="each-question-wrapper-box-outer">
          <div className="each-question-wrapper-box">
            {this.state.editquestion == true ? (
              <EditMediuMCQ
                data={this.props.question}
                showalert={this.handlealert}
                edit={this.editbutton}
                handleQuestionUpdate={this.props.handleQuestionUpdate}
                disable={this.state.disable}
                // addOnData={{ source: this.props.data.source }}
              ></EditMediuMCQ>
            ) : (
              <div className="word-break">
                <div
                  className={classNames(
                    styles["qna-container"],
                    "each-question-wrapper-box--body"
                  )}
                >
                  <div>
                    {/* <Tooltip title="Coming Soon" id="global" /> */}
                    <div className="d-flex justify-content-between">
                      <p
                        style={{ wordBreak: "break-word" }}
                        className={classNames(
                          styles["qna-q-title"],
                          "position-relative"
                        )}
                      >
                        <span>Q{Ques}. </span>
                        {_.isArray(data.question)
                          ? data.question.map((q, k) => {
                              if (k == 0) {
                                return q.substring(7, q.length);
                              } else {
                                return (
                                  <React.Fragment key={randomKey()}>
                                    <br />
                                    <span className="ml-3 py-1 d-inline-block">
                                      {q}
                                    </span>
                                  </React.Fragment>
                                );
                              }
                            })
                          : data.question.substring(7, data.question.length)}
                        {/* <span
                        className="what--text"
                        // onMouseEnter={() =>
                        //   trackEvent("Hover On Solution", {
                        //     source: this.props.data.source,
                        //   })
                        // }
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9ZM6.6 7.2C6.6 5.87452 7.67452 4.8 9 4.8H9.72C10.9792 4.8 12 5.82079 12 7.08V7.2C12 8.52548 10.9255 9.6 9.6 9.6V10.8H8.4V8.4H9.6C10.2627 8.4 10.8 7.86274 10.8 7.2V7.08C10.8 6.48353 10.3165 6 9.72 6H9C8.33726 6 7.8 6.53726 7.8 7.2H6.6ZM8.4 13.2V12H9.6V13.2H8.4Z"
                            fill="#526892"
                          />
                        </svg>
                        <span className="what--textbox">
                          <span className="what--textbox--content">
                            <span>
                              <strong>
                                <u>Context: </u>
                              </strong>
                            </span>
                            <br />
                            {data.pro_tips}
                          </span>
                        </span>
                      </span> */}
                      </p>
                      <div
                        role="button"
                        onClick={(e) => this.editbutton(e)}
                        style={{ marginTop: "-10px" }}
                      >
                        <Tooltip title="Edit">
                          <IconButton>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    {_.isArray(data.image_data) &&
                      data.image_data[1] == "outside" && (
                        <p className="text-center">
                          <Zoom>
                            <img
                              src={`data:image/png;base64, ${data.image_data[0]}`}
                              alt="refernce image"
                              style={{ width: "30%" }}
                            />
                          </Zoom>
                        </p>
                      )}
                    {/* <div className="rate--question large-star">
                      <span>Rate Question</span>
                      <div className="d-inline-block line-height-0">
                        <Rating
                          className="ratyli"
                          start={0}
                          stop={5}
                          step={1}
                          onChange={this.handleRatingQuestion}
                          initialRating={this.state.ques_rating}
                          emptySymbol="rate rate-empty far fa-star fa-2x"
                          fullSymbol="rate rate-full fas fa-star fa-2x"
                        />
                      </div>
                    </div> */}
                    <div
                      className={classNames(
                        styles["qna-ans-container"],
                        "custom--ol--question"
                      )}
                    >
                      <ol
                        id={`options_${data.question_id}`}
                        type="A"
                        start="1"
                        className={classNames(styles["qna-ans"])}
                      >
                        {/* <ReactSortable
                      list={data.options}
                      setList={(newState) =>
                        this.setState({
                          options: newState,
                        })
                      }
                    > */}
                        {data.options.map((item, k) => (
                          <li
                            key={`option_${data.question_id}_${k}`}
                            className={classNames(
                              styles["qna-ans"],
                              `list-group-item-each`
                            )}
                          >
                            {item.endsWith("*")
                              ? item.substring(0, item.length - 1)
                              : item}
                          </li>
                        ))}
                        {/* </ReactSortable> */}
                        {/* <li className="list-group-item-each selected">
                    Individuals define physics by what it was rather than what
                    it is and will be.
                  </li> */}
                      </ol>
                    </div>
                    <div
                      className="hidden-more-options custom--ol--question cusror-mover collapse"
                      id="moreoptionThree"
                    >
                      <ol
                        id={`moreoptions_${data.question_id}`}
                        type="A"
                        start="5"
                      >
                        <li className="list-group-item-each">
                          An object at rest stays at rest and an object in
                          motion stays in motion.
                        </li>
                        <li className="list-group-item-each">
                          Individuals define physics by what it was rather than
                          what it is and will be.
                        </li>
                        <li className="list-group-item-each">
                          What we mean by this is that things keep changing in
                          the world of physics with every discovery.
                        </li>
                        <li className="list-group-item-each">
                          As theories progress and discoveries are made, not
                          only the answer but the whole question.
                        </li>
                      </ol>
                    </div>
                  </div>
                  {/* <div>
              <div className="inside-question-box">
                <div className="quetsion--edit">
                  <div className="quetsion--edit--markquee">Q.</div>
                  <input
                    type="text"
                    className="quedtion--text--fields"
                    value="What is Physics? How would you define it in the context of Physics?"
                  />
                </div>
                <div className="editable--questions">
                  <div className="quetsion--edit--markquee">
                    <label className="mordern-radiobox-label my-0">
                      <input type="radio" name="questions2" />
                      <span className="design"></span>
                    </label>
                  </div>
                  <input
                    type="text"
                    className="quedtion--text--fields"
                    value="An object at rest stays at rest and an object in motion stays in motion."
                  />
                </div>
                <div className="editable--questions">
                  <div className="quetsion--edit--markquee">
                    <label className="mordern-radiobox-label my-0">
                      <input type="radio" name="questions2" />
                      <span className="design"></span>
                    </label>
                  </div>
                  <input
                    type="text"
                    className="quedtion--text--fields"
                    value="The acceleration of an object is dependent upon two variables."
                  />
                </div>
                <div className="editable--questions">
                  <div className="quetsion--edit--markquee">
                    <label className="mordern-radiobox-label my-0">
                      <input type="radio" name="questions2" />
                      <span className="design"></span>
                    </label>
                  </div>
                  <input
                    type="text"
                    className="quedtion--text--fields"
                    value="The first law states that for every action (force) in nature there is an equal and opposite reaction."
                  />
                </div>
                <div className="editable--questions">
                  <div className="quetsion--edit--markquee">
                    <label className="mordern-radiobox-label my-0">
                      <input type="radio" name="questions2" />
                      <span className="design"></span>
                    </label>
                  </div>
                  <input
                    type="text"
                    className="quedtion--text--fields"
                    value="Force equals mass times acceleration. For every action, there is an equal and opposite reaction."
                  />
                </div>
              </div>
              <div className="editable-question-submitbox">
                <button
                  type="button"
                  role="button"
                  className="preview--paper--btn font--400 font--14 w-110"
                >
                  Save
                </button>
                <button
                  role="button"
                  type="putton"
                  className="preview--button font--400 font--14 w-110 ml-2"
                >
                  Cancel
                </button>
              </div>
            </div> */}
                  {this.getAccordion(data)}
                </div>
                <div className="each-question-wrapper-box--footer">
                  <div className="row align-items-center justify-content-between no-gutters">
                    <span
                      style={{
                        fontSize: "small",
                      }}
                    >
                      <span style={{ fontWeight: "500" }}>Page:</span>{" "}
                      {data.page_no || "N/A"}
                    </span>
                    {/* <div className="col-12 col-175">
                      <div className="content--pill">
                        <button
                          className="content--pill--btn"
                          type="button"
                          role="button"
                          data-tip
                          data-for="global"
                          onClick={(e) => e.preventDefault()}
                        >
                          More Options
                        </button> */}
                    {/* <button
                        className="content--pill--btn"
                        type="button"
                        role="button"
                        data-toggle="collapse"
                        href="#moreoptionThree"
                        role="button"
                        aria-expanded="false"
                        aria-controls="moreoptionThree"
                      >
                        More Options <span>4</span>
                      </button> */}
                    {/* </div>
                    </div> */}
                    <div className="col-auto">
                      {/* <div className="content--pill">
                        <button
                          className="content--pill--btn"
                          type="button"
                          role="button"
                          name="edit-button"
                          //data-tip
                          //data-for="global"
                          onClick={(e) => this.editbutton(e)}
                        >
                          Edit Question
                        </button>
                      </div> */}
                    </div>
                    {/* <div className="col-auto">
                      <div className="content--pill">
                        <div className="rate--question mb-0 p-10 large-star">
                          <span>Rate Options</span>
                          <div className="d-inline-block line-height-0">
                            <Rating
                              className="ratyli"
                              start={0}
                              stop={5}
                              step={1}
                              onChange={this.handleRatingOptions}
                              initialRating={this.state.opt_rating}
                              emptySymbol="rate rate-empty far fa-star fa-2x"
                              fullSymbol="rate rate-full fas fa-star fa-2x"
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-auto">
                      <div className="content--pill">
                        <div className="rate--question mb-0 p-10 d-flex align-items-center">
                          <span>Delete Question?</span>
                          <div className="d-inline-block font-0 ml-2">
                            <label
                              className="mordern-radiobox-label my-0"
                              // onClick={() => this.handleisRequiredClick(1)}
                            >
                              {/* <input
                                type="radio"
                                className="radio-class-2"
                                name={`isrequired_${data.question_id}`}
                                onChange={this.handleisRequiredChange}
                                value={1}
                                checked={
                                  this.state.questionRequired == 1
                                    ? true
                                    : false
                                }
                              />
                              <span className="design"></span>
                              <span className="text">Yes</span> */}
                              <Switch
                                onChange={this.handleisRequiredClick}
                                checked={this.state.checked}
                                onColor="#FF0000"
                              />
                            </label>
                            <label
                              className="mordern-radiobox-label ml-2 my-0"
                              // onClick={() => this.handleisRequiredClick(0)}
                            >
                              {/* <input
                                id="popup-2"
                                className="radaio-pop radio-class-2"
                                type="radio"
                                name={`isrequired_${data.question_id}`}
                                onChange={this.handleisRequiredChange}
                                value={0}
                                checked={
                                  this.state.questionRequired == 0
                                    ? true
                                    : false
                                }
                              />
                              <span className="design"></span>
                              <span className="text">No</span> */}
                              {/* {this.state.questionRequired == 0 && (
                                <React.Fragment>
                                  <Tooltip
                                    title="Edit Comment"
                                    id="editcomment"
                                  />
                                  <span
                                    className="what--wrong"
                                    data-tip
                                    data-for="editcomment"
                                  >
                                    <i
                                      className="fa fa-edit ml-2"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </React.Fragment>
                              )} */}
                              {/* {this.state.questionRequired == 0 &&
                                this.state.reasonBox == 0 &&
                                this.state.openBoxDefault && (
                                  <div className="custom--tooltip--nobox">
                                    <span></span>
                                    <textarea
                                      name="comment"
                                      id={`comment_${data.question_id}`}
                                      onChange={this.handleChangeComment}
                                      value={this.state.reasonComment}
                                      placeholder="Give reason to elaborate your answer..."
                                    ></textarea>
                                    <button
                                      type="button"
                                      role="button"
                                      className="preview--paper--btn comment-save-btn font--400 font--14"
                                      onClick={this.handleCommentSave}
                                    >
                                      Save
                                    </button>
                                    <button
                                      role="button"
                                      type="button"
                                      className="closebtn"
                                      onClick={(e) =>
                                        this.handleCloseReasonBox(e)
                                      }
                                    >
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                )} */}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>{this.state.alert}</div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(MediumMCQ);
