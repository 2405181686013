import {Link} from "react-router-dom";
import {Menu, Close} from "@mui/icons-material";
import {useState} from "react";
import styles from "../../styles/demoResponsive.module.css";
import classNames from "classnames";

const DemoHeader = ({handlePopUpOpen, props, showHeaderTextPlan}) => {
	const [menuOpen, setMenuOpen] = useState(false);
	return (
		<div
			className='custom-navbar-v2'
			style={{zIndex: "10", padding: "5px 0px"}}
		>
			<nav
				className='navbar navbar-expand-md'
				style={{padding: "0.3rem !important"}}
			>
				<div
					className='container'
					style={{maxWidth: "100vw", alignItems: "center"}}
				>
					<Link className='navbar-brand' to='/'>
						<img
							src={`${process.env.REACT_APP_PROJECT_URL}/img/prepAI-new-logo.svg`}
							alt='prepAi-logo'
							style={{width: "120px"}}
							onClick={handlePopUpOpen}
						/>
					</Link>
					<button
						className={classNames(
							styles["navbar-toggler"],
							"navbar-toggler"
						)}
						type='button'
						data-toggle='collapse'
						data-target='#navbarSupportedContent'
						aria-controls='navbarSupportedContent'
						aria-expanded='false'
						aria-label='Toggle navigation'
						onClick={() => setMenuOpen(!menuOpen)}
					>
						{!menuOpen && <Menu style={{pointerEvents: "none"}} />}
						{menuOpen && <Close style={{pointerEvents: "none"}} />}
					</button>
					<div
						className='collapse navbar-collapse custom-navlinks'
						id='navbarSupportedContent'
					>
						<ul className='navbar-nav ml-auto'>
							{showHeaderTextPlan() !== null && (
								<li className={classNames(styles["nav-item"])}>
									<Link className='nav-link' to='/plans'>
										<p
											className={classNames(
												styles["upgrade-plan-btn"],
												"btn",
												"upgrade-plan-btn"
											)}
											style={{
												margin: "0px",
												padding: "0px"
											}}
										>
											{showHeaderTextPlan()}
										</p>
									</Link>
								</li>
							)}
							<li className={classNames(styles["nav-item"])}>
								<Link
									className='nav-link'
									to={{
										pathname: `https://prepai.io/pricing${props.location.search}`,
									}}
									target='_blank'
								>
									<p
										className={classNames(
											styles["upgrade-plan-btn"],
											"btn",
											"upgrade-plan-btn"
										)}
										// onClick={handlePopUpOpen}
										style={{
											backgroundColor: "#ffcc44",
											borderColor: "#ffcc44",
											margin: "0px",
										}}
									>
										PrepAI Premium
									</p>
								</Link>
							</li>
							<div className={classNames(styles["divider"])}></div>
							<div
								className={classNames(styles["demo-login-section"])}
								style={{display: "flex", alignItems: "center"}}
							>
								<li
									className={classNames(styles["nav-item"])}
									style={{marginLeft: "25px"}}
								>
									<Link
										className='nav-link'
										to={`/login${props.location.search}`}
									>
										<p
											className={classNames(
												styles["upgrade-plan-btn"],
												"btn",
												"upgrade-plan-btn-v2"
											)}
											style={{
												borderRadius: "30px",
												padding: "5px 20px",
												margin: "0px",
											}}
										>
											Login
										</p>
									</Link>
								</li>
								<div className={classNames(styles["divider"])}></div>
								<li
									className={classNames(styles["nav-item"])}
									style={{marginRight: "20px", marginLeft: "20px"}}
								>
									<Link
										className='nav-link'
										to={`/register${props.location.search}`}
									>
										<p
											className={classNames(
												styles["upgrade-plan-btn"],
												"btn",
												"upgrade-plan-btn"
											)}
											style={{
												borderRadius: "30px",
												padding: "5px 20px",
												margin: "0px",
											}}
										>
											Sign Up
										</p>
									</Link>
								</li>
							</div>
						</ul>
					</div>
				</div>
			</nav>
		</div>
	);
};

export default DemoHeader;
