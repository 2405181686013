import mixpanel from "mixpanel-browser";
mixpanel.init("07ac3224e622a7b9aa3f038e5634fbf5");

export function trackEvent(eventName, properties = {}) {
  mixpanel.track(eventName, properties);
  return true;
}

export function timeEvent(eventName) {
  mixpanel.time_event(eventName);
  return true;
}

export function identifyUser(uniqueIdentifier) {
  mixpanel.identify(uniqueIdentifier);
  return true;
}

export function alias(userIdentifier) {
  mixpanel.alias(userIdentifier);
  return true;
}
