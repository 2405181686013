const standard = {
  topic: "Make Money in Stock Market",
  content:
    `Making money in the stock market is not as complicated or risky as many people think. In fact, with a little know-how and some discipline, it can be quite easy to make money in the stock market.

Here are a few basic suggestions on how to make money in the stock market:
    
1. Doing Proper Research
Before investing in any stock, it is important to do your research and know as much as you can about the company. This includes understanding the financial statements, the competitive landscape, the company’s management, and the risks involved.
    
2. Have a Plan
It is also important to have a plan and a strategy before investing. This plan should include your investment goals for sure.

3. Stay disciplined
Investing in the stock market is not a get-rich-quick scheme. It requires patience and discipline to make money. It is important to stay disciplined and not to sell when the stock market is down.

4. Diversify
Don’t put all your eggs in one basket. Diversify your investments and spread your risk. This means investing in different types of stocks, industries, and even countries.

5. Review your portfolio
It is important to review your portfolio regularly and make sure it is still in line with your investment goals. This will help you make sure you are still on track to reach your goals and make money in the stock market.
    `
};

const hots = {
  topic: "Make Money in Stock Market",
  content:
  `Making money in the stock market is not as complicated or risky as many people think. In fact, with a little know-how and some discipline, it can be quite easy to make money in the stock market.

Here are a few basic suggestions on how to make money in the stock market:
    
1. Doing Proper Research
Before investing in any stock, it is important to do your research and know as much as you can about the company. This includes understanding the financial statements, the competitive landscape, the company’s management, and the risks involved.
    
2. Have a Plan
It is also important to have a plan and a strategy before investing. This plan should include your investment goals for sure.

3. Stay disciplined
Investing in the stock market is not a get-rich-quick scheme. It requires patience and discipline to make money. It is important to stay disciplined and not to sell when the stock market is down.

4. Diversify
Don’t put all your eggs in one basket. Diversify your investments and spread your risk. This means investing in different types of stocks, industries, and even countries.

5. Review your portfolio
It is important to review your portfolio regularly and make sure it is still in line with your investment goals. This will help you make sure you are still on track to reach your goals and make money in the stock market.
    `
};

var questionsDemoPreview = [
  {
    question_id: 1,
    content_id: 1,
    topic: "World is Adopting Technology in Education",
    category_id: 1,
    questions: [
      "Ques  : What is one of the main advantages of using technology in education? ",
    ],
    is_required: 1,
    question_rating: 0,
    option_rating: 0,
    options: [
      "Technology can be used to create non-targeted and engaging learning experiences.",
      "Technology can help improve teaching and learning.*",
      "Some students may not have the necessary skills to use technology effectively.",
      "Technology can be distracting for students.",
    ],
    pro_tips:
      "One of the main advantages of using technology in education is that it can improve teaching and learning. This is because technology can be used to create targeted and engaging learning experiences.",
    more_options: "''",
    comment: null,
  },
  {
    question_id: 2,
    content_id: 1,
    topic: "World is Adopting Technology in Education",
    category_id: 2,
    questions: [
      "Ques  : Which of the following is true about using technology in education?",
      "I. Technology can help to improve the overall quality of education.",
      "II. Real-time feedback can be given to both students and teachers.",
      "III. It can be very challenging to adopt technology in education.",
      "IV. Technology may be distracting for some students.",
      "Which of the options given above is/are correct:",
    ],
    is_required: 1,
    question_rating: 0,
    option_rating: 0,
    options: [
      "III and I only.",
      "II only.",
      "II, IV and I only.*",
      "All the above.",
    ],
    pro_tips:
      "Additionally, technology can be used to provide real-time feedback to both students and teachers. This can help to improve the quality of education overall. However, there are also some disadvantages to using technology in education. One of the main disadvantages is that it can be distracting for students.",
    more_options: "''",
    comment: null,
  },
  {
    question_id: 3,
    content_id: 1,
    topic: "World is Adopting Technology in Education",
    category_id: 5,
    questions: [
      "Ques  : What are some advantages & disadvantages of using technology in Education?",
    ],
    is_required: 1,
    question_rating: 0,
    option_rating: 0,
    options: [
      <>
        <br />
        <b>Advantages include</b>
        <br /> 1) Massive improvement in teaching and learning process{" "}
        <br />
        2) You can provide real-time feedback to both students and
        teachers <br />
        3) Create targeted and engaging learning experiences. <br />
        <br />
        <b>Disadvantages include</b> <br />
        1) Distraction for students in rare cases. <br />
        2) Some students may not have the necessary skills to use
        technology effectively &nbsp;so proper guidance in starting might
        help here. Overall, the advantages of using <br />
        technology in education outweigh the disadvantages.
      </>,
    ],
    pro_tips:
      "One of the main advantages of using technology in education is that it can improve teaching and learning. This is because technology can be used to create targeted and engaging learning experiences.Additionally, technology can be used to provide real-time feedback to both students and teachers. This can help to improve the quality of education overall. However, there are also some disadvantages to using technology in education. One of the main disadvantages is that it can be distracting for students. This is because students may be tempted to use their devices for things other than learning. Additionally, some students may not have the necessary skills to use technology effectively (proper guidance in starting should be given).",
    more_options: "''",
    comment: null,
  },
  {
    question_id: 4,
    content_id: 3,
    topic: "World is Adopting Technology in Education",
    category_id: 4,
    questions: [
      "Ques  : What is one of the main advantages of using technology in education? ",
    ],
    is_required: 1,
    question_rating: 0,
    option_rating: 0,
    options: [
      "Technology can be used to create non-targeted and engaging learning experiences.",
      "Technology can help improve teaching and learning.*",
      "Some students may not have the necessary skills to use technology effectively.",
      "Technology can be distracting for students.",
    ],
    pro_tips:
      "One of the main advantages of using technology in education is that it can improve teaching and learning. This is because technology can be used to create targeted and engaging learning experiences.",
    more_options: "''",
    comment: null,
  },
  {
    question_id: 5,
    content_id: 3,
    topic: "World is Adopting Technology in Education",
    category_id: 3,
    questions: [
      "Ques  : What is one of the main advantages of using technology in education? ",
    ],
    is_required: 1,
    question_rating: 0,
    option_rating: 0,
    options: [
      "Technology can be used to create non-targeted and engaging learning experiences.",
      "Technology can help improve teaching and learning.*",
      "Some students may not have the necessary skills to use technology effectively.",
      "Technology can be distracting for students.",
    ],
    pro_tips:
      "One of the main advantages of using technology in education is that it can improve teaching and learning. This is because technology can be used to create targeted and engaging learning experiences.",
    more_options: "''",
    comment: null,
  },
  {
    question_id: 5,
    content_id: 6,
    topic: "World is Adopting Technology in Education",
    category_id: 6,
    questions: [
      "Ques  : What is one of the main advantages of using technology in education? ",
    ],
    is_required: 1,
    question_rating: 0,
    option_rating: 0,
    options: [
      "Technology can be used to create non-targeted and engaging learning experiences.",
      "Technology can help improve teaching and learning.*",
      "Some students may not have the necessary skills to use technology effectively.",
      "Technology can be distracting for students.",
    ],
    pro_tips:
      "One of the main advantages of using technology in education is that it can improve teaching and learning. This is because technology can be used to create targeted and engaging learning experiences.",
    more_options: "''",
    comment: null,
  },
];

let questionDemoHots =[
  {
    question_id: 223096,
    content_id: 5870,
    topic: "test",
    category_id: 7,
    questions: "How old was Nelson Mandela when he left his home?",
    is_required: 1,
    question_rating: 0,
    option_rating: 0,
    options: ["20 years", "14 years*", "25 years", "18 years"],
    pro_tips: null,
    more_options: "''",
    comment: null,
  },
  {
    question_id: 223101,
    content_id: 5870,
    topic: "test",
    category_id: 8,
    questions: "What was the main struggle of Nelson Mandela?",
    is_required: 1,
    question_rating: 0,
    option_rating: 0,
    options: [
      "To take revenge against white people.",
      "To defeat apartheid and create democracy in South Africa.*",
      "To gain power by force.",
      "To fight against the white people.",
    ],
    pro_tips: null,
    more_options: "''",
    comment: null,
  },
  {
    question_id: 223112,
    content_id: 5870,
    topic: "test",
    category_id: 10,
    questions: "How did Nelson Mandela’s life story inspire people?",
    is_required: 1,
    question_rating: 0,
    option_rating: 0,
    options: [
      "Nelson Mandela's life story inspired people through his unwavering struggle against apartheid, his commitment to democracy, his ability to forgive and seek cooperation rather than revenge, and his successful efforts to bring about political change. His actions and principles made him a heroic figure who influenced many, including world leaders like President Barack Obama, and he ultimately played a pivotal role in ending apartheid and establishing democracy in South Africa.",
    ],
    pro_tips: null,
    more_options: "''",
    comment: null,
  },
  {
    question_id: 223114,
    content_id: 5870,
    topic: "test",
    category_id: 11,
    questions: "Do you agree that Mandela was a great leader? Why?",
    is_required: 1,
    question_rating: 0,
    option_rating: 0,
    options: [
      "Yes, Mandela was a great leader due to his strong character, wise approach, strategic planning, cooperation with white leaders, and successful struggle against apartheid, which inspired millions and led to the establishment of democracy in South Africa.",
    ],
    pro_tips: null,
    more_options: "''",
    comment: null,
  },
  {
    question_id: 223119,
    content_id: 5870,
    topic: "test",
    category_id: 9,
    questions: "Do you agree that Mandela was a great leader? Why?",
    is_required: 1,
    question_rating: 0,
    option_rating: 0,
    options: [
      "Yes, Mandela was a great leader due to his strong character, wise approach, strategic planning, cooperation with white leaders, and successful struggle against apartheid, which inspired millions and led to the establishment of democracy in South Africa.",
    ],
    pro_tips: null,
    more_options: "''",
    comment: null,
  },
  {
    question_id: 223118,
    content_id: 5870,
    topic: "test",
    category_id: 12,
    questions: "Do you agree that Mandela was a great leader? Why?",
    is_required: 1,
    question_rating: 0,
    option_rating: 0,
    options: [
      "Yes, Mandela was a great leader due to his strong character, wise approach, strategic planning, cooperation with white leaders, and successful struggle against apartheid, which inspired millions and led to the establishment of democracy in South Africa.",
    ],
    pro_tips: null,
    more_options: "''",
    comment: null,
  },
]



export {standard,hots,questionsDemoPreview,questionDemoHots};