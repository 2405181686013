import React, {Component} from "react";
import {connect} from "react-redux";
import {addQuestion} from "../../services";
import {startLoader, stopLoader} from "../../store/reducers/generalActions";
import {notifyError, notifySuccess} from "../../utils/Helper";
import _ from "lodash";
// medium mcq red line

class Addmediummcq extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newquestions: {
				question: [],
				options: ["", "", "", ""],
				category_type: 2,
				content_id: props.questions[0].content_id,
				topic: props.questions[0].topic,
			},
			titleofQuestion_emsg: "",
			option_emsg: "",
			selectedOption: null,
			isEmptyIndex: null,
			optionIndex: null,
			isOptionEmpty: null,
			optionIndexes: ["1", "2", "3", "4"],
		};
	}

	// async componentDidMount() {
	//   var optionObj = this.state.newquestions.options;
	//   let updatedOptionIndexes = this.state.optionIndexes
	//   optionObj.map((option, index) => {
	//     if (option.endsWith("*")) {
	//       updatedOptionIndexes[index] = index.toString()
	//     }
	//   });
	//   this.setState({
	//     optionIndexes: updatedOptionIndexes,
	//   });
	// }

	validatemainquestion = () => {
		var que = this.state.newquestions.question;
		if (que.length === 0) {
			this.setState({
				isEmptyIndex: 1,
				titleofQuestion_emsg: "Please enter text in the input field.",
			});
			return false;
		}

		// for (var i = 0; i < que.length; i++) {
		// if (que[0].trim() === "") {
		//   this.setState({
		//     isEmptyIndex: 1,
		//     titleofQuestion_emsg: "Please enter text in the input field.",
		//   });
		//   return false;
		// }
		// }

		if (!que[0].endsWith("?")) {
			this.setState({
				isEmptyIndex: 1,
				titleofQuestion_emsg: "Please put ? in the end.",
			});
			return false;
		}

		if (que[0].trim().length === 1) {
			this.setState({
				titleofQuestion_emsg: "Please enter text in the input field.",
			});
			return false;
		}

		// if (que.length <= 5) {
		//   this.setState({
		//     isEmptyIndex: que.length + 1,
		//     titleofQuestion_emsg: "Please enter text in the input field.",
		//   });
		//   return false;
		// }

		this.setState({
			titleofQuestion_emsg: "",
		});

		return true;
	};

	validateoption = () => {
		var opt = this.state.newquestions.options;
		var option = opt.map(val => val.replace("*", ""));
		let count = 0;
		let optionIndexes = this.state.optionIndexes;

		for (let i = 0; i < option.length; i++) {
			if (option[i].trim() === "") {
				this.setState({
					isOptionEmpty: i + 1, // mention which option is empty
					option_emsg: "Please Enter the option",
				});
				return false;
			}
		}

		for (let i = 0; i < optionIndexes.length; i++) {
			if (optionIndexes[i].trim().endsWith(" *")) {
				count += 1;
			}
		}

		if (count === 0) {
			this.setState({
				option_emsg: "Please mark atleaset 1 correct answer.",
			});
			return false;
		}

		this.setState({
			option_emsg: "",
		});

		return true;
	};

	add = event => {
		this.setState({
			titleofQuestion_emsg: "",
			isEmptyIndex: null,
		});

		var newquestion = this.state.newquestions;
		var index = event.target.name;
		newquestion.question[index - 1] = event.target.value;
		this.setState({
			newquestions: newquestion,
		});
	};

	optionChange = e => {
		this.setState({
			isOptionEmpty: null,
			option_emsg: "",
		});
		let index = e.target.name;
		var optionValue = [...this.state.newquestions.options];

		var val = optionValue[index - 1];
		if (val.endsWith("*")) {
			optionValue[index - 1] = e.target.value + " *";
		} else {
			optionValue[index - 1] = e.target.value;
		}
		var newQuestionObj = Object.assign({}, this.state.newquestions);
		newQuestionObj.options = optionValue;

		this.setState({
			// optionIndexes: updatedOptionIndexes,
			newquestions: newQuestionObj,
		});
	};

	handleExplanation = async event => {
		const value = event.target.value;
		this.setState({explanation: value});
	};

	checkBoxButtonValue = event => {
		let index = parseInt(event.target.name);
		// new change
		let updatedOptionIndexes = [...this.state.optionIndexes];
		let currentOption = updatedOptionIndexes[index - 1];
		if (currentOption.endsWith(" *")) {
			updatedOptionIndexes[index - 1] = updatedOptionIndexes[
				index - 1
			].slice(0, -2);
		} else {
			updatedOptionIndexes[index - 1] += " *";
		}

		this.setState({
			optionIndex: event.target.name,
			optionIndexes: updatedOptionIndexes,
			selectedOption: event.target.value,
		});
	};

	discard = () => {
		this.props.discardquestion("mediummcq");
	};

	handlesubmit = () => {
		if (!this.validatemainquestion() || !this.validateoption()) {
			return false;
		}

		var reqObj = _.cloneDeep(this.state);
		const data = this.state;
		reqObj.newquestions.question[0] =
			"Ques  : " + data.newquestions.question[0];

		var optionsObj = reqObj.newquestions.options;

		let optionIndexes = this.state.optionIndexes;

		// new change
		for (let i = 0; i < optionIndexes.length; i++) {
			if (optionIndexes[i].trim().endsWith(" *")) {
				optionsObj[i] += " *";
			}
		}

		reqObj.newquestions.options = optionsObj;
		reqObj.newquestions.explanation = this.state.explanation;
		this.doSubmit(reqObj);
	};

	doSubmit = async req => {
		this.props.dispatch(startLoader());
		try {
			const {data: res} = await addQuestion(req);
			if (res.success) {
				notifySuccess(res.message);
				const allQuestions = res.response;
				this.props.parentCallback(allQuestions);
				this.discard();
			} else {
				notifyError(res.message);
			}
			this.props.dispatch(stopLoader());
		} catch (err) {
			console.log(err);
		}
	};

	render() {
		console.log("opt", this.state.newquestions.options);
		return (
			<React.Fragment>
				<div className='each-question-wrapper-box'>
					<div className='quetsion--edit'>
						<div className='quetsion--edit--markquee'>Q.</div>
						<input
							className={
								this.state.isEmptyIndex === 1
									? "quedtion--text--fields input-required"
									: "quedtion--text--fields"
							}
							type='text'
							name='1'
							value={this.state.newquestions.question[0]}
							onChange={this.add}
							placeholder='Write your Question here...'
						/>
					</div>
					{/* <div className="quetsion--edit">
            <div className="quetsion--edit--markquee">I.</div>
            <input
              className={
                this.state.isEmptyIndex === 2
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="2"
              value={this.state.newquestions.question[1]}
              onChange={this.add}
              placeholder="Option 1"
            />
          </div>
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee">II.</div>
            <input
              className={
                this.state.isEmptyIndex === 3
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="3"
              value={this.state.newquestions.question[2]}
              onChange={this.add}
              placeholder="Option 2"
            />
          </div>
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee">III.</div>
            <input
              className={
                this.state.isEmptyIndex === 4
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="4"
              value={this.state.newquestions.question[3]}
              onChange={this.add}
              placeholder="Option 3"
            />
          </div>
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee">IV.</div>
            <input
              className={
                this.state.isEmptyIndex === 5
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="5"
              value={this.state.newquestions.question[4]}
              onChange={this.add}
              placeholder="Option 4"
            />
          </div> */}
					{/* <div className="quetsion--edit">
            <div className="quetsion--edit--markquee"></div>
            <input
              type="text"
              name="6"
              value={this.state.newquestions.question[5]}
              onChange={this.add}
              className="quedtion--text--fields"
              placeholder="write your question"
            />
          </div> */}

					{this.state.titleofQuestion_emsg && (
						<span className='text-danger' style={{marginLeft: "3em"}}>
							{this.state.titleofQuestion_emsg}
						</span>
					)}

					<div className='editable--questions'>
						<div className='quetsion--edit--markquee'>
							<label>
								<input
									type='checkbox'
									name='1'
									onChange={e => this.checkBoxButtonValue(e)}
									checked={
										this.state?.optionIndexes[0]?.endsWith(" *")
											? true
											: false
									}
								/>
								<span className='design'></span>
							</label>
						</div>
						<input
							className={
								this.state.isOptionEmpty === 1
									? "quedtion--text--fields input-required"
									: "quedtion--text--fields"
							}
							type='text'
							name='1'
							value={this.state.newquestions.options[0]}
							onChange={this.optionChange}
							placeholder='Option 1'
						/>
					</div>
					<div className='editable--questions'>
						<div className='quetsion--edit--markquee'>
							<label>
								<input
									type='checkbox'
									name='2'
									onChange={e => this.checkBoxButtonValue(e)}
									checked={
										this.state?.optionIndexes[1]?.endsWith(" *")
											? true
											: false
									}
								/>
								<span className='design'></span>
							</label>
						</div>
						<input
							className={
								this.state.isOptionEmpty === 2
									? "quedtion--text--fields input-required"
									: "quedtion--text--fields"
							}
							type='text'
							name='2'
							value={this.state.newquestions.options[1]}
							onChange={this.optionChange}
							placeholder='Option 2'
						/>
					</div>
					<div className='editable--questions'>
						<div className='quetsion--edit--markquee'>
							<label>
								<input
									type='checkbox'
									name='3'
									onChange={e => this.checkBoxButtonValue(e)}
									checked={
										this.state?.optionIndexes[2]?.endsWith(" *")
											? true
											: false
									}
								/>
								<span className='design'></span>
							</label>
						</div>
						<input
							className={
								this.state.isOptionEmpty === 3
									? "quedtion--text--fields input-required"
									: "quedtion--text--fields"
							}
							type='text'
							name='3'
							value={this.state.newquestions.options[2]}
							onChange={this.optionChange}
							placeholder='Option 3'
						/>
					</div>
					<div className='editable--questions'>
						<div className='quetsion--edit--markquee'>
							<label>
								<input
									type='checkbox'
									name='4'
									onChange={e => this.checkBoxButtonValue(e)}
									checked={
										this.state?.optionIndexes[3]?.endsWith(" *")
											? true
											: false
									}
								/>
								<span className='design'></span>
							</label>
						</div>
						<input
							className={
								this.state.isOptionEmpty === 4
									? "quedtion--text--fields input-required"
									: "quedtion--text--fields"
							}
							type='text'
							name='4'
							value={this.state.newquestions.options[3]}
							onChange={this.optionChange}
							placeholder='Option 4'
						/>
					</div>
					{this.state.option_emsg && (
						<span className='text-danger' style={{marginLeft: "3em"}}>
							{this.state.option_emsg}
						</span>
					)}

					<div className='editable--questions'>
						<div
							style={{
								position: "relative",
								width: "94%",
								textAlign: "left",
								marginTop: "10px",
							}}
							className='quetsion--edit--markquee p-0'
						>
							<label style={{fontSize: "15px"}}>
								Add Explanation (Optional)
							</label>
							<textarea
								name='explanation'
								onChange={this.handleExplanation}
								className='form-control'
								rows={5}
							/>
						</div>
					</div>
					<div className='editable-question-submitbox'>
						<button
							type='button'
							role='button'
							className='preview--paper--btn font--400 font--14 w-110'
							onClick={this.handlesubmit}
						>
							Add
						</button>
						<button
							role='button'
							type='putton'
							className='preview--button font--400 font--14 w-110 ml-2'
							onClick={this.discard}
						>
							Discard
						</button>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	};
};
export default connect(mapStateToProps)(Addmediummcq);
